import { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Grid, IconButton, Link, styled, Typography } from '@mui/material';
import { icons, tokens } from 'config/constants/assets';
import { useAppSelector } from 'state/hooks';
import { getMarketCollectionInfo } from 'utils/market';
import { TokenSelectInput } from 'components/common/TokenSelectCard/TokenSelectInput';
import DataSelect from 'components/common/Select/DataSelect';
import { ConnectWallet, ContainedButton } from 'components/common/Button';
import { useWeb3React } from '@web3-react/core';
import { useMarketpalce } from 'hooks/useMarketplace';
import { SupportedCollections } from 'types/nft';
import { useNotification } from 'hooks/useNotification';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '12px 5px 12px',
  borderRadius: '10px',
  background: 'rgb(6,1,1,0.3)',
  position: 'relative'
//   boxShadow:
//     '0px 2.15748px 29.22142px 0px rgba(0, 0, 0, 0.05), 0px 3.80638px 39.85961px 0px rgba(0, 0, 0, 0.07), 0px 5.70356px 44.46048px 0px rgba(0, 0, 0, 0.08), 0px 10.1608px 47.29564px 0px rgba(0, 0, 0, 0.09), 0px 25.99347px 52.37106px 0px rgba(0, 0, 0, 0.10), 0px 100px 77px 0px rgba(0, 0, 0, 0.13)',

//   [theme.breakpoints.up('xs')]: {
//     minHeight: '400px',
//   },
//   [theme.breakpoints.up('md')]: {
//     minHeight: '550px',
//   },
}));

// nft list title
const SellTitle = styled(Box)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  color: '#CDC392',
  fontSize: '18px',
  textAlign: 'left',
}));

const BasicText = styled(Box)(() => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    color: 'white',
    fontSize: '15px',
    textAlign: 'left',
}));

const Header = styled(Box)(({ theme }) => ({
    display: 'flex', 
    // background: 'red',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('xs')]: {
        minHeight: '70px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '50px',
      },
}));

const ContentBox = styled(Box)(({ theme }) => ({
    display: 'flex', 
    alignItems: 'center',
    // flexDirection: 'column',
    background: 'rgb(6,1,1,0.3)',
    borderRadius: '10px',
    [theme.breakpoints.up('xs')]: {
        minHeight: '70px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '50px',
      },
}));

const ContentLeft = styled(Box)(({ theme }) => ({
    display: 'flex', 
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
        minHeight: '70px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '50px',
      },
}));

const ContentRight = styled(Box)(({ theme }) => ({
    display: 'flex', 
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
        minHeight: '70px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '50px',
      },
}));

// toggle section
const ToggleSection = styled(Box)(() => ({
    position: 'absolute',
    right: '-7px',
    top: 'calc(50%px - 10px)',
    background: '#2A3139',
    boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '28px',
    height: '28px',
  }));

const StyledTokenInput = styled('div')(({ theme }) => ({
    maxWidth: '100%',
    width: '100%',
    border: '1px solid grey',
    borderRadius: '10px',
    backgroundColor: 'transparent'
}));

const TokenImgSection = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  }));

  const ActionButton = styled(ContainedButton)(() => ({
    // maxWidth: '102px',
    height: '40px',
  }));
  
  const TokenImg = styled('img')(({ theme }) => ({
      height: '25px',
      width: '25px',
  }));

  const ToggleIcon = styled('img')(() => ({}));

interface Props {
    data?: any
    onHideCard: () => void
}

const SellBox: FC<Props> = ({ data, onHideCard }) => {

  const [loaded, setLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const ref = useRef<HTMLImageElement>(null)
  const [isExpanded, setExpanded] = useState<boolean>(true);
  const [amount, setAmount] = useState('');
  const [periodSelect, setPeriodSelect] = useState<any>('1');
  const [isPending, setPending] = useState<boolean>(false);
  const { account } = useWeb3React();
  const { selectedChainId } = useAppSelector((state) => state.chain);
  const collection : SupportedCollections | undefined = getMarketCollectionInfo(Number(selectedChainId), data?.address);
  const [isLoadingTx, setLoading] = useState<boolean>(false);
  const [updateNftApp, setUpdateNftApp] = useState(0);
  const { onShowNotification } = useNotification();
  const { pendingTxHash } = useAppSelector((state) => state.modal);
  const pendingTxHashRef: { current: string | undefined } = useRef();
  pendingTxHashRef.current = pendingTxHash;
  const { onApproveStakingToken, onGetApproved, onSell } = useMarketpalce(Number(selectedChainId), collection?.address ? collection.address : "", '' );

  const imageLoaded = () => {
    setIsLoading(false);
  }
  const onToggle = () => {
    setExpanded(!isExpanded);
  };
  const truncate = (str: string, n: number) => {
    return str?.length > n ? `${str.substr(0, n ) } ... ${str.substr(str.length - n, n) }`: str;
    };
    const changeValue = (val: string) => {
        setAmount((val));
    }

    const onChangePeriod = (option: any) => {
        // if (Number(selectedChainId) === Number(option.chainId)) return;
        setPeriodSelect(option);
    };

  const isApproved = onGetApproved(data?.tokenId, updateNftApp);

  const onConfirm = async () => {
    setLoading(true);
    if (!isApproved) {
      // 1. Approve staking token logic
      try {
        await onApproveStakingToken(data?.tokenId);
        setUpdateNftApp(updateNftApp+1);
        onShowNotification({
          title: 'Transaction Success',
          description: 'Approve Token',
          hasView: true,
          txHash: pendingTxHashRef.current,
        });
      } catch (err) {
        onShowNotification({ title: 'Transaction Failed', description: 'Approve Token' });
        console.log('Approve NFT Token Error: ', err);
      }
    } else {
      try {
        if( collection ){
          if( collection.tokens.length > 0 ){
            await onSell(Number(amount), collection?.tokens[0], data?.tokenId, Math.ceil(new Date().getTime() /1000) + (Number(periodSelect) * 86400));
            onHideCard();
            onShowNotification({
              title: 'Transaction Success',
              description: 'List Token',
              hasView: true,
              txHash: pendingTxHashRef.current,
            });
          }
        }
      } catch (err) {
        onShowNotification({ title: 'Transaction Failed', description: 'List Token' });
        console.log('List NFT Token Error: ', err);
      }
    }
    setLoading(false);
  };

  const getButtonText = () => {
    if(!account){
      return 'Connect';
    }
    if (!isApproved) {
      return 'Approve';
    }
    return 'List';
  };

  return (
    <Container mt={1}>
        {isExpanded && 
            <>
                {/* <Box mt={2} display='flex' sx={{height: '5px', borderBottom: '1px solid grey'}}/> */}
                <Box mt={1} display='flex' flexDirection='column'>
                    <Box display='flex' flexDirection='column' sx={{minHeight: '40px', width: '100%', borderRadius: '15px', border: '0px solid grey'}}>
                        <>
                        <StyledTokenInput style={{maxHeight: '150px'}}>
                                <Box mt={0} pr={1} pl={1} style={{border: '0px solid grey', borderRadius: '10px'}}>
                                    <TokenSelectInput
                                        placeholder="0.0"
                                        changeValue={changeValue}
                                        amount={amount}
                                        startAdornment={
                                        <Box display='flex' alignItems='center' justifyContent='center'  
                                            style={{ background: 'rgb(6,1,1,0.3)', padding: '10px', borderRadius: '15px'}}>
                                            <TokenImgSection>
                                                <TokenImg src={tokens.obol}/>
                                            </TokenImgSection>
                                            <Box ml={1} alignItems='center' textAlign='center' sx={{ height: '20px', border: '0px solid grey'}}>
                                                <Typography style={{ fontSize: '15px', color: "lightgray"}} color='primary'
                                            variant='h6' >Obol</Typography>
                                            </Box>
                                        </Box>
                                        }
                                    />
                                </Box>
                                <Box style={{height: '1px', width: '100%', backgroundColor: 'grey', opacity: 0.2}}/>
                            </StyledTokenInput>
                        </>
                              <Box display='flex' alignItems='center' padding='10px' justifyContent='space-between'>
                                        <BasicText>
                                            You Recieve After Sell:
                                        </BasicText>
                                        {/* <DataSelect onSelect={onChangePeriod} options={["1", "5", "10", "30"]} label='days' value={periodSelect} /> */}
                                        <BasicText>
                                            {amount.length > 0 ? (Number(amount) - Number(amount) * 5.0 / 100.0)  : '0.0' } Obol
                                        </BasicText>
                                </Box>
                                <Box display='flex' alignItems='center' padding='10px' justifyContent='space-between'>
                                        <BasicText>
                                            List Tax:
                                        </BasicText>
                                        {/* <DataSelect onSelect={onChangePeriod} options={["1", "5", "10", "30"]} label='days' value={periodSelect} /> */}
                                        <BasicText>
                                            10 FTM
                                        </BasicText>
                                </Box>
                                <Box display='flex' alignItems='center' padding='10px' justifyContent='space-between'>
                                        <BasicText>
                                            Sale Period:
                                        </BasicText>
                                        <DataSelect width='75px' onSelect={onChangePeriod} options={["1", "5", "10", "30"]} label='days' value={periodSelect} />
                                </Box>
                                {/* <Box display='flex' mt={1} padding='10px' sx={{border: '0px solid blue'}}>
                                        <BasicText>
                                            Note:
                                        </BasicText>
                                        <BasicText ml={1} display='flex' sx={{color: 'red', border: '0px solid red'}}>
                                            While selling you can’t bridge, send or stake nft.
                                            Sell will be reverted!
                                        </BasicText>
                                </Box> */}
                        </Box>
                        {account ? (
                          <ActionButton size="small" color="primary" variant="contained" onClick={onConfirm} 
                              style={{marginRight: '10px', width: '100%'}}>
                          {isLoadingTx ? <CircularProgress size={20} sx={{ color: 'black' }} /> : getButtonText()}
                          </ActionButton>
                        ) : (
                          <ConnectWallet width={100} />
                        )}
                        {/* <ActionButton
                            disabled={!account || isPending}
                            onClick={() => {}}
                            sx={{ width: '100%' }}
                            >
                            {isPending ? <CircularProgress size={20} sx={{ color: 'black' }} /> : getButtonText()}
                        </ActionButton> */}
                </Box>
            </>
        }
    </Container>
    // sx={{borderBottom: '1px solid grey', paddingBottom: '10px'}}
  );
};

export default SellBox;
