import { useEffect, useState } from 'react';

import { getTokenPriceFromCGC } from 'utils/coingecko';
import { getTokens } from 'utils/tokens';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';

const useTokenPrice = (): { tokenPrices: any } => {
  const [tokenPrices, setTokenPrice] = useState({});

  const fetchTokenPrice = async () => {
    const tokens = getTokens(DEFAULT_ACTIVE_CHAIN_ID);

    const tokenIds = tokens.filter((row) => row.cgcId).map((row) => row.cgcId);
    const tokenIdsWithFtm = [...tokenIds, 'fantom'].join();
    const tokenPricesRaw = await getTokenPriceFromCGC(tokenIdsWithFtm);
    let tokenPrices2: any = {};
    Object.entries(tokenPricesRaw)
      .map((row) => ({ [row[0]]: row[1].usd }))
      .map((row) => {
        tokenPrices2 = { ...tokenPrices2, ...row };
        return row;
      });

    setTokenPrice({
      ...tokenPrices2,
      usdc: 1,
    });
  };

  useEffect(() => {
    fetchTokenPrice();

    setTimeout(() => {
      fetchTokenPrice();
    }, 100000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { tokenPrices };
};

export default useTokenPrice;
