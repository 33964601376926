import { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Box, CircularProgress, styled } from '@mui/material';

import Header from 'components/Header';
import Footer from 'components/Footer';
import { AccountModal, ConnectModal } from 'components/Modal';
import { useAppSelector } from 'state/hooks';
import useAuth from 'hooks/useAuth';

const MainLayoutBox = styled(Box)(() => ({
  minWidth: '320px',
  minHeight: '100vh',
  background: 'linear-gradient(180deg, #184A95 0%, #16191E 71.35%)',
}));

const OutLetContainer = styled(Box)(({ theme }) => ({
  borderColor: theme.palette.grey.A100,
}));

const layoutBgColors: any = {
  '250': 'linear-gradient(180deg, #184A95 0%, #16191E 71.35%)',
  '137': 'linear-gradient(180deg, #5000A0 0%, #16191E 71.35%)',
  '43114': 'linear-gradient(180deg, #D12C48 0%, #16191E 71.35%)',
};

interface Props {
  isLodaded: boolean
}

const MainLayout: FC<Props> = ({isLodaded}) => {
  const { isWalletConnectModalOpen, isAccountModalOpen } = useAppSelector((state) => state.modal);
  const { selectedChainId } = useAppSelector((state) => state.chain);
  const { login } = useAuth();

  return (
    <MainLayoutBox sx={{ background: layoutBgColors[selectedChainId || '250'] }}>
      {isWalletConnectModalOpen && <ConnectModal login={login} />}
      {isAccountModalOpen && <AccountModal />}
      <Header />
      <Box sx={{minHeight: 'calc(100vh - 195px)'}}>
        { !isLodaded && 
                          <Box
                          sx={{
                            minHeight: 'calc(100vh - 150px)',
                            fontSize: '100px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <CircularProgress size={40} sx={{ color: 'white' }} />
                        </Box>
        }
        { isLodaded && 
                <Suspense
                fallback={
                  <Box
                    sx={{
                      minHeight: 'calc(100vh - 150px)',
                      fontSize: '100px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress size={40} sx={{ color: 'white' }} />
                  </Box>
                }
              >
                <OutLetContainer>
                  <Box sx={{ display: 'flex' }}>
                    <Outlet />
                  </Box>
                </OutLetContainer>
              </Suspense>
        }

      </Box>
      <Footer />
    </MainLayoutBox>
  );
};

export default MainLayout;
