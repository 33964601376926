import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';
import LpPairAbi from 'config/abi/LpPair.json';
import multicall from 'utils/multicall';
import { getStakingPoolAbi, getStakingPools } from 'utils/stakings';
import { getTokens } from 'utils/tokens';
import ERC20Abi from 'config/abi/Erc20.json';
import { getBalanceInEther } from 'utils';
import { getTreasuryAddress } from 'utils/addressHelpers';

export const fetchUserTokenData = async (account: string, chainId: string) => {
  const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
  const tokens = getTokens(selectedChainid);
  const treasuryAddr = getTreasuryAddress(selectedChainid);

  let basedAddress = "";
  let bbondAddress = "";

  for( let i = 0; i < tokens.length; ){
    if( tokens[i].symbol === 'BASED' )
        basedAddress = tokens[i].address;
    if( tokens[i].symbol === 'BBOND' )
        bbondAddress = tokens[i].address;
    i += 1;
  }

  if( basedAddress.length === 0 || bbondAddress.length === 0 )
    return {basedBalance: 0, bbondBalance: 0};
    // fetch staking token balance and allowance
    const calls = [
    {
        address: basedAddress,
        name: 'balanceOf',
        params: [account],
    },
    {
        address: bbondAddress,
        name: 'balanceOf',
        params: [account],
    },
    {
        address: basedAddress,
        name: 'allowance',
        params: [account, treasuryAddr],
    },
    {
        address: bbondAddress,
        name: 'allowance',
        params: [account, treasuryAddr],
    },
    ];
    const [basedBalance, bbondBalance, basedAllowance, bbondAllowance] = await multicall(
        LpPairAbi,
        calls
      );
    const bbasedBalance = getBalanceInEther(basedBalance);
    const bbbondBalance = getBalanceInEther(bbondBalance);

    const bbasedAllowance = getBalanceInEther(basedAllowance);
    const bbbondAllowance = getBalanceInEther(bbondAllowance);

    return {basedAddress, bbondAddress, basedBalance: bbasedBalance, bbondBalance: bbbondBalance, basedAllowance: bbasedAllowance, bbondAllowance: bbbondAllowance}
};

export const fetchTheatreUserData = async (account: string, chainId: string) => {
  try{
    const data = await fetchUserTokenData(account, chainId);
    return data;
  }
  catch(err){
    console.log(err)
  }
  return [];
};
