import { icons } from 'config/constants/assets';

export interface SocialType {
  key: string;
  url: string;
  icon: any;
}

export const socials: SocialType[] = [
  { key: 'docs', url: 'https://basedfinance.gitbook.io/based-finance/', icon: icons.docs },
  { key: 'discord', url: 'https://discord.com/invite/RYqacpPTD7', icon: icons.discord },
  { key: 'github', url: 'https://github.com', icon: icons.github },
  { key: 'twitter', url: 'https://twitter.com/BasedDEFI', icon: icons.twitter },
  { key: 'telegram', url: 'https://t.me/BasedFinanceio', icon: icons.telegram },
  // { key: 'instagram', url: 'https://www.instagram.com/basedlabsio/', icon: icons.instagram },
  { key: 'facebook', url: 'https://www.facebook.com/people/Based-Labs/100083349280282/', icon: icons.facebook },
];
