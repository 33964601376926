import { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Grid, IconButton, Link, styled, TextField, Typography } from '@mui/material';
import { icons, tokens } from 'config/constants/assets';
import { TokenSelectInput } from 'components/common/TokenSelectCard/TokenSelectInput';
import DataSelect from 'components/common/Select/DataSelect';
import { ContainedButton } from 'components/common/Button';
import { useWeb3React } from '@web3-react/core';
import { useAppSelector } from 'state/hooks';
import { useNotification } from 'hooks/useNotification';
import { useNftFarmMasterChef } from 'hooks/useNftFarmMasterChef';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '12px 5px 12px',
  borderRadius: '10px',
  background: 'rgb(6,1,1,0.3)',
  position: 'relative'
//   boxShadow:
//     '0px 2.15748px 29.22142px 0px rgba(0, 0, 0, 0.05), 0px 3.80638px 39.85961px 0px rgba(0, 0, 0, 0.07), 0px 5.70356px 44.46048px 0px rgba(0, 0, 0, 0.08), 0px 10.1608px 47.29564px 0px rgba(0, 0, 0, 0.09), 0px 25.99347px 52.37106px 0px rgba(0, 0, 0, 0.10), 0px 100px 77px 0px rgba(0, 0, 0, 0.13)',

//   [theme.breakpoints.up('xs')]: {
//     minHeight: '400px',
//   },
//   [theme.breakpoints.up('md')]: {
//     minHeight: '550px',
//   },
}));

// nft list title
const SellTitle = styled(Box)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  color: '#CDC392',
  fontSize: '18px',
  textAlign: 'left',
}));

const BasicText = styled(Box)(() => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    color: 'white',
    fontSize: '15px',
    textAlign: 'left',
}));

const Header = styled(Box)(({ theme }) => ({
    display: 'flex', 
    // background: 'red',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('xs')]: {
        minHeight: '70px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '50px',
      },
}));

const ContentBox = styled(Box)(({ theme }) => ({
    display: 'flex', 
    alignItems: 'center',
    // flexDirection: 'column',
    background: 'rgb(6,1,1,0.3)',
    borderRadius: '10px',
    [theme.breakpoints.up('xs')]: {
        minHeight: '70px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '50px',
      },
}));

const ContentLeft = styled(Box)(({ theme }) => ({
    display: 'flex', 
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
        minHeight: '70px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '50px',
      },
}));

const ContentRight = styled(Box)(({ theme }) => ({
    display: 'flex', 
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
        minHeight: '70px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '50px',
      },
}));

// toggle section
const ToggleSection = styled(Box)(() => ({
    position: 'absolute',
    right: '-7px',
    top: 'calc(50%px - 10px)',
    background: '#2A3139',
    boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '28px',
    height: '28px',
  }));

const StyledTokenInput = styled('div')(({ theme }) => ({
    maxWidth: '100%',
    width: '100%',
    border: '1px solid grey',
    borderRadius: '10px',
    backgroundColor: 'transparent'
}));

const TokenImgSection = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  }));

  const ActionButton = styled(ContainedButton)(() => ({
    // maxWidth: '102px',
    height: '40px',
  }));
  
  const TokenImg = styled('img')(({ theme }) => ({
      height: '25px',
      width: '25px',
  }));

  const ToggleIcon = styled('img')(() => ({}));

interface Props {
    data?: any,
    onHideCard: () => void
}

const TransferBox: FC<Props> = ({ data, onHideCard }) => {
  const [isLoadingTx, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const ref = useRef<HTMLImageElement>(null)
  const [isExpanded, setExpanded] = useState<boolean>(true);
  const [periodSelect, setPeriodSelect] = useState<any>('1');
  const [isPending, setPending] = useState<boolean>(false);
  const { account } = useWeb3React();
  const [amount, setAmount] = useState('');
  const { data: farmList } = useAppSelector((state) => state.nftFarms);
  const { pendingTxHash } = useAppSelector((state) => state.modal);
  const pendingTxHashRef: { current: string | undefined } = useRef();
  pendingTxHashRef.current = pendingTxHash;
  const { onShowNotification } = useNotification();
  const [pool, setPool] = useState<any>(undefined);
  const { onTransferNft } = useNftFarmMasterChef(pool);

  const imageLoaded = () => {
    setIsLoading(false);
  }
  const onToggle = () => {
    setExpanded(!isExpanded);
  };
  const truncate = (str: string, n: number) => {
    return str?.length > n ? `${str.substr(0, n ) } ... ${str.substr(str.length - n, n) }`: str;
    };

  const handleChangeAddress = async (e: any) => {
      if (e.currentTarget.value === '' || e.currentTarget.value === 0) {
        setAmount(e.currentTarget.value);
      }
      setAmount(e.currentTarget.value);
  };

    useEffect(() => {
      if( account )
        setAmount(account)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account]);

    useEffect(() => {
      if( farmList.length > 0 && data){
          for( let i = 0; i < farmList.length; i+=1 ){
              if( farmList[i].masterChefAddress === data.stakeContract ) {
                  setPool(farmList[i]);
              }
          }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmList, data]);

  const onConfirm = async () => {

      setLoading(true);

      try {
          await onTransferNft(amount, data.tokenId);
          onHideCard();
          onShowNotification({
            title: 'Transaction Success',
            description: 'Transfer NFT',
            hasView: true,
            txHash: pendingTxHashRef.current,
          });
        } catch (err) {
          onShowNotification({ title: 'Transaction Failed', description: 'Transfer NFT' });
          console.log('Transfer Error: ', err);
        }
        setLoading(false);
    
  };

  return (
    <Container mt={1}>
        {/* <ToggleSection onClick={onToggle}>
        <ToggleIcon
          alt="toggle"
          src={icons.chevronDown}
          sx={{ transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
        />
      </ToggleSection>
        <Box display='flex' sx={{border: '0px solid red'}}>
            <SellTitle>Transfer</SellTitle>
        </Box> */}
        {isExpanded && 
            <>
                {/* <Box mt={2} display='flex' sx={{height: '5px', borderBottom: '1px solid grey'}}/> */}
                <Box mt={0} display='flex' flexDirection='column'>
                    <Box display='flex' flexDirection='column' sx={{minHeight: '40px', width: '100%', borderRadius: '15px', border: '0px solid grey'}}>
                    {/* <BasicText>
                          Recipient address
                    </BasicText> */}
                    <TextField
                        style={{width: '100%', marginTop: '10px'}}
                          error={amount.length <= 0 }
                          onChange={handleChangeAddress}
                          placeholder="0.0"
                          value={amount}
                          variant="outlined"
                          label="Recipient Address"
                          margin='none'
                          size='small'
                          InputLabelProps={{
                            style: {
                              maxHeight:"15px",
                              fontSize: 15,
                              fontFamily: 'Prompt',
                              fontStyle: 'normal',
                              fontWeight: '300',
                            },
                          }}
                          inputProps={{
                            style: {
                              height: '30px',
                              fontSize: 13
                            },
                        }}
                      /> 
                      </Box>
                        <ActionButton
                            disabled={!account || isPending}
                            onClick={onConfirm}
                            sx={{ width: '100%', marginTop: '10px' }}
                            >
                            {isLoadingTx ? <CircularProgress size={20} sx={{ color: 'black' }} /> : 'Send'}
                        </ActionButton>
                </Box>
            </>
        }
    </Container>
    // sx={{borderBottom: '1px solid grey', paddingBottom: '10px'}}
  );
};

export default TransferBox;
