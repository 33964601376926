import { FC } from 'react';
import { Input, InputAdornment, styled, TextField, TextFieldProps } from '@mui/material';
import { ContainedButton } from 'components/common/Button';

const StyledInput = styled('input')(() => ({
  background: 'none',
  border: '0px',
  color: 'lightgrey',
  fontSize: '18px',
  flex: '1',
  height: '56px',
  textAlign: 'right',
  margin: '0',
  padding: '0',
  outline: 'none',
  width: '100%',
  paddingLeft: '10px',
  paddingRight: '10px'
}));

const MaxButton = styled(ContainedButton)(() => ({
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '15px',
  padding: '0px 4px',
  height: '24px',
  minWidth: 'auto',
  width: 'auto',
}));

const StyledInputWrapper = styled('div')(() => ({
    alignItems: 'center',
    backgroundColor: 'rgba(32, 32, 43, 0.0)',
    borderRadius: '5px',
    border: '0px solid white',
    display: 'flex'
  }));

interface TokenSelectInputProps {
  symbol?: any;
  onClickMax?: () => void;
  textColor?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  amount: string;
  changeValue: (val: string) => void;
}

const TokenSelectInput: FC<TextFieldProps & TokenSelectInputProps> = ({ symbol = '', textColor = 'rgba(39, 40, 42, 0.8)', changeValue, onClickMax, startAdornment, endAdornment, amount, ...props }) => {
    function isNumeric(n: any) {
        return !Number.isNaN(Number(n)) && Number.isFinite(Number(n));
      }
    const change = (e: React.FormEvent<HTMLInputElement>) => {
        if( e.currentTarget.value !== "") {
            if (!isNumeric(e.currentTarget.value)) return;
        }
        else{
            changeValue('');
        } 
          
           if( Number(e.currentTarget.value)  === 0 ){
            changeValue('');
           }
           if( Number(e.currentTarget.value) > 100000000 )
                return;
           changeValue(e.currentTarget.value);
    };

    return (
        <StyledInputWrapper>
        {!!startAdornment && startAdornment}
        <StyledInput
            placeholder='0.0'
            value={amount}
            onChange={change}
        />
        {!!endAdornment && endAdornment}
    </StyledInputWrapper>
    );
}

export { TokenSelectInput };
