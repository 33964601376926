import { tokens as tokenImages } from 'config/constants/assets';

const basedLiquidityInfos = [
  {
    name: 'OBOL-FTM',
    chainId: 250,
    address: '0xE3E26Bd9cD2F32a8F60BfFf5DF88bB3b3C5Eb9f9',
    stakingToken: '0xE3E26Bd9cD2F32a8F60BfFf5DF88bB3b3C5Eb9f9',
    stakingToken0: '0x1539C63037D95f84A5981F96e43850d1451b6216', // obol
    stakingToken1: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // wFTM
    visible: true,
    tokenImage: tokenImages.obolFtmLp,
    symbol: 'OBOL-FTM-LP',
  },
  {
    name: 'SMELT-FTM',
    chainId: 250,
    address: '0x432a4654BC67ed86B3119cD256c490F2CEA1e42a',
    stakingToken: '0x432a4654BC67ed86B3119cD256c490F2CEA1e42a',
    stakingToken0: '0x141FaA507855E56396EAdBD25EC82656755CD61e', // smelt
    stakingToken1: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // wFTM
    visible: true,
    tokenImage: tokenImages.smeltFtmLp,
    symbol: 'SMELT-FTM-LP',
  },
  {
    name: 'BSHARE-FTM',
    chainId: 250,
    address: '0x52A5B9E36F53b54Ed9ddd7a4e66Ac26696E9F0be',
    stakingToken: '0x52A5B9E36F53b54Ed9ddd7a4e66Ac26696E9F0be',
    stakingToken0: '0x49C290Ff692149A4E16611c694fdED42C954ab7a', // bshare
    stakingToken1: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // wFTM
    visible: true,
    tokenImage: tokenImages.bshareFtmLp,
    symbol: 'BSHARE-FTM-LP',
  },
  {
    name: 'BASED-FTM',
    chainId: 250,
    address: '0x52A5B9E36F53b54Ed9ddd7a4e66Ac26696E9F0be',
    stakingToken: '0x0981916Bd96d375dAd0067Fd24E19120D2fcF5e6',
    stakingToken0: '0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae', // based
    stakingToken1: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // wFTM
    visible: true,
    tokenImage: tokenImages.basedFtmLp,
    symbol: 'BASED-FTM-LP',
  },
  {
    name: 'pBASED-MATIC',
    chainId: 137,
    address: '0xbb811d2171d176070dab7b5964e01c3be74a993a',
    stakingToken: '0xbb811d2171d176070dab7b5964e01c3be74a993a',
    stakingToken0: '0xd44779F0E18729cB6AFB7548cF10EF448a602668', // based
    stakingToken1: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270', // wFTM
    visible: true,
    tokenImage: tokenImages.basedMatic,
    symbol: 'BASED-MATIC',
    isShort: true,
    addUrl: "https://quickswap.exchange/#/pools/v2?currency0=ETH&currency1=0xd44779F0E18729cB6AFB7548cF10EF448a602668"
  },
  {
    name: 'aBASED-AVAX',
    chainId: 43114,
    address: '0xC0203Ab741B085d8ed677D4BF6a5fbb47913Fd46',
    stakingToken: '0xC0203Ab741B085d8ed677D4BF6a5fbb47913Fd46',
    stakingToken0: '0x1C1CDF8928824dac36d84B3486D598B9799bA6c0', // based
    stakingToken1: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7', // wFTM
    visible: true,
    tokenImage: tokenImages.basedMatic,
    symbol: 'BASED-AVAX',
    isShort: true,
    addUrl: "https://quickswap.exchange/#/pools/v2?currency0=ETH&currency1=0xd44779F0E18729cB6AFB7548cF10EF448a602668"
  },
];

const liquidityInfos = [...basedLiquidityInfos];
export { liquidityInfos };
