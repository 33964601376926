import { accountTokens, staking, tokens as tokenImages, wallets } from 'config/constants/assets';
import { FarmCategory } from 'types/farm';

const fantomMasterChefInfos = [
  {
    address: '0x7A1f47c8a26fD895228947ffc0482F3dD9c2cA29', // SmeltRewardPool
    type: 'smeltRewardPool',
    chainId: 250,
    category: FarmCategory.ACTIVE,
  },

  {
    address: '0x3A554736b2aF99F5574Ee630Db5Ae33Af118235e', // DeadPool
    type: 'dealPool',
    chainId: 250,
    category: FarmCategory.ACTIVE,
  },

  {
    address: '0x348D568221DE91D4ABF3908893Be5aa040b46F91', // DeadPool
    type: 'deadPool2',
    chainId: 250,
    category: FarmCategory.ACTIVE,
  },
  {
    address: '0x0c9c3cb59dC6017731A985c2ab9F5C1c6f4a33dA', // DeadPool
    type: 'deadPool3',
    chainId: 250,
    category: FarmCategory.ACTIVE,
  },
  {
    address: '0x66d1C92f2319C67DA822BAe1Ef33b2C85C391a7b', // SmeltRewardPool
    type: 'smeltRewardPool',
    chainId: 250,
    category: FarmCategory.RETIRED,
  },

  {
    address: '0x7A1f47c8a26fD895228947ffc0482F3dD9c2cA29', // SmeltRewardPool
    type: 'smeltRewardPool',
    chainId: 250,
    category: FarmCategory.TEAM,
  },

  {
    address: '0x08621e16577B1b0F6E38f272b9B21CC0C107D9c9', // SmeltRewardPool
    type: 'smeltRewardPool',
    chainId: 250,
    category: FarmCategory.TEST,
  },
  {
    address: '0x82634497e10636E9cEa00C7B1c301d8289a7ef54', // SmeltRewardPool
    type: 'smeltRewardPool',
    chainId: 250,
    category: FarmCategory.ACTIVE,
  },
];

// ft farms
const fantomFarmInfos = [
  {
    name: 'OBOL-FTM LP',
    poolId: 0,
    stakingToken: '0xE3E26Bd9cD2F32a8F60BfFf5DF88bB3b3C5Eb9f9',
    stakingToken0: '0x1539C63037D95f84A5981F96e43850d1451b6216', // obol
    stakingToken1: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // wFTM
    receiptToken: '', // receipt token
    rewardTokenName: 'Smelt',
    rewardToken: '0x141FaA507855E56396EAdBD25EC82656755CD61e', // smelt
    rewardTokenDecimal: 18,
    tokenImage: tokenImages.obolFtmLp,
    rewardTokenImage: tokenImages.rewardSmelt,
    masterChefAddress: '0x82634497e10636E9cEa00C7B1c301d8289a7ef54', // SmeltRewardPool
    farmType: 'smeltRewardPool',
    chainId: 250,
    category: FarmCategory.ACTIVE,
    location: 'farm',
    autocompounder: '',
    place: FarmCategory.ACTIVE,
    isAprZero: false
  },
  {
    name: 'SMELT-FTM LP',
    poolId: 1,
    stakingToken: '0x432a4654BC67ed86B3119cD256c490F2CEA1e42a',
    stakingToken0: '0x141FaA507855E56396EAdBD25EC82656755CD61e', // smelt
    stakingToken1: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // wFTM
    receiptToken: '', // receipt token
    rewardTokenName: 'Smelt',
    rewardToken: '0x141FaA507855E56396EAdBD25EC82656755CD61e', // smelt
    rewardTokenDecimal: 18,
    tokenImage: tokenImages.smeltFtmLp,
    rewardTokenImage: tokenImages.rewardSmelt,
    masterChefAddress: '0x82634497e10636E9cEa00C7B1c301d8289a7ef54', // SmeltRewardPool
    farmType: 'smeltRewardPool',
    chainId: 250,
    category: FarmCategory.ACTIVE,
    location: 'farm',
    autocompounder: '',
    place: FarmCategory.ACTIVE,
    isAprZero: false
  },
  {
    name: 'OBOL-FTM LP(Retired)',
    poolId: 3,
    stakingToken: '0xE3E26Bd9cD2F32a8F60BfFf5DF88bB3b3C5Eb9f9',
    stakingToken0: '0x1539C63037D95f84A5981F96e43850d1451b6216', // obol
    stakingToken1: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // wFTM
    receiptToken: '0x4ef20669E38751E4a585638d12dCFc6FF3635Dd1', // receipt token
    rewardTokenName: 'Smelt',
    rewardToken: '0x141FaA507855E56396EAdBD25EC82656755CD61e', // smelt
    rewardTokenDecimal: 18,
    tokenImage: tokenImages.obolFtmLp,
    rewardTokenImage: tokenImages.rewardSmelt,
    masterChefAddress: '0x7A1f47c8a26fD895228947ffc0482F3dD9c2cA29', // SmeltRewardPool
    farmType: 'smeltRewardPool',
    chainId: 250,
    category: FarmCategory.ACTIVE,
    location: 'farm',
    autocompounder: 'https://matrix.farm/?provider=basedV2',
    place: FarmCategory.ACTIVE,
    isAprZero: true
  },
  {
    name: 'SMELT-FTM LP(Retired)',
    poolId: 4,
    stakingToken: '0x432a4654BC67ed86B3119cD256c490F2CEA1e42a',
    stakingToken0: '0x141FaA507855E56396EAdBD25EC82656755CD61e', // smelt
    stakingToken1: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // wFTM
    receiptToken: '0x2733C1DAa3891E7c9Cdf9bC2aCAD74Aa78578B3b', // receipt token
    rewardTokenName: 'Smelt',
    rewardToken: '0x141FaA507855E56396EAdBD25EC82656755CD61e', // smelt
    rewardTokenDecimal: 18,
    tokenImage: tokenImages.smeltFtmLp,
    rewardTokenImage: tokenImages.rewardSmelt,
    masterChefAddress: '0x7A1f47c8a26fD895228947ffc0482F3dD9c2cA29', // SmeltRewardPool
    farmType: 'smeltRewardPool',
    chainId: 250,
    category: FarmCategory.ACTIVE,
    location: 'farm',
    autocompounder: 'https://matrix.farm/?provider=basedV2',
    place: FarmCategory.ACTIVE,
    isAprZero: true
  },
  {
    name: 'BASED-FTM LP',
    poolId: 0,
    stakingToken: '0x0981916Bd96d375dAd0067Fd24E19120D2fcF5e6',
    stakingToken0: '0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae', // based
    stakingToken1: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // wFTM
    receiptToken: '0x0000000000000000000000000000000000000000', // receipt token
    rewardTokenName: 'FTM',
    rewardToken: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // usdc
    rewardTokenDecimal: 18,
    tokenImage: tokenImages.basedFtmLp,
    rewardTokenImage: tokenImages.rewardFtm,
    masterChefAddress: '0x0c9c3cb59dC6017731A985c2ab9F5C1c6f4a33dA', // DeadPool
    farmType: 'deadPool3',
    chainId: 250,
    category: FarmCategory.ACTIVE,
    location: 'farm',
    autocompounder: '',
    place: FarmCategory.RETIRED,
    isAprZero: true
  },
  {
    name: 'BSHARE-FTM LP',
    poolId: 1,
    stakingToken: '0x52A5B9E36F53b54Ed9ddd7a4e66Ac26696E9F0be',
    stakingToken0: '0x49C290Ff692149A4E16611c694fdED42C954ab7a', // bshare
    stakingToken1: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // wFTM
    receiptToken: '0x0000000000000000000000000000000000000000', // receipt token
    rewardTokenName: 'FTM',
    rewardToken: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // usdc
    rewardTokenDecimal: 18,
    tokenImage: tokenImages.bshareFtmLp,
    rewardTokenImage: tokenImages.rewardFtm,
    masterChefAddress: '0x0c9c3cb59dC6017731A985c2ab9F5C1c6f4a33dA', // DeadPool
    farmType: 'deadPool3',
    chainId: 250,
    category: FarmCategory.ACTIVE,
    location: 'farm',
    autocompounder: '',
    place: FarmCategory.RETIRED,
    isAprZero: true
  },
  {
    name: 'Bbond',
    poolId: 2,
    stakingToken: '0xC078285F16665B3F4bCe74AbDCF0f4C877de3E9f', // BBOND token
    stakingToken0: '0x0000000000000000000000000000000000000000', // not lp
    stakingToken1: '0x0000000000000000000000000000000000000000', // not lp
    receiptToken: '0x0000000000000000000000000000000000000000', // receipt token
    rewardTokenName: 'FTM',
    rewardToken: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // usdc
    rewardTokenDecimal: 18,
    // tokenImage: tokenImages.bbondStaking,
    tokenImage: tokenImages.bbond,
    rewardTokenImage: tokenImages.rewardFtm,
    masterChefAddress: '0x0c9c3cb59dC6017731A985c2ab9F5C1c6f4a33dA', // DeadPool
    farmType: 'deadPool3',
    chainId: 250,
    category: FarmCategory.ACTIVE,
    location: 'staking',
    autocompounder: '',
    place: FarmCategory.ACTIVE,
    isAprZero: true
  },
  {
    name: 'BASED-FTM LP (Retired)',
    poolId: 0,
    stakingToken: '0x0981916Bd96d375dAd0067Fd24E19120D2fcF5e6',
    stakingToken0: '0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae', // based
    stakingToken1: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // wFTM
    receiptToken: '0x0000000000000000000000000000000000000000', // receipt token
    rewardTokenName: 'FTM',
    rewardToken: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // usdc
    rewardTokenDecimal: 18,
    tokenImage: tokenImages.basedFtmLp,
    rewardTokenImage: tokenImages.rewardFtm,
    masterChefAddress: '0x348D568221DE91D4ABF3908893Be5aa040b46F91', // DeadPool
    farmType: 'deadPool2',
    chainId: 250,
    category: FarmCategory.ACTIVE,
    location: 'farm',
    autocompounder: 'https://app.matrix.farm/vaults/0xA4504f428B18690C78DaA67E4ac01aED5677CFCC/',
    place: FarmCategory.RETIRED,
    isAprZero: true
  },
  {
    name: 'BSHARE-FTM LP (Retired)',
    poolId: 1,
    stakingToken: '0x52A5B9E36F53b54Ed9ddd7a4e66Ac26696E9F0be',
    stakingToken0: '0x49C290Ff692149A4E16611c694fdED42C954ab7a', // bshare
    stakingToken1: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // wFTM
    receiptToken: '0x0000000000000000000000000000000000000000', // receipt token
    rewardTokenName: 'FTM',
    rewardToken: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // usdc
    rewardTokenDecimal: 18,
    tokenImage: tokenImages.bshareFtmLp,
    rewardTokenImage: tokenImages.rewardFtm,
    masterChefAddress: '0x348D568221DE91D4ABF3908893Be5aa040b46F91', // DeadPool
    farmType: 'deadPool2',
    chainId: 250,
    category: FarmCategory.ACTIVE,
    location: 'farm',
    autocompounder: 'https://app.matrix.farm/vaults/0xF45307601bfbB8efaa842Cc3fc66bDA5AA421D7c/',
    place: FarmCategory.RETIRED,
    isAprZero: true
  },
  {
    name: 'Bbond',
    poolId: 2,
    stakingToken: '0xC078285F16665B3F4bCe74AbDCF0f4C877de3E9f', // BBOND token
    stakingToken0: '0x0000000000000000000000000000000000000000', // not lp
    stakingToken1: '0x0000000000000000000000000000000000000000', // not lp
    receiptToken: '0x0000000000000000000000000000000000000000', // receipt token
    rewardTokenName: 'FTM',
    rewardToken: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // usdc
    rewardTokenDecimal: 18,
    // tokenImage: tokenImages.bbondStaking,
    tokenImage: tokenImages.bbond,
    rewardTokenImage: tokenImages.rewardFtm,
    masterChefAddress: '0x348D568221DE91D4ABF3908893Be5aa040b46F91', // DeadPool
    farmType: 'deadPool2',
    chainId: 250,
    category: FarmCategory.ACTIVE,
    location: 'staking',
    autocompounder: '',
    place: FarmCategory.ACTIVE,
    isAprZero: true
  },
  {
    name: 'BASED-FTM LP(Retired)',
    poolId: 3,
    stakingToken: '0x0981916Bd96d375dAd0067Fd24E19120D2fcF5e6',
    stakingToken0: '0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae', // based
    stakingToken1: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // wFTM
    receiptToken: '0x0000000000000000000000000000000000000000', // receipt token
    rewardTokenName: 'Multi-USDC',
    rewardToken: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75', // usdc
    rewardTokenDecimal: 6,
    tokenImage: tokenImages.basedFtmLp,
    rewardTokenImage: tokenImages.rewardUsdc,
    masterChefAddress: '0x3A554736b2aF99F5574Ee630Db5Ae33Af118235e', // DeadPool
    farmType: 'dealPool',
    chainId: 250,
    category: FarmCategory.ACTIVE,
    location: 'farm',
    autocompounder: 'https://matrix.farm/?provider=basedV1',
    place: FarmCategory.RETIRED
  },
  {
    name: 'BSHARE-FTM LP(Retired)',
    poolId: 1,
    stakingToken: '0x52A5B9E36F53b54Ed9ddd7a4e66Ac26696E9F0be',
    stakingToken0: '0x49C290Ff692149A4E16611c694fdED42C954ab7a', // bshare
    stakingToken1: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // wFTM
    receiptToken: '0x0000000000000000000000000000000000000000', // receipt token
    rewardTokenName: 'Multi-USDC',
    rewardToken: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75', // usdc
    rewardTokenDecimal: 6,
    tokenImage: tokenImages.bshareFtmLp,
    rewardTokenImage: tokenImages.rewardUsdc,
    masterChefAddress: '0x3A554736b2aF99F5574Ee630Db5Ae33Af118235e', // DeadPool
    farmType: 'dealPool',
    chainId: 250,
    category: FarmCategory.ACTIVE,
    location: 'farm',
    autocompounder: 'https://matrix.farm/?provider=basedV1',
    place: FarmCategory.RETIRED
  },
  {
    name: 'Bbond(Retired)',
    poolId: 2,
    stakingToken: '0xC078285F16665B3F4bCe74AbDCF0f4C877de3E9f', // BBOND token
    stakingToken0: '0x0000000000000000000000000000000000000000', // not lp
    stakingToken1: '0x0000000000000000000000000000000000000000', // not lp
    receiptToken: '0x0000000000000000000000000000000000000000', // receipt token
    rewardTokenName: 'Multi-USDC',
    rewardToken: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75', // usdc
    rewardTokenDecimal: 6,
    // tokenImage: tokenImages.bbondStaking,
    tokenImage: tokenImages.bbond,
    rewardTokenImage: tokenImages.rewardUsdc,
    masterChefAddress: '0x3A554736b2aF99F5574Ee630Db5Ae33Af118235e', // DeadPool
    farmType: 'dealPool',
    chainId: 250,
    category: FarmCategory.ACTIVE,
    location: 'farm',
    autocompounder: 'https://matrix.farm/?provider=basedV1',
    place: FarmCategory.RETIRED
  },

  // TODO: retired pools
  {
    name: 'OBOL-FTM LP SPOOKY',
    poolId: 3,
    stakingToken: '0x47FcE13359ac80Cc1FC98D46688701B2Bb54300C',
    stakingToken0: '0x1539C63037D95f84A5981F96e43850d1451b6216', // obol
    stakingToken1: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // wFTM
    receiptToken: '0x0000000000000000000000000000000000000000', // receipt token
    rewardTokenName: 'Smelt',
    rewardToken: '0x141FaA507855E56396EAdBD25EC82656755CD61e', // smelt
    rewardTokenDecimal: 18,
    tokenImage: tokenImages.obolFtmLp,
    rewardTokenImage: tokenImages.rewardSmelt,
    masterChefAddress: '0x66d1C92f2319C67DA822BAe1Ef33b2C85C391a7b', // SmeltRewardPool
    farmType: 'smeltRewardPool',
    chainId: 250,
    category: FarmCategory.RETIRED,
    location: 'farm',
    place: FarmCategory.RETIRED,
  },
  {
    name: 'SMELT-FTM LP SPOOKY',
    poolId: 4,
    stakingToken: '0x02E060A4B8453C5dA554d66c2035e3163D453daA',
    stakingToken0: '0x141FaA507855E56396EAdBD25EC82656755CD61e', // smelt
    stakingToken1: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83', // wFTM
    receiptToken: '0x0000000000000000000000000000000000000000', // receipt token
    rewardTokenName: 'Smelt',
    rewardToken: '0x141FaA507855E56396EAdBD25EC82656755CD61e', // smelt
    rewardTokenDecimal: 18,
    tokenImage: tokenImages.smeltFtmLp,
    rewardTokenImage: tokenImages.rewardSmelt,
    masterChefAddress: '0x66d1C92f2319C67DA822BAe1Ef33b2C85C391a7b', // SmeltRewardPool
    farmType: 'smeltRewardPool',
    chainId: 250,
    category: FarmCategory.RETIRED,
    location: 'farm',
    place: FarmCategory.RETIRED
  },

  // TODO: team pools
  {
    name: 'STATER',
    poolId: 1,
    stakingToken: '0x5706d4d6694D22A11d98678Db9D461eadbeE7e41', // STARTER
    stakingToken0: '0x0000000000000000000000000000000000000000', // N/A
    stakingToken1: '0x0000000000000000000000000000000000000000', // N/A
    receiptToken: '0x0000000000000000000000000000000000000000', // receipt token
    rewardTokenName: 'Smelt',
    rewardToken: '0x141FaA507855E56396EAdBD25EC82656755CD61e', // smelt
    rewardTokenDecimal: 18,
    tokenImage: tokenImages.staterSmelter,
    rewardTokenImage: tokenImages.rewardSmelt,
    masterChefAddress: '0x7A1f47c8a26fD895228947ffc0482F3dD9c2cA29', // SmeltRewardPool
    farmType: 'smeltRewardPool',
    chainId: 250,
    category: FarmCategory.TEAM,
    location: 'farm',
    place: FarmCategory.TEAM
  },
  {
    name: 'SMELTER',
    poolId: 2,
    stakingToken: '0x34b0d4E3b6189865B304E54882bE5E2e833a026a', // SMELTER
    stakingToken0: '0x0000000000000000000000000000000000000000', // N/A
    stakingToken1: '0x0000000000000000000000000000000000000000', // N/A
    receiptToken: '0x0000000000000000000000000000000000000000', // receipt token
    rewardTokenName: 'Smelt',
    rewardToken: '0x141FaA507855E56396EAdBD25EC82656755CD61e', // smelt
    rewardTokenDecimal: 18,
    tokenImage: tokenImages.staterSmelter,
    rewardTokenImage: tokenImages.rewardSmelt,
    masterChefAddress: '0x7A1f47c8a26fD895228947ffc0482F3dD9c2cA29', // SmeltRewardPool
    farmType: 'smeltRewardPool',
    chainId: 250,
    category: FarmCategory.TEAM,
    location: 'farm',
    place: FarmCategory.TEAM
  },
];

// nft farms
const fantomNftFarmInfos = [
  // {
  //   name: '[Test] Prometheus',
  //   poolId: 0,
  //   masterChefAddress: '0x08621e16577B1b0F6E38f272b9B21CC0C107D9c9', // SmeltRewardPool
  //   farmType: 'smeltRewardPool',
  //   stakingToken: '0x35B228ECb46DBb3AF0bC3A3E0F34D9857dBEc549', // Test GodNFT
  //   stakingToken0: '0x0000000000000000000000000000000000000000',
  //   stakingToken1: '0x0000000000000000000000000000000000000000',
  //   receiptToken: '0xBe9E38E6e3386D67e1b7A3754dc39a8cd8b82b08', // Test GODFTP
  //   rewardTokenName: 'BLife',
  //   rewardToken: '0x14007ec3b333d2c9710f31730c1ef64a86ae3a27',
  //   rewardTokenDecimal: 18,
  //   rewardTokenImage: tokenImages.rewardTestSmelt,
  //   tokenImage: staking.logo,
  //   autoCompounder: 5,
  //   chainId: 250,
  //   category: FarmCategory.TEST,
  //   isTest: true,
  // },
  {
    name: 'Traveler Prometheus',
    poolId: 0,
    masterChefAddress: '0x08621e16577B1b0F6E38f272b9B21CC0C107D9c9', // SmeltRewardPool
    farmType: 'smeltRewardPool',
    stakingToken: '0x6d74573A73f9B52B42fc07888f243b62d53fd2e0', // Test GodNFT
    stakingToken0: '0x0000000000000000000000000000000000000000',
    stakingToken1: '0x0000000000000000000000000000000000000000',
    receiptToken: '0xBe9E38E6e3386D67e1b7A3754dc39a8cd8b82b08', // Test GODFTP
    rewardTokenName: 'BLife',
    rewardToken: '0x14007ec3b333d2c9710f31730c1ef64a86ae3a27',
    rewardTokenDecimal: 18,
    rewardTokenImage: tokenImages.rewardTestSmelt,
    tokenImage: staking.logo,
    autoCompounder: 5,
    chainId: 250,
    category: FarmCategory.INVISIBLE,
    isTest: true,
    isAprZero: true,
    isFake: false
  },
  {
    name: 'Prometheus Fantom Emperor(Retired)',
    poolId: 5,
    masterChefAddress: '0x7A1f47c8a26fD895228947ffc0482F3dD9c2cA29', // SmeltRewardPool
    farmType: 'smeltRewardPool',
    stakingToken: '0xC6B720270aB699f52b1e5f529aA364f7407FceCd', // Test GodNFT
    stakingToken0: '0x0000000000000000000000000000000000000000',
    stakingToken1: '0x0000000000000000000000000000000000000000',
    receiptToken: '0xBe9E38E6e3386D67e1b7A3754dc39a8cd8b82b08', // Test GODFTP
    rewardTokenName: 'Smelt',
    rewardToken: '0x141FaA507855E56396EAdBD25EC82656755CD61e',
    rewardTokenDecimal: 18,
    rewardTokenImage: tokenImages.rewardSmelt,
    tokenImage: staking.travelLogo,
    autoCompounder: 5,
    chainId: 250,
    category: FarmCategory.ACTIVE,
    isTest: false,
    isRecieptDisabled: true,
    isAprZero: true,
    isFake: false
  },
  {
    name: 'Prometheus Origins',
    poolId: 2,
    masterChefAddress: '0x82634497e10636E9cEa00C7B1c301d8289a7ef54', // SmeltRewardPool
    farmType: 'smeltRewardPool',
    stakingToken: '0xc5777AD2250D7b12a3f2383afB4464b9E49bE297', // GodNFT
    stakingToken0: '0x0000000000000000000000000000000000000000',
    stakingToken1: '0x0000000000000000000000000000000000000000',
    receiptToken: '', // GODFTP
    rewardTokenName: 'Smelt',
    rewardToken: '0x141FaA507855E56396EAdBD25EC82656755CD61e',
    rewardTokenDecimal: 18,
    rewardTokenImage: tokenImages.rewardSmelt,
    tokenImage: staking.logo,
    autoCompounder: 5,
    chainId: 250,
    category: FarmCategory.ACTIVE,
    isTest: false,
    autocompounder: '',
    isRecieptDisabled: true,
    isAprZero: false,
    isFake: false
  },
  {
    name: 'Prometheus Fantom Emperor',
    poolId: 3,
    masterChefAddress: '0x82634497e10636E9cEa00C7B1c301d8289a7ef54', // SmeltRewardPool
    farmType: 'smeltRewardPool',
    stakingToken: '0xC6B720270aB699f52b1e5f529aA364f7407FceCd', // Test GodNFT
    stakingToken0: '0x0000000000000000000000000000000000000000',
    stakingToken1: '0x0000000000000000000000000000000000000000',
    receiptToken: '', // Test GODFTP
    rewardTokenName: 'Smelt',
    rewardToken: '0x141FaA507855E56396EAdBD25EC82656755CD61e',
    rewardTokenDecimal: 18,
    rewardTokenImage: tokenImages.rewardSmelt,
    tokenImage: staking.travelLogo,
    autoCompounder: 5,
    chainId: 250,
    category: FarmCategory.ACTIVE,
    isTest: false,
    isRecieptDisabled: true,
    isAprZero: false,
    isFake: false
  },
  {
    name: 'Prometheus Origins(Retired)',
    poolId: 0,
    masterChefAddress: '0x7A1f47c8a26fD895228947ffc0482F3dD9c2cA29', // SmeltRewardPool
    farmType: 'smeltRewardPool',
    stakingToken: '0xc5777AD2250D7b12a3f2383afB4464b9E49bE297', // GodNFT
    stakingToken0: '0x0000000000000000000000000000000000000000',
    stakingToken1: '0x0000000000000000000000000000000000000000',
    receiptToken: '0xBe9E38E6e3386D67e1b7A3754dc39a8cd8b82b08', // GODFTP
    rewardTokenName: 'Smelt',
    rewardToken: '0x141FaA507855E56396EAdBD25EC82656755CD61e',
    rewardTokenDecimal: 18,
    rewardTokenImage: tokenImages.rewardSmelt,
    tokenImage: staking.logo,
    autoCompounder: 5,
    chainId: 250,
    category: FarmCategory.ACTIVE,
    isTest: false,
    autocompounder: 'https://matrix.farm/?provider=basedV2',
    isRecieptDisabled: false,
    isAprZero: true,
    isFake: false
  },
  {
    name: "Polygon Emperor Footprint",
    poolId: 0,
    masterChefAddress: '0x7A1f47c8a26fD895228947ffc0482F3dD9c2cA29', // SmeltRewardPool
    farmType: 'smeltRewardPool',
    stakingToken: '0xc5777AD2250D7b12a3f2383afB4464b9E49bE297', // GodNFT
    stakingToken0: '0x0000000000000000000000000000000000000000',
    stakingToken1: '0x0000000000000000000000000000000000000000',
    receiptToken: '0xBe9E38E6e3386D67e1b7A3754dc39a8cd8b82b08', // GODFTP
    rewardTokenName: 'Smelt',
    rewardToken: '0x141FaA507855E56396EAdBD25EC82656755CD61e',
    rewardTokenDecimal: 18,
    rewardTokenImage: tokenImages.rewardSmelt,
    tokenImage: accountTokens.godFootprint,
    autoCompounder: 5,
    chainId: 250,
    category: FarmCategory.ACTIVE,
    isTest: false,
    autocompounder: 'https://matrix.farm/?provider=basedV2',
    isFake: true,
    back: 'linear-gradient(180deg, #5000A0 0%, #16191E 100.35%)'
  },
  {
    name: "Avalanche Emperor Footprint",
    poolId: 0,
    masterChefAddress: '0x7A1f47c8a26fD895228947ffc0482F3dD9c2cA29', // SmeltRewardPool
    farmType: 'smeltRewardPool',
    stakingToken: '0xc5777AD2250D7b12a3f2383afB4464b9E49bE297', // GodNFT
    stakingToken0: '0x0000000000000000000000000000000000000000',
    stakingToken1: '0x0000000000000000000000000000000000000000',
    receiptToken: '0xBe9E38E6e3386D67e1b7A3754dc39a8cd8b82b08', // GODFTP
    rewardTokenName: 'Smelt',
    rewardToken: '0x141FaA507855E56396EAdBD25EC82656755CD61e',
    rewardTokenDecimal: 18,
    rewardTokenImage: tokenImages.rewardSmelt,
    tokenImage: accountTokens.godFootprint,
    autoCompounder: 5,
    chainId: 250,
    category: FarmCategory.ACTIVE,
    isTest: false,
    autocompounder: 'https://matrix.farm/?provider=basedV2',
    isFake: true,
    back: 'linear-gradient(180deg, #D12C48 0%, #16191E 100.35%)'
  },
  {
    name: "ZkSync Emperor Footprint",
    poolId: 0,
    masterChefAddress: '0x7A1f47c8a26fD895228947ffc0482F3dD9c2cA29', // SmeltRewardPool
    farmType: 'smeltRewardPool',
    stakingToken: '0xc5777AD2250D7b12a3f2383afB4464b9E49bE297', // GodNFT
    stakingToken0: '0x0000000000000000000000000000000000000000',
    stakingToken1: '0x0000000000000000000000000000000000000000',
    receiptToken: '0xBe9E38E6e3386D67e1b7A3754dc39a8cd8b82b08', // GODFTP
    rewardTokenName: 'Smelt',
    rewardToken: '0x141FaA507855E56396EAdBD25EC82656755CD61e',
    rewardTokenDecimal: 18,
    rewardTokenImage: tokenImages.rewardSmelt,
    tokenImage: accountTokens.godFootprint,
    autoCompounder: 5,
    chainId: 250,
    category: FarmCategory.ACTIVE,
    isTest: false,
    autocompounder: 'https://matrix.farm/?provider=basedV2',
    isFake: true,
    back: 'linear-gradient(180deg, #6B427A 0%, #16191E 100.35%)'
  },
  {
    name: "Base Emperor Footprint",
    poolId: 0,
    masterChefAddress: '0x7A1f47c8a26fD895228947ffc0482F3dD9c2cA29', // SmeltRewardPool
    farmType: 'smeltRewardPool',
    stakingToken: '0xc5777AD2250D7b12a3f2383afB4464b9E49bE297', // GodNFT
    stakingToken0: '0x0000000000000000000000000000000000000000',
    stakingToken1: '0x0000000000000000000000000000000000000000',
    receiptToken: '0xBe9E38E6e3386D67e1b7A3754dc39a8cd8b82b08', // GODFTP
    rewardTokenName: 'Smelt',
    rewardToken: '0x141FaA507855E56396EAdBD25EC82656755CD61e',
    rewardTokenDecimal: 18,
    rewardTokenImage: tokenImages.rewardSmelt,
    tokenImage: accountTokens.godFootprint,
    autoCompounder: 5,
    chainId: 250,
    category: FarmCategory.ACTIVE,
    isTest: false,
    autocompounder: 'https://matrix.farm/?provider=basedV2',
    isFake: true,
    back: 'linear-gradient(180deg, #184A95 0%, #16191E 71.35%)'
  },
  {
    name: 'Prometheus Polygon Realm',
    poolId: 0,
    masterChefAddress: '0x7A1f47c8a26fD895228947ffc0482F3dD9c2cA29', // SmeltRewardPool
    farmType: 'smeltRewardPool',
    stakingToken: '0xc5777AD2250D7b12a3f2383afB4464b9E49bE297', // GodNFT
    stakingToken0: '0x0000000000000000000000000000000000000000',
    stakingToken1: '0x0000000000000000000000000000000000000000',
    receiptToken: '0xBe9E38E6e3386D67e1b7A3754dc39a8cd8b82b08', // GODFTP
    rewardTokenName: 'Smelt',
    rewardToken: '0x141FaA507855E56396EAdBD25EC82656755CD61e',
    rewardTokenDecimal: 18,
    rewardTokenImage: tokenImages.rewardSmelt,
    tokenImage: staking.travelLogo,
    autoCompounder: 5,
    chainId: 137,
    category: FarmCategory.ACTIVE,
    isTest: false,
    autocompounder: 'https://matrix.farm/?provider=basedV2',
    isFake: true
  },
  {
    name: 'Prometheus Avalanche Realm',
    poolId: 0,
    masterChefAddress: '0x7A1f47c8a26fD895228947ffc0482F3dD9c2cA29', // SmeltRewardPool
    farmType: 'smeltRewardPool',
    stakingToken: '0xc5777AD2250D7b12a3f2383afB4464b9E49bE297', // GodNFT
    stakingToken0: '0x0000000000000000000000000000000000000000',
    stakingToken1: '0x0000000000000000000000000000000000000000',
    receiptToken: '0xBe9E38E6e3386D67e1b7A3754dc39a8cd8b82b08', // GODFTP
    rewardTokenName: 'Smelt',
    rewardToken: '0x141FaA507855E56396EAdBD25EC82656755CD61e',
    rewardTokenDecimal: 18,
    rewardTokenImage: tokenImages.rewardSmelt,
    tokenImage: staking.travelLogo,
    autoCompounder: 5,
    chainId: 43114,
    category: FarmCategory.ACTIVE,
    isTest: false,
    autocompounder: 'https://matrix.farm/?provider=basedV2',
    isFake: true
  },
];

const farmInfos = [...fantomFarmInfos];
const nftFarmInfos = [...fantomNftFarmInfos];

const masterChefInfos = [...fantomMasterChefInfos];

export { farmInfos, nftFarmInfos, masterChefInfos };
