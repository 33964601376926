import { getMasterChefAbi, getNftFarms } from 'utils/farms';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';
import GodNftAbi from 'config/abi/GodNft.json';
import MarketAbi from 'config/abi/BasedMarketplace.json'
import multicall from 'utils/multicall';
import { BigNumber } from 'ethers';
import { FarmCategory } from 'types/farm';
import LpPairAbi from 'config/abi/LpPair.json';
import { getBalanceInEther } from 'utils';
import { getMarketABI, getMarketAddress, getMarketSupportedCollectionsInfo } from 'utils/market';
import { AbiItem } from 'web3-utils';
import { NftMarketInfo, SupportedCollections } from 'types/nft';
import { getMarketBuys, getMarketSells, getMarketVolume } from 'utils/backend';

// single
export const fetchMarketGlobalInfo = async (chainId: string) => {
    const collections = getMarketSupportedCollectionsInfo(Number(chainId));

    const supported: SupportedCollections[] = []

    for( let i = 0; i < collections.length; i+=1 ){
        supported.push({address: collections[i].address, tokens: collections[i].tokens, name: collections[i].name, chainId: collections[i].chainId, img: collections[i].img, collectionName: collections[i].collectionName});
    }
    
    try{
        if( collections.length  > 0 ){

            const marketAddress = getMarketAddress(Number(chainId));
  
            if( marketAddress ){
              const marketCollectionsData = await Promise.all(
                  collections.map(async (row: any) => {
                    const { address } = row;
                    if (!address) return { ...row };
                      const calls1 = [
                          {
                          address: marketAddress,
                          name: 'numTokenListings',
                          params: [row.address],
                          },
                      ];
                      
                      const [listingSize] = await multicall(MarketAbi, calls1);
  
                    //   console.log(`Listing ${row.address} size ${listingSize}`)
                      const size = getBalanceInEther(listingSize[0]);
                      const nfts = [];
                      if( size > 0 ){
                          let readTimes = 1;
                          if( size > 200 ){
                              readTimes = Math.ceil(size / 200);
                          }
                         // for( let i = 0; i < readTimes; i+=1 ){
                              const calls2 = [
                                  {
                                  address: marketAddress,
                                  name: 'getTokenListings',
                                  params: [row.address, 0 ,200],
                                  },
                              ];
                              const list = await multicall(MarketAbi, calls2);
                              if( list.length > 0 ){
                                  const listings = list[0];
                                  for( let i = 0; i < listings.listings.length; i+=1 ){
                                        if( listings.listings[i].seller === '0x0000000000000000000000000000000000000000' || 
                                                listings.listings[i].paymentToken === '0x0000000000000000000000000000000000000000' )
                                        {
                                            // console.log("err")
                                        } else{
                                            const isn : NftMarketInfo = {nftId: Number(listings.listings[i].tokenId),
                                                seller: listings.listings[i].seller, 
                                                paymentToken: listings.listings[i].paymentToken, 
                                                value: getBalanceInEther(listings.listings[i].value),
                                                expires: Number(listings.listings[i].expireTimestamp)}
                                            nfts.push(isn)
                                        }

                                  }
                              }
                        //  }
     
                      }
                      // Number(((new Date().getTime() / 1000) - (86400*3)).toFixed(0))
                      const offchainData = await getMarketVolume({startTime: Number(((new Date().getTime() / 1000) - (86400)).toFixed(0)), endTime: Number((new Date().getTime() / 1000- (0)).toFixed(0)), collectionNFT: row.address});
                      const offchainDataSells = await getMarketSells({startTime: 0, endTime: Number((new Date().getTime() / 1000).toFixed(0)), collectionNFT: row.address});
                      const offchainDataBuys = await getMarketBuys({startTime: 0, endTime: Number((new Date().getTime() / 1000).toFixed(0)), collectionNFT: row.address});
                      const offchainDataAll = await getMarketVolume({startTime: 0, endTime: Number((new Date().getTime() / 1000- (0)).toFixed(0)), collectionNFT: row.address});

                      return {address, nfts, dayVolumeStatistic: offchainData, dayBuys: offchainDataSells, daySells: offchainDataBuys, allVolume: offchainDataAll, enabled: row.enabled};
                  })
              )
              return {
                  collections: marketCollectionsData,
                  supportedCollections: supported
              };
            }
      }
    }
    catch(err){
        console.log(err)
    }
    return {
        collections: []
    };
  };

  export const fetchMarketTokenAllowance = async (chainId: string, tokenAddress: string, account: string) => {
    if( !account )
        return 0;

    const marketAddress = getMarketAddress(Number(chainId));

    if( marketAddress ){
        const calls1 = [
            {
              address: tokenAddress,
              name: 'allowance',
              params: [account, marketAddress],
            },
          ];
          const [allowanceRaw] = await multicall(LpPairAbi, calls1);
          return getBalanceInEther(allowanceRaw[0]);
    }

    return 0;
  };