import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useDispatch } from 'react-redux';
import { BigNumber, ethers } from 'ethers';

import { useLpContract, useMasterChefContract, useNftContract } from 'hooks/useContract';
import {
  fetchNftFarmGlobalDataAsync,
  fetchNftFarmUserDepositDataAsync,
  fetchNftFarmUserTokenDataAsync,
} from 'state/actions';
import { useAppSelector } from 'state/hooks';
import { FarmInfo } from 'types/farm';
import { setPendingTxHash } from 'state/modal/modalSlice';
import { useNotification } from 'hooks/useNotification';

export const useNftFarmMasterChef = (farmInfo: FarmInfo) => {
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const masterChefContract = useMasterChefContract(farmInfo?.masterChefAddress, farmInfo?.farmType);
  const stakingTokenContract = useNftContract(farmInfo?.stakingToken);
  const receiptTokenContract = useLpContract(farmInfo?.receiptToken);
  const { selectedChainId } = useAppSelector((state) => state.chain);

  const { onShowNotification } = useNotification();

  const handleApproveStakingToken = useCallback(async (): Promise<string | undefined> => {
    if (!account || !masterChefContract || !stakingTokenContract) return '';

    const tx = await stakingTokenContract.setApprovalForAll(masterChefContract.address, true);
    onShowNotification({
      title: 'Transaction Pending',
      description: 'Approve Staking Token',
      hasView: true,
      txHash: tx.hash,
    });

    dispatch(setPendingTxHash(tx.hash));

    const receipt = await tx.wait();

    if (receipt.status !== 1) {
      throw new Error();
    }

    dispatch(fetchNftFarmUserTokenDataAsync(account, selectedChainId, farmInfo));

    return tx.txHash;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, dispatch, stakingTokenContract, masterChefContract]);

  const handleApproveReceiptToken = useCallback(async (): Promise<string | undefined> => {
    if (farmInfo.farmType === 'dealPool' || farmInfo.farmType === 'deadPool2'|| farmInfo.farmType === 'deadPool3') return '';
    if (!account || !masterChefContract || !receiptTokenContract) return '';

    const tx = await receiptTokenContract.approve(masterChefContract.address, ethers.constants.MaxUint256);
    onShowNotification({
      title: 'Transaction Pending',
      description: 'Approve Receipt Token',
      hasView: true,
      txHash: tx.hash,
    });
    dispatch(setPendingTxHash(tx.hash));

    const receipt = await tx.wait();

    if (receipt.status !== 1) {
      throw new Error();
    }

    dispatch(fetchNftFarmUserTokenDataAsync(account, selectedChainId, farmInfo));

    return tx.txHash;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, dispatch, stakingTokenContract, masterChefContract]);

  const handleDeposit = useCallback(
    async (nftIds: number[]): Promise<string | undefined> => {
      if (!account || !masterChefContract) return '';

      const tx = await masterChefContract.StakeNft(farmInfo.poolId, nftIds);

      onShowNotification({
        title: 'Transaction Pending',
        description: 'Deposit',
        hasView: true,
        txHash: tx.hash,
      });
      dispatch(setPendingTxHash(tx.hash));

      const receipt = await tx.wait();
      if (receipt.status !== 1) {
        throw new Error();
      }

      dispatch(fetchNftFarmUserTokenDataAsync(account, selectedChainId, farmInfo));
      dispatch(fetchNftFarmGlobalDataAsync(selectedChainId));
      dispatch(fetchNftFarmUserDepositDataAsync(account, selectedChainId, farmInfo));

      return tx.txHash;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, dispatch, masterChefContract]
  );

  const handleWithdraw = useCallback(
    async (nftIds: number[]): Promise<string | undefined> => {
      if (!account || !masterChefContract) return '';

      const tx = await masterChefContract.UnstakeNft(farmInfo.poolId, nftIds);
      onShowNotification({
        title: 'Transaction Pending',
        description: 'Withdraw',
        hasView: true,
        txHash: tx.hash,
      });
      dispatch(setPendingTxHash(tx.hash));

      const receipt = await tx.wait();
      if (receipt.status !== 1) {
        throw new Error();
      }

      dispatch(fetchNftFarmUserTokenDataAsync(account, selectedChainId, farmInfo));
      dispatch(fetchNftFarmGlobalDataAsync(selectedChainId));
      dispatch(fetchNftFarmUserDepositDataAsync(account, selectedChainId, farmInfo));

      return tx.txHash;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, dispatch, masterChefContract]
  );

  const handleClaim = useCallback(
    async (): Promise<string | undefined> => {
      if (!account || !masterChefContract) return '';

      const tx = await masterChefContract.claimRewardNft(farmInfo.poolId);
      onShowNotification({
        title: 'Transaction Pending',
        description: 'Claim NFT Reward',
        hasView: true,
        txHash: tx.hash,
      });
      dispatch(setPendingTxHash(tx.hash));

      const receipt = await tx.wait();
      if (receipt.status !== 1) {
        throw new Error();
      }

      dispatch(fetchNftFarmUserTokenDataAsync(account, selectedChainId, farmInfo));
      dispatch(fetchNftFarmGlobalDataAsync(selectedChainId));
      dispatch(fetchNftFarmUserDepositDataAsync(account, selectedChainId, farmInfo));

      return tx.txHash;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, dispatch, masterChefContract]
  );

  const handleTransferNft = useCallback(
    async (recipientAddress: string, nftid: number): Promise<string | undefined> => {
      if (!account || !recipientAddress || !stakingTokenContract) return '';

      if( account.length !== recipientAddress.length )
        return '';

      const tx = await stakingTokenContract.transferFrom(account, recipientAddress, nftid);
      onShowNotification({
        title: 'Transaction Pending',
        description: 'Transfer NFT',
        hasView: true,
        txHash: tx.hash,
      });
      dispatch(setPendingTxHash(tx.hash));

      const receipt = await tx.wait();
      if (receipt.status !== 1) {
        throw new Error();
      }

      dispatch(fetchNftFarmUserTokenDataAsync(account, selectedChainId, farmInfo));
      dispatch(fetchNftFarmGlobalDataAsync(selectedChainId));
      dispatch(fetchNftFarmUserDepositDataAsync(account, selectedChainId, farmInfo));

      return tx.txHash;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, dispatch, stakingTokenContract]
  );

  return {
    onApproveStakingToken: handleApproveStakingToken,
    onApproveReceiptToken: handleApproveReceiptToken,
    onDeposit: handleDeposit,
    onWithdraw: handleWithdraw,
    onClaim: handleClaim,
    onTransferNft: handleTransferNft
  };
};
