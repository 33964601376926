import React from 'react';
import { Box, Dialog, styled, Typography } from '@mui/material';
import { useWeb3React } from '@web3-react/core';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setIsAccountModalOpen } from 'state/modal/modalSlice';
import useAuth from 'hooks/useAuth';
import { LinkButton } from 'components/common/Button';
import { useEthBalance } from 'hooks/useEthBalance';
import { getBalanceInEther } from 'utils/formatBalance';
import { CHAIN_INFO } from 'config/constants/chains';
import { icons } from 'config/constants/assets';
import { useAccountTokenBalance } from 'hooks/useAccountTokenBalance';
import TokenCard from './TokenCard';

const ModalDialog = styled(Dialog)(() => ({
  '.MuiDialog-container > .MuiPaper-root': {
    borderRadius: '12px',
    maxWidth: '350px',
    width: '100%',
    background: '#2A3139',
    boxShadow:
      '0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
    padding: '30px 30px',
    border: '1px solid rgba(217, 217, 217, 0.2)',
  },
}));

// header
const ModalTitle = styled(Typography)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '19px',
  lineHeight: '25px',
  color: '#ffffff',
}));

const WalletIcon = styled('img')(() => ({
  width: '15px',
}));

// account link, disconnect section
const ExplorerLinkText = styled(Typography)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: 'normal',
  color: '#ffffff',
  cursor: 'pointer',

  '&:hover': {
    textDecoration: 'underline',
  },
}));

const DisconnectButton = styled(LinkButton)(() => ({
  color: '#CC8B87',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: 'normal',
  textDecoration: 'none',
  padding: '0',

  '&:hover': {
    color: '#CC8B87',
    opacity: '0.8',
    textDecoration: 'none',
  },
}));

// native token section
const ChainLogo = styled('img')(() => ({
  width: '30px',
  borderRadius: '50%'
}));

const ChainSymbol = styled(Typography)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: 'normal',
  color: '#ffffff',
  cursor: 'pointer',
}));

const ChainValue = styled(Typography)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: 'normal',
  color: '#ffffff',
  cursor: 'pointer',
}));

const getDisplayedTokens = (tokens: any[], showDustToken: boolean) => {
  let tokens1 = [...tokens];
  if (!showDustToken) {
    tokens1 = tokens.filter((token) => token.balance > 0.01 || token?.alwaysShow);
  }
  return tokens1.sort((a, b) => (a.balance > b.balance ? -1 : 1));
};

const AccountModal: React.FC = () => {
  const { isAccountModalOpen } = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();
  const { logout } = useAuth();
  const { account } = useWeb3React();

  const ethBalanceRaw = useEthBalance();
  const ethBalance = getBalanceInEther(ethBalanceRaw);
  const { selectedChainId } = useAppSelector((state) => state.chain);
  const accountTokenInfos = useAccountTokenBalance();
  const chainInfo = CHAIN_INFO[Number(selectedChainId)];
  const showDustTokens = false;

  const displayedTokens = getDisplayedTokens(accountTokenInfos, showDustTokens);

  // close modal
  const onClose = () => {
    dispatch(setIsAccountModalOpen(false));
  };

  // disconnect connected wallet
  const onLogout = () => {
    logout();
    onClose();
  };

  // open account url on explorer
  const onOpenAccountUrl = () => {
    const accountUrl = `${chainInfo.explorer}/address/${account}`;
    window.open(accountUrl, '_blank');
  };

  return (
    <ModalDialog maxWidth="xs" onClose={onClose} open={isAccountModalOpen}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <ModalTitle>Connect Wallet</ModalTitle>
        <WalletIcon alt="chain logo" src={icons.wallet} />
      </Box>
      <Box sx={{ gap: '12px', display: 'flex', flexDirection: 'column' }}>
        {/* erc20, nft balances section */}
        <Box
          sx={{
            marginTop: '12px',
            maxHeight: '230px',
            overflowY: 'auto',
          }}
        >
          <Box sx={{ marginRight: '4px', gap: '6px', display: 'flex', flexDirection: 'column' }}>
            {displayedTokens.map((tokenInfo) => (
              <TokenCard key={tokenInfo.address} token={tokenInfo} />
            ))}
          </Box>
        </Box>

        {/* account link, disconnect section */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <ExplorerLinkText onClick={onOpenAccountUrl}>{`View on ${chainInfo.explorerName}`}</ExplorerLinkText>
          <DisconnectButton onClick={onLogout}>Disconnect</DisconnectButton>
        </Box>

        {/* native token section */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            {chainInfo.logoUrl && (
              <Box>
                <ChainLogo alt="chain logo" src={chainInfo.logoUrl} />
              </Box>
            )}
            <ChainSymbol>{chainInfo?.nativeCurrency?.symbol || 'FTM'}</ChainSymbol>
          </Box>
          <ChainValue>{`${ethBalance.toFixed(2)}`}</ChainValue>
        </Box>
      </Box>
    </ModalDialog>
  );
};

export default AccountModal;
