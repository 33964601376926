import { tokens as tokenImages } from 'config/constants/assets';

const fantomTokenInfos = [
  {
    address: '0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae',
    symbol: 'BASED',
    decimals: 18,
    tokenImage: tokenImages.based,
    chainId: 250,
    hasTax: true,
    visible: true,
    sellTax: 0.001,
    buyTax: 0,
    pairedTokenAddress: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    hasPair: true,
    showNativePrice: true,
    isStake: true
  },
  {
    address: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
    symbol: 'FTM',
    decimals: 18,
    tokenImage: tokenImages.rewardFtm,
    chainId: 250,
    hasTax: true,
    visible: false,
    sellTax: 0.04,
    buyTax: 0,
    pairedTokenAddress: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    hasPair: true,
    showNativePrice: true,
    isStake: false
  },
  {
    address: '0x49C290Ff692149A4E16611c694fdED42C954ab7a',
    symbol: 'BSHARE',
    decimals: 18,
    tokenImage: tokenImages.bshard,
    chainId: 250,
    hasTax: true,
    visible: true,
    sellTax: 0.001,
    buyTax: 0,
    pairedTokenAddress: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    hasPair: true,
    showNativePrice: true,
    isStake: true
  },
  {
    address: '0xC078285F16665B3F4bCe74AbDCF0f4C877de3E9f',
    symbol: 'BBOND',
    decimals: 18,
    tokenImage: tokenImages.bbond,
    chainId: 250,
    hasTax: true,
    visible: true,
    sellTax: 0.001,
    buyTax: 0,
    hasPair: false,
    showNativePrice: true,
    isStake: true
  },
  {
    address: '0x1539C63037D95f84A5981F96e43850d1451b6216',
    symbol: 'OBOL',
    decimals: 18,
    tokenImage: tokenImages.obol,
    chainId: 250,
    hasTax: true,
    visible: true,
    pairedTokenAddress: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    hasPair: true,
    showNativePrice: false,
    isStake: true
  },
  {
    address: '0x141FaA507855E56396EAdBD25EC82656755CD61e',
    symbol: 'SMELT',
    decimals: 18,
    tokenImage: tokenImages.smelt,
    chainId: 250,
    hasTax: true,
    visible: true,
    pairedTokenAddress: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    hasPair: true,
    showNativePrice: false,
    isStake: false
  },
  {
    address: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    symbol: 'USDC',
    decimals: 6,
    tokenImage: tokenImages.usdc,
    chainId: 250,
    hasTax: false,
    visible: false,
    showNativePrice: false,
    isStake: false
  },
  {
    address: '0x1c1cdf8928824dac36d84b3486d598b9799ba6c0',
    symbol: 'aBASED',
    decimals: 18,
    tokenImage: tokenImages.based,
    chainId: 43114,
    hasTax: false,
    cgcId: 'abased',
    visible: true,
    buyTax: 0,
    sellTax: 0.001,
    showNativePrice: false,
    dextoolsPair: "0xC0203Ab741B085d8ed677D4BF6a5fbb47913Fd46",
    isStake: false,
    nativeTokenAddress: "0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae",
    buyUrl: "https://traderjoexyz.com/avalanche/trade",
    marketCapByCirculating: true
  },
  {
    address: '0xd44779F0E18729cB6AFB7548cF10EF448a602668',
    symbol: 'pBASED',
    decimals: 18,
    tokenImage: tokenImages.based,
    chainId: 137,
    hasTax: true,
    cgcId: 'pBASED',
    visible: true,
    buyTax: 0,
    sellTax: 0.001,
    showNativePrice: false,
    dextoolsPair: "0xbb811d2171d176070dab7b5964e01c3be74a993a",
    nativeTokenAddress: "0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae",
    buyUrl: "https://quickswap.exchange/#/pools/v2?currency0=ETH&currency1=0xd44779F0E18729cB6AFB7548cF10EF448a602668",
    isStake: false,
    marketCapByCirculating: true
  },
];

const tokenInfos = [...fantomTokenInfos];

const treasuryAddress: {[chainId: number]: string } = {
  250: '0x25430E2ACb1255453135b2c64436C6b1Ff8153C5'
}

const teamTreasuryAddresses: { [chainId: number]: string } = {
  250: '0xc5bf5A56cEc938312A2528e2Bf5A5CBcaBEb33ea',
};

const boilerRoomAddresses: { [chainId: number]: string } = {
  250: '0x6bB173673cc128Aa8709B2Aa8108f6f9521AE0A0',
};

export { tokenInfos, teamTreasuryAddresses, boilerRoomAddresses, treasuryAddress };
