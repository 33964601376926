import {accountTokens, staking, tokens as tokenImages } from 'config/constants/assets';
import { FarmCategory } from 'types/farm';

const supportedMarketCollections = [
  {
    address: '0xc5777AD2250D7b12a3f2383afB4464b9E49bE297',
    name: 'Smelt Reward Pool',
    chainId: 250,
    tokens: ['0x1539C63037D95f84A5981F96e43850d1451b6216'],
    img: accountTokens.godNft,
    collectionName: "Prometheus Origins",
    enabled: true
  },
  {
    address: '0x6d74573A73f9B52B42fc07888f243b62d53fd2e0',
    name: 'Smelt Reward Pool Test',
    chainId: 250,
    tokens: ['0x1539C63037D95f84A5981F96e43850d1451b6216'],
    img: staking.travelLogo,
    collectionName: "Prometheus Traveler",
    enabled: true
  },
  // {
  //   address: '0x8901B1461116dDD5E4164Ea0A728Eb710b824fEE',
  //   name: 'Smelt Reward Pool Test',
  //   chainId: 250,
  //   tokens: ['0x1539C63037D95f84A5981F96e43850d1451b6216'],
  //   img: staking.travelLogo,
  //   collectionName: "Prometheus Traveler",
  //   enabled: false
  // }
];

const marketAddress: {[chainId: number]: string } = {
    250: '0xf013eA46A3f1d3f6829761D4103F1393986B09b5'
   // 250: '0xbbf1f8E200e46B32fB383c099Da7565700B6AaD0' // TEST
  }

const supportedCollections = [...supportedMarketCollections];
 
export { supportedCollections, marketAddress };