import { FC, useEffect, useRef, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Box, CircularProgress, Dialog, styled, Typography, useTheme } from '@mui/material';
import Carousel from 'better-react-carousel';

import { useAppSelector } from 'state/hooks';
import { ConnectWallet, ContainedButton } from 'components/common/Button';
import { useNftFarmMasterChef } from 'hooks/useNftFarmMasterChef';
import { useNotification } from 'hooks/useNotification';
import { icons } from 'config/constants/assets';
import { getBalanceInEther } from 'utils/formatBalance';
import { BigNumber } from 'ethers';

const ModalDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-container > .MuiPaper-root': {
    borderRadius: '20px',
    maxWidth: '373px',
    width: '100%',
    background: '#2A3139',
    padding: '15px 25px 15px',
    border: '1px solid #CDC392',
    boxShadow:
      '0px 2.1574831008911133px 29.22142219543457px 0px rgba(0, 0, 0, 0.05), 0px 3.806384325027466px 39.8596076965332px 0px rgba(0, 0, 0, 0.07), 0px 5.703558921813965px 44.46048355102539px 0px rgba(0, 0, 0, 0.08), 0px 10.160801887512207px 47.2956428527832px 0px rgba(0, 0, 0, 0.09), 0px 25.993473052978516px 52.371063232421875px 0px rgba(0, 0, 0, 0.10), 0px 100px 77px 0px rgba(0, 0, 0, 0.13)',

    [theme.breakpoints.up('xs')]: {
      padding: '10px 15px 10px',
      margin: '24px',
    },
    [theme.breakpoints.up('md')]: {},
  },
}));

// modal header
const ModalHeader = styled(Box)(() => ({}));

const ModalTitle = styled(Typography)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '17px',
  lineHeight: 'normal',
  textAlign: 'center',
  marginBottom: '5px',
}));

const ModalSubTitle = styled(Typography)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '15px',
  lineHeight: 'normal',
  textAlign: 'center',
}));

// modal action
const ModalAction = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',
  marginTop: '5px',
}));

// modal body
const ModalBody = styled(Box)(() => ({
  marginTop: '23px',
  marginBottom: '21px',
}));

const NftItemContainer = styled(Box)(() => ({
  position: 'relative',
  borderRadius: '10px 10px',
}));

const NftImage = styled(Box)(() => ({
  position: 'relative',
  background: 'white',
  height: '110px',
  borderRadius: '10px 10px 0px 0px',
  overflow: 'hidden',
  paddingTop: '100%',

  img: {
    position: 'absolute',
    top: '0',
    borderRadius: '10px 10px 0px 0px',
    width: '100%',
  },
}));

const NftId = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#7C7C7C',
  borderRadius: '0px 0px 10px 10px',
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: 'normal',
  height: '24px',
}));

const NftStatus = styled(Box)(() => ({
  position: 'absolute',
  top: '11px',
  right: '8px',
  width: '28px',
  height: '28px',
  borderRadius: '50%',
  pointerEvents: 'none',
}));

const CarouselDot = styled('span')(({ isActive }: { isActive: boolean }) => ({
  display: 'inline-block',
  height: '14px',
  width: '14px',
  background: isActive ? '#96906F' : '#ffffff',
  borderRadius: '50%',
  border: '1px solid #000000',
}));

const CarouselArrow = styled('span')(() => ({
  position: 'absolute',
  width: '28px',
  height: '28px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  border: '1px solid white',
  cursor: 'pointer',
  transition: 'opacity 0.25s',

  '&:hover': {
    background: '#7C7C7C',
  },
}));

const CarouselArrowLeft = styled(CarouselArrow)(() => ({
  top: '45%',
  left: '-8px',
}));

const CarouselArrowRight = styled(CarouselArrow)(() => ({
  top: '45%',
  right: '-8px',
}));

const CarouselArrowIcon = styled('img')(() => ({}));

const ActionButton = styled(ContainedButton)(() => ({
  maxWidth: '102px',
  height: '32px',
}));

interface Props {
  info: any;
  modalType: string;
  onClose: () => void;
}

const NftFarmModal: FC<Props> = ({ info, modalType, onClose }) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [selectedNftIds, setSelectedNftIds] = useState<number[]>([]);
  const [allNfts, setAllNfts] = useState<any[]>([]);

  const { pendingTxHash } = useAppSelector((state) => state.modal);
  const pendingTxHashRef: { current: string | undefined } = useRef();
  pendingTxHashRef.current = pendingTxHash;

  const theme = useTheme();
  const { account } = useWeb3React();
  const { onApproveStakingToken, onApproveReceiptToken, onDeposit, onWithdraw } = useNftFarmMasterChef(info);
  const { onShowNotification } = useNotification();
  const { name, userInfo, isRecieptDisabled } = info;
  const receiptTokenAllowance = getBalanceInEther(userInfo?.userReceiptTokenAllowance || BigNumber.from(0));
  const isStakingTokenApproved = userInfo?.userStakingTokenApproved;
  const isActionDisabled =
    isLoading ||
    (modalType === 'DEPOSIT' && isStakingTokenApproved && selectedNftIds.length === 0) ||
    (modalType === 'WITHDRAW' && selectedNftIds.length === 0);

  const isReceiptTokenApproved = isRecieptDisabled ? true : receiptTokenAllowance >= selectedNftIds.length;

  useEffect(() => {
    if (modalType === 'DEPOSIT') {
      setAllNfts(userInfo?.userNfts || []);
    }
    if (modalType === 'WITHDRAW') {
      setAllNfts(userInfo?.stakedNfts || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  const handleClose = () => {
    onClose();
  };

  const reset = () => {
    setSelectedNftIds([]);
  };

  // aprpove or deposit
  const handleApproveOrDeposit = async () => {
    setLoading(true);
    if (!isStakingTokenApproved) {
      // 1. Approve staking token logic
      try {
        await onApproveStakingToken();
        reset();
        onShowNotification({
          title: 'Transaction Success',
          description: 'Approve Staking Token',
          hasView: true,
          txHash: pendingTxHashRef.current,
        });
      } catch (err) {
        onShowNotification({ title: 'Transaction Failed', description: 'Approve Staking Token' });
        console.log('Approve Staking Token Error: ', err);
      }
    } else {
      // 2. Deposit logic
      try {
        await onDeposit(selectedNftIds);
        reset();
        onShowNotification({
          title: 'Transaction Success',
          description: 'Deposit Staking Token',
          hasView: true,
          txHash: pendingTxHashRef.current,
        });
      } catch (err) {
        onShowNotification({ title: 'Transaction Failed', description: 'Deposit Staking Token' });
        console.log('Deposit Error: ', err);
      }
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  // withdraw
  const handleWithdraw = async () => {
    setLoading(true);

    if (!isReceiptTokenApproved) {
      // 1. Approve receipt token (only for smeltRewardPool)
      try {
        await onApproveReceiptToken();
        reset();

        onShowNotification({
          title: 'Transaction Success',
          description: 'Approve Receipt Token',
          hasView: true,
          txHash: pendingTxHashRef.current,
        });
      } catch (err) {
        onShowNotification({ title: 'Transaction Failed', description: 'Approve Receipt Token' });
        console.log('Approve Receipt Token Error: ', err);
      }
      setLoading(false);
    } else {
      try {
        await onWithdraw(selectedNftIds);
        reset();

        onShowNotification({
          title: 'Transaction Success',
          description: 'Withdraw Staking Token',
          hasView: true,
          txHash: pendingTxHashRef.current,
        });
      } catch (err) {
        onShowNotification({ title: 'Transaction Failed', description: 'Withdraw Staking Token' });
        console.log('Withdraw Error: ', err);
      }
      setLoading(false);
    }
  };

  const onConfirm = () => {
    if (modalType === 'DEPOSIT') {
      handleApproveOrDeposit();
    }
    if (modalType === 'WITHDRAW') {
      handleWithdraw();
    }
  };

  const onSelectAll = () => {
    if (selectedNftIds.length !== allNfts.length) {
      setSelectedNftIds([...allNfts.map((row: any) => row.tokenId)]);
    } else {
      setSelectedNftIds([]);
    }
  };

  const onSelectNft = (item: any) => {
    const nftId = item;
    if (selectedNftIds.includes(nftId)) {
      setSelectedNftIds(selectedNftIds.filter((row) => row !== nftId));
    } else {
      setSelectedNftIds([...selectedNftIds, nftId]);
    }
  };

  const getButtonText = () => {
    if (modalType === 'WITHDRAW') {
      if (isReceiptTokenApproved) {
        return 'Withdraw';
      }
      return userInfo?.userReceiptTokenAllowance ? 'Approve' : 'Approve';
    }
    if (modalType === 'DEPOSIT') {
      if (isStakingTokenApproved === undefined) return '';
      return isStakingTokenApproved ? 'Deposit' : 'Approve';
    }
    return 'Deposit';
  };

  // deposit modal content
  return (
    <ModalDialog maxWidth="xs" onClose={handleClose} open theme={theme}>
      {/* header */}
      <ModalHeader>
        <ModalTitle>{`${modalType === 'DEPOSIT' ? 'Deposit NFT' : 'Withdraw NFT'} `}</ModalTitle>
        <ModalSubTitle>{`${name} collection`}</ModalSubTitle>
        {/* action */}
        <ModalAction sx={{ gap: { xs: '5px', md: '10px' } }}>
          <ModalSubTitle>{`Selected ${selectedNftIds.length}/${allNfts.length}`}</ModalSubTitle>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: '5px', md: '20px' } }}>
            <Box>
              <ActionButton
                disabled={isLoading || allNfts.length === 0}
                onClick={onSelectAll}
                sx={{ maxWidth: '102px', padding: '8px 12px' }}
              >
                {`${selectedNftIds.length === allNfts.length ? 'Deselect All' : 'Select All'}`}
              </ActionButton>
            </Box>
            <Box>
              {account ? (
                <ActionButton
                  disabled={isActionDisabled}
                  onClick={onConfirm}
                  sx={{ maxWidth: '102px', minWidth: '70px', padding: '8px 12px' }}
                >
                  {isLoading ? <CircularProgress size={20} sx={{ color: 'black' }} /> : getButtonText()}
                </ActionButton>
              ) : (
                <ConnectWallet width={100} />
              )}
            </Box>
          </Box>
        </ModalAction>
      </ModalHeader>

      {/* body */}
      <ModalBody>
        {allNfts.length > 0 && (
          <Carousel
            arrowLeft={() => (
              <CarouselArrowLeft>
                <CarouselArrowIcon alt="arrow icon" src={icons.chevronDown} sx={{ transform: 'rotate(90deg)' }} />
              </CarouselArrowLeft>
            )}
            arrowRight={() => (
              <CarouselArrowRight>
                <CarouselArrowIcon alt="arrow icon" src={icons.chevronDown} sx={{ transform: 'rotate(-90deg)' }} />
              </CarouselArrowRight>
            )}
            cols={2}
            containerStyle={{ padding: '15px 15px' }}
            dot={(props: { isActive: boolean }) => {
              const { isActive } = props;
              return <CarouselDot isActive={isActive} />;
            }}
            gap={10}
            loop
            rows={allNfts.length < 3 ? 1 : 2}
            showDots
          >
            {allNfts.map((row: any) => (
              <Carousel.Item key={row}>
                <NftItemContainer>
                  <NftImage
                    onClick={() => {
                      if (isLoading) return;
                      onSelectNft(row.tokenId);
                    }}
                  >
                    <img alt="nft pic" src={row.image} width="100%" />
                  </NftImage>
                  <NftId>{`ID: ${row.tokenId} `}</NftId>
                  <NftStatus sx={{ background: selectedNftIds.includes(row.tokenId) ? '#96906F' : 'white' }} />
                </NftItemContainer>
              </Carousel.Item>
            ))}
          </Carousel>
        )}
      </ModalBody>
    </ModalDialog>
  );
};

export default NftFarmModal;
