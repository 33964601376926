import { FC, useEffect, useRef, useState } from 'react';
import { Box, Link, styled, Typography } from '@mui/material';
import { icons, images } from 'config/constants/assets';
import TvlCard from 'components/TvlCard';
import NftCard from 'components/Nft/NftCard/NftCard';
import { NftCardCategory } from 'types/nft';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { fetchNftInfoAsync } from 'state/actions';
import { useWeb3React } from '@web3-react/core';

const Container = styled(Box)(({ theme }) => ({
    position: 'relative',
    width: '100%',
    minHeight: 'calc(100vh - 194px)',
    padding: '0px 24px',
    paddingTop: '19px',
  
    [theme.breakpoints.up('xs')]: {
      minHeight: 'calc(100vh - 284px)',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 'calc(100vh - 194px)',
    },
  }));

  const Banner = styled(Box)(({ theme }) => ({
    backgroundImage: `url(${images.overviewBanner})`,
    backgroundSize: 'cover',
    width: '100%',
    height: '255px',
    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
  
    [theme.breakpoints.up('xs')]: {
      height: '225px',
    },
    [theme.breakpoints.up('md')]: {
      height: '225px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '225px',
    },
  }));
  const BannerHero = styled(Box)(({ theme }) => ({
    backgroundImage: `url(${images.overviewBannerHero})`,
    backgroundSize: 'cover',
    width: '185px',
    height: '255px',
    maxHeight: '255px',
    position: 'absolute',
    top: '0px',
    right: '15%',
  
    [theme.breakpoints.up('xs')]: {
      height: '200px',
      right: '10%'
    },
    [theme.breakpoints.up('md')]: {
      height: '225px',
      right: '10%',
    },
    [theme.breakpoints.up('lg')]: {
      height: '225px',
      right: '8%',
    },
    [theme.breakpoints.up('xl')]: {
      height: '225px',
      right: '25%',
    },
    "@media (min-width:1920px) and (max-width:2500px)": {    
      height: '225px',
      right: '18%',
    },
  }));
  
  const Content = styled(Box)(() => ({
    position: 'relative',
    maxWidth: '1000px',
    margin: 'auto',
  }));
  
  // overview section
  const OverviewSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    gap: '20px',
    maxWidth: '100%',
    overflowX: 'auto',
  
    [theme.breakpoints.up('xs')]: {
      flexDirection: 'column',
      gap: '10px',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: '20px',
    },
  }));
  
  // news box
  const NewsBox = styled(Box)(({ theme }) => ({
    width: '100%',
    background: 'rgba(42, 49, 57, 0.4)',
    boxShadow:
        '0px 100px 77px rgba(0, 0, 0, 0.0), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0)',
    borderRadius: '20px',
    padding: '20px 10px',
  
    [theme.breakpoints.up('xs')]: {
      padding: '0px',
      boxShadow: 'none',
    },
    [theme.breakpoints.up('md')]: {
      padding: '20px 10px',
      boxShadow:
        '0px 100px 77px rgba(0, 0, 0, 0.0), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0)',
    },
  }));
  
  const NewsContent = styled(Box)(({ theme }) => ({
    position: 'relative',
    background: '#CCC292',
    borderRadius: '20px',
    padding: '15px',
    height: '160px',
    overflow: 'hidden',
  }));
  
  const NewsTitle = styled(Typography)(({ theme }) => ({
    position: 'relative',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    color: 'rgba(39, 40, 42, 0.8)',
    fontSize: '16px',
    lineHeight: '18px',
    textAlign: 'center',
    zIndex: '2',
  }));
  
  const NewsSubTitle = styled(Typography)(({ theme }) => ({
    position: 'relative',
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '400',
    color: 'rgba(39, 40, 42, 0.6)',
    fontSize: '11px',
    lineHeight: 'normal',
    zIndex: '2',
  }));
  
  const NewsImg = styled('img')(({ theme }) => ({
    marginTop: '18px',
  
    [theme.breakpoints.up('xs')]: {
      width: 'calc(100% - 0px)',
      height: '80px',
      marginTop: '28px',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '18px',
    },
  }));
  
  const StampImg = styled('img')(({ theme }) => ({
    position: 'absolute',
    bottom: '3px',
    left: '16px',
    width: '210px',
  
    [theme.breakpoints.up('xs')]: {},
    [theme.breakpoints.up('md')]: {},
  }));
  
interface Props {
  data?: any
}

const NftTokenPage: FC<Props> = ({ data}) => {
  const { selectedChainId } = useAppSelector((state) => state.chain);
  const { currentNft } = useAppSelector((state) => state.market);
  const { account } = useWeb3React();
  const [loaded, setLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const ref = useRef<HTMLImageElement>(null)
  const dispatch = useAppDispatch();

  const imageLoaded = () => {
    setIsLoading(false);
  }

  useEffect(() => {
    if( !selectedChainId )
      return
    const a = document.createElement('a');
    const url = new URL(window.location.href);
    const res = url.pathname.split('/');
    if( res.length > 3 ){
      dispatch(fetchNftInfoAsync(res[2], Number(res[3]), selectedChainId));

      // Open add liquidity
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href, selectedChainId]);

  return (
    <Container>
    <Banner />
    <BannerHero/>
    <Content>
        <OverviewSection>
            <TvlCard />
            <NewsBox>
            <NewsContent>
                <StampImg alt="stamp bg" src={icons.stamp} />
                <Box sx={{ margin: 'auto', width: 'fit-content' }}>
                <NewsTitle>LATEST NEWS BY BASED LABS</NewsTitle>
                <NewsSubTitle>BASED</NewsSubTitle>
                </Box>
                <NewsImg alt="news" src={images.defaultNews} />
            </NewsContent>
            </NewsBox>
        </OverviewSection>
        <Box display='flex' width='100%' sx={{border: '0px solid red'}}>
            <NftCard data={currentNft} category={NftCardCategory.EXTERNAL} onBack={() => {}}/>
        </Box>
    </Content>
  </Container>
  );
};

export default NftTokenPage;