import { createSlice } from '@reduxjs/toolkit';

import { FarmInfo } from 'types/farm';
import { fetchGlobalFarmData } from './fetchGlobalNftFarms';
import { fetchFarmUserData, fetchUserFarmDataSingle, fetchUserTokenDataSingle } from './fetchUserNftFarms';

interface FarmState {
  data: FarmInfo[];
  loaded: boolean;
  isStakedNftsLoaded: boolean;
  isUserNftsLoaded: boolean;
}

const initialState: FarmState = {
  data: [],
  loaded: false,
  isStakedNftsLoaded:false,
  isUserNftsLoaded: false
};

export const farmSlice = createSlice({
  name: 'nftFarms',
  initialState,
  reducers: {
    reset: (state) => {
      state.data = [];
      state.loaded = false;
      state.isStakedNftsLoaded = false;
      state.isUserNftsLoaded = false;
    },

    resetStates: (state) => {
      state.isStakedNftsLoaded = false;
      state.isUserNftsLoaded = false;
    },

    setFarmGlobalData: (state, action) => {
      // TODO Fix 
      if( action.payload.data.length === 0 )
        return;
      state.data = action.payload.data.map((row: any, i: number) => ({
        ...row,
        userInfo: state.data[i]?.userInfo || {},
      }));
      state.loaded = true;
    },

    setFarmUserData: (state, action) => {
      const { data } = action.payload;

      // update farms
      state.data = state.data.map((row: FarmInfo, index: number) => {
        try{
          if (row.name === data[index].name) {
            return {
              ...row,
              userInfo: {
                ...row.userInfo,
                ...data[index],
              },
            };
          }
        }
        catch(err)
        {
          // 
        }
        return row;

      });
      state.isUserNftsLoaded = true;
      state.isStakedNftsLoaded = true;
    },

    setFarmUserTokenData: (state, action) => {
      const { data } = action.payload;

      state.data = state.data.map((row: FarmInfo) => {
        if (row.name === data.name) {
          return {
            ...row,
            userInfo: {
              ...row.userInfo,
              userStakingTokenApproved: data.userStakingTokenApproved,
              userStakingTokenBalance: data.userStakingTokenBalance,
              userNfts: data.userNfts,
              userReceiptTokenAllowance: data.userReceiptTokenAllowance,
            },
          };
        }
        return row;
      });
    },

    setFarmUserDepositData: (state, action) => {
      const { data } = action.payload;

      state.data = state.data.map((row: FarmInfo) => {
        if (row.name === data.name) {
          return {
            ...row,
            userInfo: {
              ...row.userInfo,
              stakedBalance: data.stakedBalance,
              stakedNfts: data.stakedNfts,
              rewardTokenBalance: data.rewardTokenBalance,
            },
          };
        }
        return row;
      });
      state.isStakedNftsLoaded = true;
    },
  },
});

export const { reset, setFarmGlobalData, setFarmUserData, setFarmUserTokenData, setFarmUserDepositData, resetStates } =
  farmSlice.actions;

// fetch global farm info
export const fetchNftFarmGlobalDataAsync =
  (chainId: string) =>
  async (dispatch: any): Promise<void> => {
    const { data } = await fetchGlobalFarmData(chainId);

    dispatch(
      setFarmGlobalData({
        data,
      })
    );
  };

// fetch farm user token info
export const fetchNftFarmUserTokenDataAsync =
  (account: string, chainId: string, farm: any) =>
  async (dispatch: any): Promise<void> => {
    const data = await fetchUserTokenDataSingle(account, chainId, farm);

    dispatch(
      setFarmUserTokenData({
        data,
      })
    );
  };

// fetch farm user token info
export const fetchNftFarmUserDepositDataAsync =
  (account: string, chainId: string, farm: any) =>
  async (dispatch: any): Promise<void> => {
    const data = await fetchUserFarmDataSingle(account, chainId, farm);

    dispatch(
      setFarmUserDepositData({
        data,
      })
    );
  };

// fetch global farm user info
export const fetchNftFarmGlobalUserDataAsync =
  (account: string, chainId: string) =>
  async (dispatch: any): Promise<void> => {
    const data = await fetchFarmUserData(account, chainId);
    dispatch(
      setFarmUserData({
        data,
      })
    );
  };

export default farmSlice.reducer;
