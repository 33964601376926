import { multicallAddr, obolAddr, obolFtmPairAddr, wftmAddr } from 'config/constants/contract';
import { Address } from 'config/constants/types';
import { activeChainId, mainNetChainId } from 'utils/web3';
import { boilerRoomAddresses, teamTreasuryAddresses, treasuryAddress } from 'config/constants/tokens';

export const getAddress = (address: Address): string =>
  address[activeChainId] ? address[activeChainId] : address[mainNetChainId];

export const getWFTMAddress = (): string => getAddress(wftmAddr);

export const getOBOLAddress = (): string => getAddress(obolAddr);

export const getMulticallAddress = (): string => getAddress(multicallAddr);

export const getMulticallNativeAddress = (): string => '0xcA11bde05977b3631167028862bE2a173976CA11';

export const getObolFtmPairAddress = (): string => getAddress(obolFtmPairAddr);

export const getTeamTreasuryAddress = (chainId: number) => teamTreasuryAddresses[chainId];

export const getTreasuryAddress = (chainId: number) => treasuryAddress[chainId];

export const getBoilerRoomAddress = (chainId: number) => boilerRoomAddresses[chainId];
