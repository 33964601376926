import { useCallback, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';

import multicall from 'utils/multicall';
import Erc20Abi from 'config/abi/Erc20.json';
import NftAbi from 'config/abi/Nft.json';
import { getBalanceInEther } from 'utils/formatBalance';
import { tokens } from 'config/constants/userTokens';
import { useAppSelector } from 'state/hooks';

export const useAccountTokenBalance = () => {
  const [tokenInfos, setTokenInfos] = useState<any[]>([]);

  const { account, chainId } = useWeb3React();
  const { selectedChainId } = useAppSelector((state) => state.chain);
  const fetchBalance = useCallback(async () => {
    if (!account) return;
    if (!chainId) return;

    try{
        // fetch erc20 token infos
        const erc20Tokens = tokens.filter(
          (row: any) => Number(selectedChainId) === Number(row.chainId) && row.type === 'ERC20'
        );
        const calls1 = erc20Tokens.map((row) => ({
          address: row.address,
          name: 'balanceOf',
          params: [account],
        }));
        const balances1 = await multicall(Erc20Abi, calls1);
        const erc20TokenInfos = erc20Tokens.map((row, i) => ({
          ...row,
          balance: getBalanceInEther(balances1[i]),
        }));

        // fetch nft token infos
        const nfts2 = tokens.filter((row: any) => Number(selectedChainId) === Number(row.chainId) && row.type === 'NFT');
        const calls2 = nfts2.map((row) => ({
          address: row.address,
          name: 'balanceOf',
          params: [account],
        })); 
        const balances2 = await multicall(NftAbi, calls2);
        const nftInfos = nfts2.map((row, i) => ({
          ...row,
          balance: Number(balances2[i]),
        }));
        setTokenInfos([...erc20TokenInfos, ...nftInfos]);
    }
    catch(err){
        console.log(err)
    }
  }, [account, chainId, selectedChainId]);

  useEffect(() => {
    fetchBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId]);

  useEffect(() => {
    fetchBalance();

    setTimeout(() => {
      fetchBalance();
    }, 30000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return tokenInfos;
};
