export enum UpgradeCategory {
    ORIGINS,
    TRAVELER,
    REALM,
}

export interface UgradeInfo {
  contract: string;
  token: string;
  nftAddress: string;
  nftId: number;
  isUpgrade: boolean;
}

export interface UpgradeEstimateInfo {
  price: number;
  token: string;
}