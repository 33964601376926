const twistedNodesFantom = [
  {
    address: '0xAEbfF260074782a3DfD8981352b44767A05fa2eD', // short node
    type: 'short',
    token: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    chainId: 250,
  },

  {
    address: '0x525ca3877a78c6AE12292D0a55765775e3943379', // medium node
    type: 'medium',
    token: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    chainId: 250,
  },

  {
    address: '0x62A2Ff4BcCC5dD5316C358cDF079EC5e5c0851fe', // long node
    type: 'long',
    token: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    chainId: 250,
  },
];

const twistedNodeInfos = [...twistedNodesFantom];

export { twistedNodeInfos };
