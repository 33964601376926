import { getBalanceInEther } from 'utils/formatBalance';
import { getLiquidity } from 'utils/liquidity';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';
import LpPairAbi from 'config/abi/LpPair.json';
import ERC20 from 'config/abi/Erc20.json';

import multicall from 'utils/multicall';

// fetch reward pool farms (smelt reward pools and dead pools)
export const fetchPoolFarms = async (chainId: string): Promise<any> => {
  try {
    const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
    const liquidity = getLiquidity(selectedChainid);
    // get farm onchain info
    const farmsOnchainData = await Promise.all(
        liquidity.map(async (row: any) => {
        // LP pool
        const calls = [
          // Balance of token in the LP contract
          {
            address: row.stakingToken0,
            name: 'balanceOf',
            params: [row.stakingToken],
          },
          // Balance of quote token on LP contract
          {
            address: row.stakingToken1,
            name: 'balanceOf',
            params: [row.stakingToken],
          },
          // Total supply of LP tokens
          {
            address: row.stakingToken,
            name: 'totalSupply',
          },
          // Token0 decimals
          {
            address: row.stakingToken0,
            name: 'symbol',
          },
          // Token1 decimals
          {
            address: row.stakingToken1,
            name: 'symbol',
          },
        ];

        const [
          token0BalanceLP,
          token1BalanceLP,
          stakingTokenTotalSupply,
          token0Symbol,
          token1Symbol,
        ] = await multicall(LpPairAbi, calls);

        const tokenSupply = getBalanceInEther(token0BalanceLP);
        const tokenSupply1 = getBalanceInEther(token1BalanceLP);
        const totalSupply = getBalanceInEther(stakingTokenTotalSupply)
        const tokenInLP = tokenSupply/ Number(totalSupply);
        const {name, stakingToken0, tokenImage, symbol, stakingToken1} = row;

        const tokenAmount0 = tokenSupply / totalSupply;
        const tokenAmount1 = tokenSupply1 / totalSupply;
        return {
          ...row,
          name,
          totalSupply,
          tokenAmount0,
          tokenAmount1,
          tokenInLP,
          token0Address: stakingToken0,
          tokenImage,
          symbol,
          token0Symbol,
          token1Symbol,
          token1Address: stakingToken1
        };
      })
    );

    return farmsOnchainData;
  } catch (error: any) {
    console.log(error)
    return [];
  }
};

export const fetchGlobalLiquidityData = async (chainId: string): Promise<any> => {
  const smeltRewardPoolFarmsData = await fetchPoolFarms(chainId);
  return {
    data: [...smeltRewardPoolFarmsData],
  };
};
