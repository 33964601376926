import { getBalanceInEther } from 'utils/formatBalance';
import { getStakingPoolAbi, getStakingPools, getTreasuryAbi } from 'utils/stakings';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';

import multicall from 'utils/multicall';
import InfinitePrinterAbi from 'config/abi/InfinitePrinter.json';
import { getTreasuryAddress } from 'utils/addressHelpers';

export const fetchStakingPools = async (chainId: string): Promise<any> => {
  try {
    const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
    const pools = getStakingPools(selectedChainid);
    const treasuryAddr = getTreasuryAddress(selectedChainid);

    // get pool onchain info
    const poolsOnchainData = await Promise.all(
      pools.map(async (row: any) => {
        const poolAddress = row.address;
        const poolType = row.type;

        if (poolType === 'Acropolis') {
          const calls = [
            // current epoch
            {
              address: poolAddress,
              name: 'epoch',
              params: [],
            },
            // next epoch start timestamp
            {
              address: poolAddress,
              name: 'nextEpochPoint',
              params: [],
            },
            // reward emission rate
            {
              address: poolAddress,
              name: poolType === 'Acropolis' ? 'rewardPerShare' : 'rewardPerSmelt',
              params: [],
            },
            // withdraw lockup epoches
            {
              address: poolAddress,
              name: 'withdrawLockupEpochs',
              params: [],
            },
            // total staked
            {
              address: poolAddress,
              name: 'totalSupply',
              params: [],
            },
            // latestSnapshotIndex
            {
              address: poolAddress,
              name: 'latestSnapshotIndex',
              params: [],
            },
            // rewardLockUpEpochs
            {
              address: poolAddress,
              name: "rewardLockupEpochs",
              params: [],
            }
          ];

          const [
            currentEpoch,
            nextEpochStartTimestamp,
            rewardEmissionRate,
            withdrawLockupEpochs,
            totalSupply,
            latestSnapshotIndexRaw,
            rewardLockupEpochs
          ] = await multicall(getStakingPoolAbi(poolType), calls);
          const latestSnapshotIndex = latestSnapshotIndexRaw[0].toNumber();

          const [lastHistroy] = await multicall(getStakingPoolAbi(poolType), [
            {
              address: poolAddress,
              name: 'acropolisHistory',
              params: [latestSnapshotIndex],
            },
          ]);
          const rewardReceived = getBalanceInEther(lastHistroy.rewardReceived);
          const tvl = getBalanceInEther(totalSupply[0]);
          const rewardPerShare = rewardReceived / tvl;

          const [period] = await multicall(getTreasuryAbi(), [
            {
              address: treasuryAddr,
              name: 'PERIOD',
              params: [],
            }
          ]);

          return {
            ...row,
            currentEpoch: currentEpoch[0].toNumber(),
            nextEpochStartTimestamp: nextEpochStartTimestamp[0].toNumber(),
            withdrawLockupEpochs: withdrawLockupEpochs[0].toNumber(),
            tvl,
            rewardEmissionRate: rewardReceived,
            rewardPerShare,
            period,
            rewardLockupEpochs
          };
        }

        const calls = [
          // current epoch
          {
            address: poolAddress,
            name: 'epoch',
            params: [],
          },
          // next epoch start timestamp
          {
            address: poolAddress,
            name: 'nextEpochPoint',
            params: [],
          },
          // reward emission rate
          {
            address: poolAddress,
            name: poolType === 'Acropolis' ? 'rewardPerShare' : 'rewardPerSmelt',
            params: [],
          },
          // withdraw lockup epoches
          {
            address: poolAddress,
            name: 'withdrawLockupEpochs',
            params: [],
          },
          // total staked
          {
            address: poolAddress,
            name: 'totalSupply',
            params: [],
          },
          // latestSnapshotIndex
          {
            address: poolAddress,
            name: 'latestSnapshotIndex',
            params: [],
          },
          // infinitePrinter
          {
            address: poolAddress,
            name: 'operator',
            params: [],
          },
          // rewardLockUpEpochs
          {
            address: poolAddress,
            name: "rewardLockupEpochs",
            params: [],
          }
        ];

        const [
          currentEpoch,
          nextEpochStartTimestamp,
          rewardEmissionRate,
          withdrawLockupEpochs,
          totalSupply,
          latestSnapshotIndexRaw,
          infinitePrinter,
          rewardLockupEpochs
        ] = await multicall(getStakingPoolAbi(poolType), calls);
        const latestSnapshotIndex = latestSnapshotIndexRaw[0].toNumber();

        const [lastHistroy] = await multicall(getStakingPoolAbi(poolType), [
          {
            address: poolAddress,
            name: 'boardroomHistory',
            params: [latestSnapshotIndex],
          },
        ]);
        const rewardReceived = getBalanceInEther(lastHistroy.rewardReceived);
        const tvl = getBalanceInEther(totalSupply[0]);
        const rewardPerShare = rewardReceived / tvl;

        // infinite printer
        const [infinitePrinterAprRaw, rewardTokenCirtulationSupplyRaw, period] = await multicall(InfinitePrinterAbi, [
          {
            address: infinitePrinter[0],
            name: 'maxSupplyExpansionPercent',
            params: [],
          },
          {
            address: infinitePrinter[0],
            name: 'getOBolCirculatingSupply',
            params: [],
          },
          {
            address: infinitePrinter[0],
            name: 'PERIOD',
            params: [],
          }
        ]);
        return {
          ...row,
          currentEpoch: currentEpoch[0].toNumber(),
          nextEpochStartTimestamp: nextEpochStartTimestamp[0].toNumber(),
          withdrawLockupEpochs: withdrawLockupEpochs[0].toNumber(),
          tvl: getBalanceInEther(totalSupply[0]),
          rewardEmissionRate: rewardReceived,
          rewardPerShare,
          infinitePrinterApr: infinitePrinterAprRaw[0].toNumber() / 10000,
          rewardTokenCirculatingSupply: getBalanceInEther(rewardTokenCirtulationSupplyRaw[0]),
          period,
          rewardLockupEpochs
        };
      })
    );

    return poolsOnchainData;
  } catch (error: any) {
    return [];
  }
};

export const fetchGlobalStakingData = async (chainId: string): Promise<any> => {
  const stakingPools = await fetchStakingPools(chainId);
  return {
    data: [...stakingPools],
  };
};
