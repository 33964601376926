import { ChainInfo } from 'types/chain';
import { chains } from 'config/constants/assets';

export const DEFAULT_ACTIVE_CHAIN_ID: number = Number(process.env.REACT_APP_CHAIN_ID) || 250;

export const SUPPORTED_CHAINS: ChainInfo[] = [
  { name: 'Fantom', chainId: 250, nativeCurrency: 'FTM', label: 'fantom', isMainnet: true, logo: chains.fantom },
  { name: 'Polygon', chainId: 137, nativeCurrency: 'MATIC', label: 'polygon', isMainnet: true, logo: chains.polygon2 },
  { name: 'Avalanche', chainId: 43114, nativeCurrency: 'AVAX', label: 'avax', isMainnet: true, logo: chains.avalanche },
  { name: 'Base', chainId: 8453, nativeCurrency: 'ETH', label: 'base', isMainnet: true, logo: chains.base },
  { name: 'zkSync', chainId: 324, nativeCurrency: 'ETH', label: 'zkSync', isMainnet: true, logo: chains.zkSync }, 
];

export const CHAIN_INFO: { [key: number]: any } = {
  250: {
    explorer: 'https://ftmscan.com/',
    explorerName: 'FTMScan',
    label: 'Fantom',
    logoUrl: chains.fantom,
    nativeCurrency: { name: 'Fantom', symbol: 'FTM', decimals: 18 },
  },
  43114: {
    explorer: 'https://snowtrace.io/',
    explorerName: 'AvaxScan',
    label: 'Avalanche',
    logoUrl: chains.avalanche,
    nativeCurrency: { name: 'Avax', symbol: 'AVAX', decimals: 18 },
  },
  137: {
    explorer: 'https://polygonscan.com/',
    explorerName: 'PolygonScan',
    label: 'Polygon',
    logoUrl: chains.polygon2,
    nativeCurrency: { name: 'Matic', symbol: 'MATIC', decimals: 18 },
  },
  8453: {
    explorer: 'https://basescan.org',
    explorerName: 'BaseScan',
    label: 'Base',
    logoUrl: chains.base,
    nativeCurrency: { name: 'Base', symbol: 'ETH', decimals: 18 },
  },
  324: {
    explorer: 'https://zkscan.io/',
    explorerName: 'zkscan',
    label: 'zkSync',
    logoUrl: chains.zkSync,
    nativeCurrency: { name: 'zkSync', symbol: 'ETH', decimals: 18 },
  }
};
