import { useMemo } from 'react';
import { AbiItem } from 'web3-utils';
import { useWeb3React } from '@web3-react/core';
import { constants, providers } from 'ethers';

import WFTMAbi from 'config/abi/WFTM.json';
import MulticallAbi from 'config/abi/Multicall.json';
import SmeltRewardPoolAbi from 'config/abi/SmeltRewardPool.json';
import DeadPoolAbi from 'config/abi/DeadPool.json';
import LpPairAbi from 'config/abi/LpPair.json';
import NftAbi from 'config/abi/Nft.json';
import UpgradeAbi from 'config/abi/Upgrade.json';
import { getContract } from 'utils/address';
import { getStakingPoolAbi, getTreasuryAbi } from 'utils/stakings';
import Erc20 from 'config/abi/Erc20.json';

import { getMulticallAddress, getWFTMAddress } from 'utils/addressHelpers';

export const useContract = (abi: AbiItem, address: string, withSignerIfPossible = true) => {
  const { account, connector } = useWeb3React();

  return useMemo(() => {
    if (!address || !abi || !connector.provider) return null;

    const provider = new providers.Web3Provider(connector.provider!);
    if (address === constants.AddressZero) {
      return null;
    }

    try {
      return getContract(address, abi, provider, withSignerIfPossible && account ? account : undefined);
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [address, abi, connector, withSignerIfPossible, account]);
};
// wftm token contract
export const useMulticallContract = () => useContract(WFTMAbi as unknown as AbiItem, getWFTMAddress());

// WFTM contract
export const useWFTMContract = () => useContract(MulticallAbi as unknown as AbiItem, getMulticallAddress());

// farming masterchef contract
export const useMasterChefContract = (address: string, type: string) => {
  const abi: any = type === 'smeltRewardPool' ? SmeltRewardPoolAbi : DeadPoolAbi;
  return useContract(abi, address);
};

// staking pool contract
export const useStakingPoolContract = (address: string, type: string) =>
  useContract(getStakingPoolAbi(type) as unknown as AbiItem, address);

// lp token contract
export const useLpContract = (address: string) => useContract(LpPairAbi as unknown as AbiItem, address);

// nft token contract
export const useNftContract = (address: string) => useContract(NftAbi as unknown as AbiItem, address);

// treasury contract
export const useTreasury = (address: string) => useContract(getTreasuryAbi() as unknown as AbiItem, address);

export const useToken = (address: string) => useContract( Erc20 as unknown as AbiItem, address);

export const useUpgradeContract = (address: string) => useContract(UpgradeAbi as unknown as AbiItem, address);

export default useContract;
