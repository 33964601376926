import { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Grid, IconButton, Link, styled, Typography } from '@mui/material';
import { useAppSelector } from 'state/hooks';
import Carousel from 'better-react-carousel';
import InfiniteScroll from 'react-infinite-scroll-component';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useWeb3React } from '@web3-react/core';

import { chains, icons } from 'config/constants/assets';
import EmptyLoad from 'components/EmtyLoad/EmptyLoad';
import { NftCardCategory, NftDataInfo, NftMarketInfo } from 'types/nft';
import SellBox from './components/SellBox';
import StakeBox from './components/StakeBox';
import BuyBox from './components/BuyBox';
import styles from "../Buy.module.css";
import UnstakeBox from './components/UnstakeBox';
import TransferBox from './components/TransferBox';
import TraitsBox from './components/TraitsBox';
import DetailsBox from './components/DetailsBox';
import TradeStats from './components/TradeStats';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '10px',
  width: '100%',
  padding: '12px 5px 30px',
  position: 'relative',
  borderRadius: '20px',
  border: '1px solid #5E6980',
  background: 'rgba(42, 49, 57, 0.60)',
//   boxShadow:
//     '0px 2.15748px 29.22142px 0px rgba(0, 0, 0, 0.05), 0px 3.80638px 39.85961px 0px rgba(0, 0, 0, 0.07), 0px 5.70356px 44.46048px 0px rgba(0, 0, 0, 0.08), 0px 10.1608px 47.29564px 0px rgba(0, 0, 0, 0.09), 0px 25.99347px 52.37106px 0px rgba(0, 0, 0, 0.10), 0px 100px 77px 0px rgba(0, 0, 0, 0.13)',

  [theme.breakpoints.up('xs')]: {
    minHeight: '400px',
  },
  [theme.breakpoints.up('md')]: {
    minHeight: '550px',
  },
}));

const NftName = styled(Box)(() => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '600',
    color: 'white',
    fontSize: '25px',
    textAlign: 'left',
}));

const NftRank = styled(Box)(() => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    color: 'white',
    fontSize: '20px',
    textAlign: 'left',
}));

const BasicText = styled(Box)(() => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    color: 'white',
    fontSize: '15px',
    textAlign: 'left',
}));

const TabText = styled(Box)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '300',
  color: 'white',
  fontSize: '15px',
  textAlign: 'center',
}));

const NftItemContainer = styled(Box)(() => ({
  position: 'relative',
  borderRadius: '10px 10px',
  maxWidth: '300px'
}));

const NftImage = styled(Box)(() => ({
  position: 'relative',
  height: '360px',
  borderRadius: '10px 10px 0px 0px',
  overflow: 'hidden',
  paddingTop: '100%',

  img: {
    position: 'absolute',
    top: '0',
    borderRadius: '10px 10px 0px 0px',
    width: '100%',
  },
}));

const ToggleIcon = styled('img')(() => ({ width: '20px', height: '20px'}));

const ContentBox = styled(Box)(({ theme }) => ({
    display: 'flex', 
    alignItems: 'center',
    // flexDirection: 'column',
    background: 'rgb(6,1,1,0.3)',
    borderRadius: '10px',
    [theme.breakpoints.up('xs')]: {
        minHeight: '70px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '50px',
      },
      // "&:hover": {
      //   backgroundColor: 'rgb(7, 177, 77, 0.42)'
      // }
    
}));

const ContentBoxButtons = styled(Box)(({ theme }) => ({
  display: 'flex', 
  alignItems: 'center',
  // flexDirection: 'column',
  background: 'rgb(6,1,1,0.3)',
  borderRadius: '10px',
  [theme.breakpoints.up('xs')]: {
      minHeight: '70px',
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '50px',
    },
    "&:hover": {
      backgroundColor: 'grey',
      color: 'white'
    }
  
}));

const ContentLeft = styled(Box)(({ theme }) => ({
    display: 'flex', 
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
        minHeight: '70px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '50px',
      },
}));

// toggle section
const ToggleSection = styled(Box)(() => ({
  position: 'absolute',
  left: '-17px',
  top: 'calc(50%px - 10px)',
  background: '#2A3139',
  boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  width: '45px',
  height: '45px',
  zIndex: 1
}));

const ContentRight = styled(Box)(({ theme }) => ({
    display: 'flex', 
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
        minHeight: '70px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '50px',
      },
}));

interface Props {
  data: NftDataInfo
  onBack: (isReload: boolean) => void
  category: NftCardCategory
}

const NftCard: FC<Props> = ({ data, onBack, category }) => {
  const [loaded, setLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const ref = useRef<HTMLImageElement>(null)
  const { nftData: sellList, nftsInfo: marketnftsInfo } = useAppSelector((state) => state.market);
  const [isCheckingForSell, setCheckingForSell] = useState(true)
  const [isNftForSell, setNftForSell] = useState(false)
  const [markedData, setMarketData] = useState<any>(undefined)
  const { account } = useWeb3React();
  const [menuType, setMenuType] = useState(1);
  const [cardType, setCardType] = useState(0);
  const [isMarketEnabled, setIsMarketEnabled] = useState(false)
  const isYourNft = account === data?.owner || data?.isStaked
  const { selectedChainId } = useAppSelector((state) => state.chain);
  const imageLoaded = () => {
    setIsLoading(false);
  }
  const menuSelect = (type: number) => {
    if( cardType === 1 && (type === 2 || type === 1) )
      setMenuType(type);
    if( (cardType === 3) && (type === 3) )
      setMenuType(type);
  }

  useEffect(() => {
    if( data ) {
      if( data.marketData ){
        setNftForSell(true);
        setMarketData(data.marketData);
        setCheckingForSell(false);
        return;
      }
    }
    if( sellList ){
      try{
        if( sellList.collections.length > 0 ){
          for(let i = 0; i < sellList.collections.length; i+=1 ){
            if( sellList.collections[i].address === data?.address ){
              if( sellList.collections[i].enabled )
                setIsMarketEnabled(true)
              for( let k = 0; k < sellList.collections[i].nfts.length; k+=1 ){
                const nft = sellList.collections[i].nfts[k];
                if( nft.nftId === data?.tokenId ){
                  setNftForSell(true);
                  setMarketData(nft);
                }
              } 
            } 
          }
        }
        setCheckingForSell(false);
      }
      catch(err){
        console.log(err)
      }
      // dispatch(fetchMarketNftsInfoAsync(sellList))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellList, data]);

  const onHide = () => {
    if( category === NftCardCategory.INTERNAL ){
      onBack(true);
    }
  };

  const getCardType = () => {
    if( !isNftForSell &&  !isCheckingForSell && !data.isStaked && isYourNft ){
      setMenuType(1)
      return 1;
    }
    if( markedData  && !isCheckingForSell  ){
      return 2;
    }
    if( data.isStaked && !isCheckingForSell && isYourNft && data.stakeContract ){
      setMenuType(3)
      return 3;
    }
    return 0;

  };

  useEffect(() => {
    if( data ) {
      if( !isNftForSell &&  !isCheckingForSell && !data.isStaked && isYourNft && isMarketEnabled ){
        setMenuType(1)
        setCardType(1)
        setLoaded(true)
        return
      }
      if( data.isStaked && !isCheckingForSell && isYourNft && data.stakeContract ){
        setMenuType(3)
        setCardType(3)
        setLoaded(true)
        return;
      }
      if( markedData  && !isCheckingForSell  ){
        setCardType(2)
        setLoaded(true)
        return
      }
      if( !isMarketEnabled) {
        setCardType(5)
      }
      if( !isYourNft ) {
        setCardType(4)
      }
      setLoaded(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellList, data, isNftForSell, isCheckingForSell, isYourNft]);

  const truncate = (str: string, n: number) => {
    return str?.length > n ? `${str.substr(0, n ) } ... ${str.substr(str.length - n, n) }`: str;
    };

    const getTvlChainLogo = () => {
      if (Number(selectedChainId) === 250) return chains.fantom1;
      if (Number(selectedChainId) === 137) return chains.polygon2;
      if (Number(selectedChainId) === 43114) return chains.avalanche;
      if (Number(selectedChainId) === 8453) return chains.base;
      if (Number(selectedChainId) === 324 ) return chains.zkSync;
      return chains.fantom1;
    };

  return (
    <Container>
      {category === NftCardCategory.INTERNAL &&
            <ToggleSection onClick={() => {onBack(false)}}>
            <ToggleIcon
              alt="toggle"
              src={icons.chevronDown}
              sx={{transform: 'rotate(90deg)'}}
            />
          </ToggleSection>
      }
        <Grid container spacing={1}>
            <Grid item xs={12} md={6} sx={{border: '0px solid red'}}>
                <ContentLeft>
                    <NftItemContainer key={data?.tokenId} className={styles.nftContainerCard}>
                        <NftImage display={isLoading ? 'block' : 'none'}>
                            <EmptyLoad  width="100%"  height='100%' />
                        </NftImage>
                        <NftImage display={isLoading ? 'none' : 'block'} sx={{height: "150px"}} onClick={() => {}}>
                            <img ref={ref} onLoad={imageLoaded}  alt="nft pic" src={data?.image}  width="100%" />
                            {data?.meta?.name === `Prometheus Emperor #${data?.tokenId}` &&
                            <>
                              <img ref={ref} onLoad={imageLoaded}  alt="nft pic" src={getTvlChainLogo()} style={{position: 'absolute', right: "15px", top: "15px", zIndex: 2, maxWidth: '60px', maxHeight: '60px'}} />
                            </>
                        }
                        </NftImage>

                    </NftItemContainer>
                    <Box display='flex' mt={1} ml={2} mr={2} flexDirection='column'>
                        {/* <Box display='flex' mt={1}  sx={{maxWidth: '100%', border: '0px solid red', paddingLeft: '10px', paddingRight: '10px'}} flexWrap='wrap' gap={1}>
                                { data?.meta?.attributes.map((row : any) =>
                                    <>
                                        <Box display='flex' flexDirection='row' justifyContent='space-between' sx={{borderRadius: '0px', borderBottom: '1px solid grey', padding: '5px', width: '100%', display: row.value !== 'none' ? 'flex' : 'none'}}>
                                            <Typography style={{fontSize: '13px', color: "lightgray", width: '50%'}} color='primary' variant='h6' >{row.trait_type}:</Typography>
                                            <Box display='flex' justifyContent='flex-end' sx={{paddingRight: '20px'}}>
                                                <Typography style={{fontSize: '13px', color: "lightgray", width: '50%'}} color='primary' variant='h6' >{row.value}</Typography>
                                            </Box>
                                        </Box>
                                    </>
                                ) }
                        </Box> */}
                    </Box>
                </ContentLeft>
            </Grid>
            <Grid mt={1} item xs={12} md={6}>
               <Grid container alignItems='center' sx={{width: '100%', border: '0px solid red'}}>
                <Grid item xs={12} md={8} alignItems='center' justifyContent='center'> 
                  <NftName>{data?.meta?.name}</NftName>
                </Grid>
                <Grid item xs={12} md={4} alignItems='center' justifyContent='center'> 
                  <NftRank>Rank: {data?.meta?.rank}</NftRank>
                </Grid>
               </Grid>
               <Grid mt={1} spacing={1} container sx={{border: '0px solid red'}}>
                <Grid item xs={12} md={6} alignItems='center' justifyContent='center'> 
                  <ContentBox flexDirection='row'>
                          <BasicText ml={1}>
                              Owner:
                          </BasicText>
                          <BasicText ml={1} sx={{fontWeight: '500'}}>
                              {data?.marketData ? truncate(data?.marketData.seller, 4) : truncate(data?.owner?.length > 0 && category === NftCardCategory.EXTERNAL ? data?.owner : data?.owner, 4)}
                          </BasicText>
                  </ContentBox>
                </Grid>
                <Grid item xs={12} md={6} alignItems='center' justifyContent='center'> 
                  <ContentBox flexDirection='row' >
                        <BasicText ml={1}>
                            Status:
                        </BasicText>
                        { isCheckingForSell ? <CircularProgress size={20} sx={{ color: 'white', marginLeft: '10px' }} /> : <></>}
                        <BasicText ml={1} sx={{fontWeight: '500', display: isCheckingForSell ? 'none' : 'flex'}}>
                            {data?.marketData ? 'Listed' : isNftForSell ? 'Listed' : data.isStaked ? 'Staked' : 'In Wallet'}
                        </BasicText>
                    </ContentBox>
                  </Grid>
               </Grid>
                  { (cardType === 1 || cardType === 3) && loaded  &&
                    <>
                      <Grid mt={0} container spacing={1}>
                        <Grid item xs={4}>
                          <ContentBoxButtons sx={{maxWidth: '100%', height: '35x', opacity: cardType === 3 ? '0.4' : '1.0'}} justifyContent='center' onClick={() => {menuSelect(1)}}>
                                <TabText textAlign='center' sx={{color: menuType === 1 ? '#065CFC' : 'white'}}>
                                    List
                                </TabText>
                          </ContentBoxButtons>
                        </Grid>
                        <Grid item xs={4}>
                          <ContentBoxButtons sx={{maxWidth: '100%', height: '35x', opacity: cardType === 3 ? '0.4' : '1.0'}} justifyContent='center' onClick={() => {menuSelect(2)}}>
                                <TabText textAlign='center' sx={{color: menuType === 2 ? '#065CFC' : 'white'}}>
                                    Transfer
                                </TabText>
                          </ContentBoxButtons>
                        </Grid>
                        <Grid item xs={4}>
                          <ContentBoxButtons sx={{maxWidth: '100%', height: '35x', opacity: cardType === 1 ? '0.4' : '1.0'}} justifyContent='center' onClick={() => {menuSelect(3)}}>
                            <TabText textAlign='center' sx={{color: menuType === 3 ? '#065CFC' : 'white'}}>
                                    Unstake
                              </TabText>
                          </ContentBoxButtons>
                        </Grid>
                      </Grid>
                    </>
                  }
                <Box>
                    { menuType === 1 && cardType === 1 && loaded  &&
                      <SellBox data={data} onHideCard={onHide}/>
                    }
                    { menuType === 2 && cardType === 1 &&
                      <TransferBox data={data} onHideCard={onHide}/>
                    }
                    { menuType === 3  && cardType === 3 &&
                      <UnstakeBox data={data} onHideCard={onHide}/>
                    }
                    { cardType === 5 &&
                        <TransferBox data={data} onHideCard={onHide}/>
                    }
                    { cardType === 2 &&
                      <>
                      <BuyBox data={data} marketData={markedData} onHideCard={onHide}/>
                      </>
                    }
                    <Box display='flex' justifyContent='center' sx={{width: '100%', marginTop: '10px'}}>
                      { isCheckingForSell ? <CircularProgress size={20} sx={{ color: 'white' }} /> : <></>}
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} sx={{border: '0px solid blue '}}>
                <Grid item xs={12} md={6}>
                  <Box>
                    <DetailsBox data={data} onHideCard={onHide}/>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box>
                    <TraitsBox data={data} onHideCard={onHide}/>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Box>
                    <TradeStats data={data} onHideCard={onHide}/>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
        </Grid>

    </Container>
  );
};

export default NftCard;
