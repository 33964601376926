import React from "react";
import styles from "./empty.module.css";

type Props = {
  width?: string;
  height?: string;
};
export default function EmptyLoad({ height, width }: Props) {
  return (
    <div
      style={{
        width,
        height,
        borderRadius: "inherit",
        position: 'absolute',
        top: '0',
      }}
      className={styles.skeleton}
    />
  );
}