import React from 'react';
import { Typography } from '@mui/material';

import "./datetime.css";
import DateTimeDisplay from "./DateTimeDisplay";
import useCountDown from "./useCountDown";

const ExpiredNotice = () => {
  return (
    <></>
    // <div className="expired-notice">
    //   <span>Genesis started!</span>
    // </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds, type }) => {
    
  return (
    <>
        <div className="show-counter" style={{marginBottom: '10px', marginTop: '10px', justifyContent: 'center', alignItems:'center', border: '0px solid red'}}>
          <div
            className="countdown-link"
            style={{fontSize: type === 1 ? '1.25rem' : '2.25rem'}}
          >
            <DateTimeDisplay value={days} type="" isDanger={days <= 3} />
            <p>:</p>
            <DateTimeDisplay value={hours < 10 ? `0${hours}` : hours} type="" isDanger={false} />
            <p>:</p>
            <DateTimeDisplay value={minutes < 10 ? `0${minutes}`: minutes} type="" isDanger={false} />
            <p>:</p>
            <DateTimeDisplay value={seconds < 10 ? `0${seconds}`: seconds} type="" isDanger={false} />
          </div>
        </div>
    </>
  );
};

const CountDownTimer = ({ targetDate, type = 0 }) => {
  const [days, hours, minutes, seconds] = useCountDown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    // return <ExpiredNotice />;
    return (
      <ShowCounter
        days={0}
        hours={0}
        minutes={0}
        seconds={0}
      />
    );
  } 
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
        type={type}
      />
    );
  
};

export default CountDownTimer;