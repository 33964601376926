import { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Grid, IconButton, Link, styled, Typography } from '@mui/material';
import { icons, tokens } from 'config/constants/assets';
import { TokenSelectInput } from 'components/common/TokenSelectCard/TokenSelectInput';
import DataSelect from 'components/common/Select/DataSelect';
import { ContainedButton } from 'components/common/Button';
import { useWeb3React } from '@web3-react/core';
import { useAppSelector } from 'state/hooks';
import { getBalanceInEther } from 'utils';
import { useNotification } from 'hooks/useNotification';
import { BigNumber } from 'ethers';
import { useNftFarmMasterChef } from 'hooks/useNftFarmMasterChef';
import { FarmInfo } from 'types/farm';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '12px 5px 12px',
  borderRadius: '10px',
  background: 'rgb(6,1,1,0.3)',
  position: 'relative'
//   boxShadow:
//     '0px 2.15748px 29.22142px 0px rgba(0, 0, 0, 0.05), 0px 3.80638px 39.85961px 0px rgba(0, 0, 0, 0.07), 0px 5.70356px 44.46048px 0px rgba(0, 0, 0, 0.08), 0px 10.1608px 47.29564px 0px rgba(0, 0, 0, 0.09), 0px 25.99347px 52.37106px 0px rgba(0, 0, 0, 0.10), 0px 100px 77px 0px rgba(0, 0, 0, 0.13)',

//   [theme.breakpoints.up('xs')]: {
//     minHeight: '400px',
//   },
//   [theme.breakpoints.up('md')]: {
//     minHeight: '550px',
//   },
}));

// nft list title
const SellTitle = styled(Box)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  color: '#CDC392',
  fontSize: '18px',
  textAlign: 'left',
}));

const BasicText = styled(Box)(() => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    color: 'white',
    fontSize: '15px',
    textAlign: 'left',
}));

const Header = styled(Box)(({ theme }) => ({
    display: 'flex', 
    // background: 'red',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('xs')]: {
        minHeight: '70px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '50px',
      },
}));

const ContentBox = styled(Box)(({ theme }) => ({
    display: 'flex', 
    alignItems: 'center',
    // flexDirection: 'column',
    background: 'rgb(6,1,1,0.3)',
    borderRadius: '10px',
    [theme.breakpoints.up('xs')]: {
        minHeight: '70px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '50px',
      },
}));

const ContentLeft = styled(Box)(({ theme }) => ({
    display: 'flex', 
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
        minHeight: '70px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '50px',
      },
}));

const ContentRight = styled(Box)(({ theme }) => ({
    display: 'flex', 
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
        minHeight: '70px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '50px',
      },
}));

// toggle section
const ToggleSection = styled(Box)(() => ({
    position: 'absolute',
    right: '-7px',
    top: 'calc(50%px - 10px)',
    background: '#2A3139',
    boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '28px',
    height: '28px',
  }));

const StyledTokenInput = styled('div')(({ theme }) => ({
    maxWidth: '100%',
    width: '100%',
    border: '1px solid grey',
    borderRadius: '10px',
    backgroundColor: 'transparent'
}));

const TokenImgSection = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  }));

  const ActionButton = styled(ContainedButton)(() => ({
    // maxWidth: '102px',
    height: '40px',
  }));
  
  const TokenImg = styled('img')(({ theme }) => ({
      height: '25px',
      width: '25px',
  }));

  const ToggleIcon = styled('img')(() => ({}));

interface Props {
    data?: any
    onHideCard: () => void
}

const UnstakeBox: FC<Props> = ({ data, onHideCard }) => {

  const [loaded, setLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingTx, setLoading] = useState<boolean>(false);

  const ref = useRef<HTMLImageElement>(null)
  const [isExpanded, setExpanded] = useState<boolean>(true);
  const [amount, setAmount] = useState('');
  const [periodSelect, setPeriodSelect] = useState<any>('1');
  const [isPending, setPending] = useState<boolean>(false);
  const { account } = useWeb3React();
  const { data: farmList } = useAppSelector((state) => state.nftFarms);
  const { pendingTxHash } = useAppSelector((state) => state.modal);
  const pendingTxHashRef: { current: string | undefined } = useRef();
  pendingTxHashRef.current = pendingTxHash;
  const { onShowNotification } = useNotification();

  const [pool, setPool] = useState<any>(undefined);

  const receiptTokenAllowance = getBalanceInEther(pool?.userInfo?.userReceiptTokenAllowance || BigNumber.from(0));
  const { onApproveStakingToken, onApproveReceiptToken, onDeposit, onWithdraw } = useNftFarmMasterChef(pool);
  const isReceiptTokenApproved = receiptTokenAllowance >= 1;
  const imageLoaded = () => {
    setIsLoading(false);
  }
  const onToggle = () => {
    setExpanded(!isExpanded);
  };
  const truncate = (str: string, n: number) => {
    return str?.length > n ? `${str.substr(0, n ) } ... ${str.substr(str.length - n, n) }`: str;
    };
    const changeValue = (val: string) => {
        setAmount((val));
    }

    const onChangePeriod = (option: any) => {
        // if (Number(selectedChainId) === Number(option.chainId)) return;
        setPeriodSelect(option);
    };

    useEffect(() => {
        if( farmList.length > 0 && data){
            for( let i = 0; i < farmList.length; i+=1 ){
                if( farmList[i].stakingToken === data.address ) {
                    // Object.freeze(farmList);
                    const farm : FarmInfo = farmList[i]
                    setPool(farm);
                    break
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [farmList, data]);

    const onConfirm = async () => {
        if( !isReceiptTokenApproved ){
            setLoading(true);

            try {
                await onApproveReceiptToken();
                onShowNotification({
                  title: 'Transaction Success',
                  description: 'Approve Receipt Token',
                  hasView: true,
                  txHash: pendingTxHashRef.current,
                });
              } catch (err) {
                onShowNotification({ title: 'Transaction Failed', description: 'Approve Receipt Token' });
                console.log('Approve Receipt Token Error: ', err);
              }
              setLoading(false);
        } else {
            setLoading(true);

            try {
                await onWithdraw([data.tokenId]);
                
                onShowNotification({
                  title: 'Transaction Success',
                  description: 'Withdraw Staking Token',
                  hasView: true,
                  txHash: pendingTxHashRef.current,
                });
                onHideCard();
              } catch (err) {
                onShowNotification({ title: 'Transaction Failed', description: 'Withdraw Staking Token' });
                console.log('Withdraw Error: ', err);
              }
              setLoading(false);
        }
      };

    const getButtonText = () => {
        if( isReceiptTokenApproved ) {
            return "Unstake";
        }
        return 'Approve';
      };
  return (
    <Container mt={1}>
        {/* <ToggleSection onClick={onToggle}>
        <ToggleIcon
          alt="toggle"
          src={icons.chevronDown}
          sx={{ transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
        />
      </ToggleSection> */}
        {/* <Box display='flex' sx={{border: '0px solid red'}}>
            <SellTitle>Unstake</SellTitle>
        </Box> */}
        {isExpanded && 
            <>
                {/* <Box mt={2} display='flex' sx={{height: '5px', borderBottom: '1px solid grey'}}/> */}
                <Box mt={0} display='flex' flexDirection='column'>
                    <Box display='flex' flexDirection='column' sx={{minHeight: '40px', width: '100%', borderRadius: '15px', border: '0px solid grey'}}>
                        <Box display='flex' flexDirection='column' padding='10px' justifyContent='flex-start'>
                                        <BasicText>
                                            Contract: {truncate(data?.stakeContract, 8)}
                                        </BasicText>
                                        <BasicText>
                                            Name: {data?.stakeName}
                                        </BasicText>
                                </Box>
                        </Box>
                        <ActionButton
                            disabled={!account || isLoadingTx}
                            onClick={onConfirm}
                            sx={{ width: '100%' }}
                            >
                            {isLoadingTx ? <CircularProgress size={20} sx={{ color: 'black' }} /> : getButtonText()}
                        </ActionButton>
                </Box>
            </>
        }
    </Container>
    // sx={{borderBottom: '1px solid grey', paddingBottom: '10px'}}
  );
};

export default UnstakeBox;
