import { poolInfos } from 'config/constants/stakingPools';
import { StakingInfo } from 'types/staking';
import AcropolisAbi from 'config/abi/Acropolis.json';
import BoardroomAbi from 'config/abi/Boardroom.json';
import TreasuryAbi from 'config/abi/Treasury.json';

export const getStakingPools = (chainId: number) => {
  const stakingPools = poolInfos.filter((row: any) => Number(chainId) === Number(row.chainId));
  return stakingPools;
};

export const getTreasuryAbi = () => TreasuryAbi;

export const getStakingPoolAbi = (type: string) => {
  if (type === 'Acropolis') {
    return AcropolisAbi;
  }

  if (type === 'Boardroom') {
    return BoardroomAbi;
  }

  return AcropolisAbi;
};
