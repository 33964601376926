import { createSlice } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';

import { TheatreInfo } from 'types/theatre';
import { fetchGlobalTheatreData } from './fetchGlobalTheatre';
import { fetchTheatreUserData } from './fetchUserDataTheatre';

interface TheatreState {
  data: TheatreInfo;
}

const initialState: TheatreState = {
    data: {
        basedPrice: 0,
        basedPriceInLastTwap: 0,
        burnableBasedLeft: 0,
        isBondPurchaseble: false,
        isBondRedeemable: false
    }
};

export const theatreSlice = createSlice({
  name: 'theatre',
  initialState,
  reducers: {
    reset: (state) => {
      state.data = {
        basedPrice: 0,
        basedPriceInLastTwap: 0,
        burnableBasedLeft: 0,
        isBondPurchaseble: false,
        isBondRedeemable: false
    };
    },

    setTheatreGlobalData: (state, action) => {
      state.data = action.payload.data;
    },
    setTheatreUserData: (state, action) => {
        const { data } = action.payload;
        // update stakings
        state.data.userInfo = data;
      },
  },
});

export const { reset, setTheatreGlobalData, setTheatreUserData} =
theatreSlice.actions;

// fetch global theatre info
export const fetchTheatreGlobalDataAsync =
  (chainId: string) =>
  async (dispatch: any): Promise<void> => {
    const { data } = await fetchGlobalTheatreData(chainId);

    dispatch(
        setTheatreGlobalData({
        data,
      })
    );
  };

  // fetch user theatre info
export const fetchTheatreUserDataAsync =
(account: string, chainId: string) =>
async (dispatch: any): Promise<void> => {
  const data = await fetchTheatreUserData(account, chainId);
  dispatch(
       setTheatreUserData({
      data,
    })
  );
};

export default theatreSlice.reducer;
