import { marketAddress, supportedCollections } from 'config/constants/market';
import MarketABI  from 'config/abi/BasedMarketplace.json';
import BridgeNFTAbi from 'config/abi/BridgeNFT.json';

export const getMarketSupportedCollectionsInfo = (chainId: number) => {
    const coll = supportedCollections.filter((row: any) => Number(chainId) === Number(row.chainId));
    return coll;
};
export const getMarketCollectionInfo = (chainId: number, address: string) => {
    const coll = supportedCollections.filter((row: any) => Number(chainId) === Number(row.chainId))
                                     .filter((row: any) => Number(address) === Number(row.address))
    if( coll.length > 0 )
        return coll[0];
    return undefined;
};

export const getMarketAddress = (chainId: number) => marketAddress[chainId];

export const getMarketABI = (chainId: number) => {
    if( chainId === 250 ){
        return MarketABI
    }
    if( chainId === 137 ){
        return MarketABI
    }
    return [];
};
