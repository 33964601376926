import { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Grid, IconButton, Link, styled, Typography } from '@mui/material';
import { icons, tokens } from 'config/constants/assets';
import { TokenSelectInput } from 'components/common/TokenSelectCard/TokenSelectInput';
import DataSelect from 'components/common/Select/DataSelect';
import { ContainedButton } from 'components/common/Button';
import { useWeb3React } from '@web3-react/core';
import { useAppSelector } from 'state/hooks';
import { getBalanceInEther } from 'utils';
import { useNotification } from 'hooks/useNotification';
import { BigNumber } from 'ethers';
import { useNftFarmMasterChef } from 'hooks/useNftFarmMasterChef';
import {CartesianGrid,Label,Legend, Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis} from 'recharts'
import "./Charts.css";
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '12px 5px 12px',
  borderRadius: '10px',
  background: 'rgb(6,1,1,0.3)',
  position: 'relative'
//   boxShadow:
//     '0px 2.15748px 29.22142px 0px rgba(0, 0, 0, 0.05), 0px 3.80638px 39.85961px 0px rgba(0, 0, 0, 0.07), 0px 5.70356px 44.46048px 0px rgba(0, 0, 0, 0.08), 0px 10.1608px 47.29564px 0px rgba(0, 0, 0, 0.09), 0px 25.99347px 52.37106px 0px rgba(0, 0, 0, 0.10), 0px 100px 77px 0px rgba(0, 0, 0, 0.13)',

//   [theme.breakpoints.up('xs')]: {
//     minHeight: '400px',
//   },
//   [theme.breakpoints.up('md')]: {
//     minHeight: '550px',
//   },
}));

// nft list title
const SellTitle = styled(Box)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  color: '#CDC392',
  fontSize: '18px',
  textAlign: 'left',
}));

const BasicText = styled(Box)(() => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    color: 'white',
    fontSize: '15px',
    textAlign: 'left',
}));

const Header = styled(Box)(({ theme }) => ({
    display: 'flex', 
    // background: 'red',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('xs')]: {
        minHeight: '70px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '50px',
      },
}));

const ContentBox = styled(Box)(({ theme }) => ({
    display: 'flex', 
    alignItems: 'center',
    // flexDirection: 'column',
    background: 'rgb(6,1,1,0.3)',
    borderRadius: '10px',
    [theme.breakpoints.up('xs')]: {
        minHeight: '70px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '50px',
      },
}));

const ContentLeft = styled(Box)(({ theme }) => ({
    display: 'flex', 
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
        minHeight: '70px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '50px',
      },
}));

const ContentRight = styled(Box)(({ theme }) => ({
    display: 'flex', 
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
        minHeight: '70px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '50px',
      },
}));

const StatisticBox = styled(Box)(({ theme }) => ({
    position: 'relative',
    // padding: '15px',
    height: '100%',
    minHeight: '200px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  [theme.breakpoints.up('xs')]: {
    height: '200px',
  },
  [theme.breakpoints.up('md')]: {
    height: '100%',
  },
}));

// toggle section
const ToggleSection = styled(Box)(() => ({
    position: 'absolute',
    right: '-7px',
    top: 'calc(50%px - 10px)',
    background: '#2A3139',
    boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '28px',
    height: '28px',
  }));

const StyledTokenInput = styled('div')(({ theme }) => ({
    maxWidth: '100%',
    width: '100%',
    border: '1px solid grey',
    borderRadius: '10px',
    backgroundColor: 'transparent'
}));

const TokenImgSection = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  }));

  const ActionButton = styled(ContainedButton)(() => ({
    // maxWidth: '102px',
    height: '40px',
  }));
  
  const TokenImg = styled('img')(({ theme }) => ({
      height: '25px',
      width: '25px',
  }));

  const ToggleIcon = styled('img')(() => ({}));

interface Props {
    data?: any
    onHideCard: () => void
}

const CustomTooltip = ( {active, payload, label} : TooltipProps<ValueType, NameType> )  => {
    if (active) {
      return (
        <div
          className="subscribers-by-channel-tooltip"
          style={{
            border: "1px solid #111",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            padding: "0 10px 0 10px",
            color: "white",
            borderRadius: "10px"
          }}
        >
          <p className="subscribers-by-channel-tooltip-label">{label}</p>
          <p className="subscribers-by-channel-tooltip-value">
            Price : {` ${payload?.[0]?.value} Obol`}
          </p>
        </div>
      );
    }
  
    return null;
  };

const TradeStats: FC<Props> = ({ data, onHideCard }) => {

  const [loaded, setLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingTx, setLoading] = useState<boolean>(false);
  const { nftData: sellList, nftsInfo: marketnftsInfo, marketNftsActivity } = useAppSelector((state) => state.market);
  const [isArrayEmpy, setIsArrayEmpty] = useState(true)

  const ref = useRef<HTMLImageElement>(null)
  const [isExpanded, setExpanded] = useState<boolean>(false);

  const { data: farmList } = useAppSelector((state) => state.nftFarms);
  const { pendingTxHash } = useAppSelector((state) => state.modal);
  const pendingTxHashRef: { current: string | undefined } = useRef();
  pendingTxHashRef.current = pendingTxHash;
  const { onShowNotification } = useNotification();

  const [pool, setPool] = useState<any>(undefined);
  const receiptTokenAllowance = getBalanceInEther(pool?.userInfo?.userReceiptTokenAllowance || BigNumber.from(0));
  const { onApproveStakingToken, onApproveReceiptToken, onDeposit, onWithdraw } = useNftFarmMasterChef(pool);
  const [statData, setStatData] = useState<any>([]);
  const [statData2, setStatData2] = useState<any>([]);
  const [reloadCount, setReloadCount] = useState<number>(0);

  const isReceiptTokenApproved = pool ? pool?.userInfo?.userStakingTokenApproved : false;

  const imageLoaded = () => {
    setIsLoading(false);
  }
  const onToggle = () => {
    setExpanded(!isExpanded);
  };
  
  useEffect(() => {
    const nftsLocal = [];
    const tmpData: any[] = [];
    let isEmpty = true

    if(sellList && data){
      try{
        for(let i = 0; i < sellList.collections.length; i+=1){
          if( sellList.collections[i].address.toLowerCase() === data.address.toLowerCase() ){
            const collection = sellList.collections[i];

            for( let k = 0; k < collection.dayBuys.length; k+=1){
                const statBuys = collection.dayBuys[k];
                if( statBuys.nftId === data.tokenId )
                {
                    nftsLocal.push({amount: statBuys.amount, expireTime: statBuys.expireTime, nftId: statBuys.nftId, paymentToken: statBuys.paymentToken, seller: statBuys.seller, buyer: "", timeStamp: statBuys.timeStamp  })
                    const price = statBuys.amount / 1e18
                    const a = new Date(statBuys.timeStamp * 1000);
                    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
                    const year = a.getFullYear();
                    const month = months[a.getMonth()];
                    const date = a.getDate();
                    const hour = a.getHours();
                    const min = a.getMinutes();
                    const sec = a.getSeconds();
                    const time = `Listed ${date} ${month} ${year}`
                    if( price !== 0 ) {
                      isEmpty = false
                      tmpData.push({name: time, uv: `${hour} ${min} ${sec}`, pv: price.toFixed(0), amt: price.toFixed(0), timeStamp: statBuys.timeStamp})
                    }
                }

            }
            for( let k = 0; k < collection.daySells.length; k+=1){
                const statSells = collection.daySells[k];
                if( statSells.nftId === data.tokenId )
                {
                    const price = statSells.amount / 1e18
                    const a = new Date(statSells.timeStamp * 1000);
                    const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
                    const year = a.getFullYear();
                    const month = months[a.getMonth()];
                    const date = a.getDate();
                    const hour = a.getHours();
                    const min = a.getMinutes();
                    const sec = a.getSeconds();
                    const time = `Sold ${date} ${month} ${year}`
                    if( price !== 0 ) {
                    isEmpty = false
                    console.log("SELL ", price)
                    tmpData.push({name: time, uv: `${hour} ${min} ${sec}`, pv: price.toFixed(0), amt: price.toFixed(0), timeStamp: statSells.timeStamp})
                    }
                }
                // nftsLocal.push({amount: statSells.amount, expireTime: 0, nftId: statSells.nftId, paymentToken: statSells.paymentToken, seller: "", buyer: statSells.buyer, timeStamp: statSells.timeStamp  })

            }
            tmpData.sort((a : any, b: any) => {
                const nameA = a.timeStamp; // ignore upper and lowercase
                const nameB = b.timeStamp; // ignore upper and lowercase
                return nameA - nameB
              });
            // nftsLocal.sort( (x, y) => {
            //     return y.timeStamp - x.timeStamp;
            // })
            // dispatch(fetchNftsInfoAsync(sellList.collections[i].address.toLowerCase(), nftsLocal, selectedChainId));
          }
        }
        setStatData(tmpData)
        if( !isEmpty ){
          setIsArrayEmpty(false)
        }
      }
      catch(err){
        console.log(err)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketnftsInfo, data, sellList]);

  return (
    <>
    {!isArrayEmpy && 
    <Container>
        <ToggleSection onClick={onToggle}>
        <ToggleIcon
          alt="toggle"
          src={icons.chevronDown}
          sx={{ transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }}
        />
      </ToggleSection>
        <Box display='flex' sx={{border: '0px solid red'}}>
            <SellTitle>Activity</SellTitle>
        </Box>
        {isExpanded  && 
            <>
                <StatisticBox>

                    <ResponsiveContainer key={reloadCount + 1} height='100%' width='100%' minHeight='200px'>
                            <LineChart key={reloadCount} data={statData} margin={{ top: 10, right: 10, left: 0, bottom: 0 }} >
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip content={<CustomTooltip/>} />
                            <Line  key={reloadCount + 2} type="monotone" dataKey="pv"  stroke="#8884d8" activeDot={{ r: 8 }} />
                            </LineChart>
                     </ResponsiveContainer>
                </StatisticBox>

            </>
        }    
    </Container>
    
    }
    </>
    // sx={{borderBottom: '1px solid grey', paddingBottom: '10px'}}
  );
};

export default TradeStats;
