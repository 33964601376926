
import { getMasterChefAbi, getNftFarms } from 'utils/farms';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';
import GodNftAbi from 'config/abi/GodNft.json';
import MarketAbi from 'config/abi/BasedMarketplace.json'
import multicall from 'utils/multicall';
import { BigNumber } from 'ethers';
import { FarmCategory } from 'types/farm';
import LpPairAbi from 'config/abi/LpPair.json';
import { getBalanceInEther } from 'utils';
import { getMarketABI, getMarketAddress, getMarketSupportedCollectionsInfo } from 'utils/market';
import { AbiItem } from 'web3-utils';

// single
export const fetchMarketNftsInfo = async (nfts: any[], collectionAddr: string) => {
    if( nfts && nfts.length  > 0 ){
        try{
            const calls1 = nfts.map((row: any) => ({
                address: collectionAddr,
                name: 'tokenURI',
                params: [row.nftId],
              }));
        
            const tokenUris = await multicall(GodNftAbi, calls1);
            const tokenMetadataArray = await Promise.all(
                tokenUris.map(async (tokenUri: string) => {
                  const tokenMetadata = await fetch(`${tokenUri}.json`).then((res) => res.json());
      
                  return {
                    image: tokenMetadata.image.replace('ipfs://', 'https://basedlabs.mypinata.cloud/ipfs/'),
                    rank: tokenMetadata.rank || 99,
                    meta: tokenMetadata,
                  };
                })
              );
    
            const userNfts = nfts.map((row: any, i: number) => ({
                tokenId: row.nftId,
                image: tokenMetadataArray[i].image,
                rank: tokenMetadataArray[i].rank,
                meta: tokenMetadataArray[i].meta,
                address: collectionAddr,
                owner: "0x00", // TODO OWner of,
                isStaked: false,
                marketData: row
             }));
             return {data: userNfts}
        }
        catch(err){
            console.log(err)
        }
    }
    return {
        data: []
    };
  };