/* eslint-disable no-console */
import axios from 'axios';

export const getTokenPriceFromDexTools = async (chainId: number, pairAddress: string): Promise<number> => {
  if (!pairAddress) return 0;

  let chainName = "";
  if( chainId === 137 )
    chainName = "polygon"

  if( chainId === 43114 ) 
    chainName = "avalanche"

  const options = {
    method: 'GET',
    headers: {
      'X-BLOBR-KEY': 'CNs5D6fc0v7abGANh6wa91A8regiyXmj'
    },
  };

  const cgcUrl = `https://open-api.dextools.io/free/v2/pool/${chainName}/${pairAddress}/price`;
  try {

    const response = await fetch(cgcUrl, options)
    if (response.ok) { 
        const json = await response.json();
        return json.data.price;
      } 
  } catch (err) {
    console.log('DexTools token price fetch error: ', err);
    return 0;
  }

  return 0;
};

