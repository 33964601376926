import { FC } from 'react';
import { InputAdornment, styled, TextField, TextFieldProps } from '@mui/material';
import { ContainedButton } from 'components/common/Button';

const StyledInput = styled(TextField)(() => ({
  width: '100%',
  borderColor: 'none',
  background: '#FFFFFF',
  borderRadius: '8px',
  boxShadow:
    '0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',

  '>div': {
    height: '100%',
  },
}));

const MaxButton = styled(ContainedButton)(() => ({
  fontWeight: '400',
  fontSize: '12px',
  lineHeight: '15px',
  padding: '0px 4px',
  height: '24px',
  minWidth: 'auto',
  width: 'auto',
}));

interface ContainedInputProps {
  symbol?: any;
  onClickMax?: () => void;
  textColor?: string;
}

const ContainedInput: FC<TextFieldProps & ContainedInputProps> = ({ symbol = '', textColor = 'rgba(39, 40, 42, 0.8)', onChange, onClickMax, ...props }) => (
  <StyledInput
    fullWidth
    inputProps={{
      style: {
        color: textColor ,
        fontFamily: 'Prompt',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '21px',
        padding: '6px',
        paddingLeft: symbol ? '8px' : '0px',
        background: 'transparent',
      },
    }}
    // eslint-disable-next-line react/jsx-no-duplicate-props
    InputProps={{
      startAdornment: (
        <InputAdornment position="start" sx={{ margin: '0px' }}>
          {symbol}
        </InputAdornment>
      ),
      endAdornment: (
        <InputAdornment position="end" sx={{ margin: '0px' }}>
          {onClickMax && (
            <MaxButton disabled={false} onClick={onClickMax}>
              MAX
            </MaxButton>
          )}
        </InputAdornment>
      ),
    }}
    onChange={onChange}
    {...props}
  />
);

export { ContainedInput };
