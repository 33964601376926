import { chains, tokens } from 'config/constants/assets';

const bridgeInfo = [
    {
        chainId: 250,
        chainLogo: chains.fantom,
        chainsTo:[
            {
                chainName: 'AVAX',
                chainId: 106,
                chainLogo: chains.avalanche,
                contractAddress: "0x3dd5EC9eDA87edbD74a8149ACb76EF01b1F3A243",
                tokensFrom: [
                    {
                        name: "BASED",
                        address: "0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae",
                        symbol: "BASED",
                        buyHelp: {
                            buyLink: "https://next-gen.basedfinance.io/dex/swap/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae",
                            buyText: "Buy BASED",
                            bridgeText: "BRIDGE BASED TO POLYGON",
                            buyHelperText1: "You don't have enough BASED",
                            buyHelperText2: ""
                        },
                        tokensTo: [
                            {
                                name: "aBASED",
                                address: "0x1C1CDF8928824dac36d84B3486D598B9799bA6c0",
                                decorativeName: "aBased"
                            }
                        ],
                        feeToken: {
                            name: "OBOL",
                            address: "0x1539C63037D95f84A5981F96e43850d1451b6216",
                            symbol: "OBOL",
                            buyHelp:{
                                buyLink: "https://next-gen.basedfinance.io/dex/swap/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x1539C63037D95f84A5981F96e43850d1451b6216",
                                buyText: "Buy OBOL",
                                bridgeText: "BRIDGE BASED TO POLYGON",
                                buyHelperText1: "You don't have enough OBOL",
                                buyHelperText2: ""
                            }
                        },
                        tokenLogo: tokens.based
            
                    }
                ]
            },
            {
                chainName: 'POLYGON',
                chainId: 109,
                chainLogo: chains.polygon2,
                contractAddress: "0x3dd5EC9eDA87edbD74a8149ACb76EF01b1F3A243",
                tokensFrom: [
                    {
                        name: "BASED",
                        address: "0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae",
                        symbol: "BASED",
                        tokenLogo: tokens.based,
                        buyHelp: {
                            buyLink: "https://next-gen.basedfinance.io/dex/swap/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae",
                            buyText: "Buy BASED",
                            bridgeText: "BRIDGE BASED TO POLYGON",
                            buyHelperText1: "You don't have enough BASED",
                            buyHelperText2: ""
                        },
                        tokensTo: [
                            {
                                name: "pBASED",
                                address: "0xd44779F0E18729cB6AFB7548cF10EF448a602668",
                                decorativeName: "pBased"
                            }
                        ],
                        feeToken: {
                            name: "OBOL",
                            address: "0x1539C63037D95f84A5981F96e43850d1451b6216",
                            symbol: "OBOL",
                            buyHelp:{
                                buyLink: "https://next-gen.basedfinance.io/dex/swap/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x1539C63037D95f84A5981F96e43850d1451b6216",
                                buyText: "Buy OBOL",
                                bridgeText: "BRIDGE BASED TO POLYGON",
                                buyHelperText1: "You don't have enough OBOL",
                                buyHelperText2: ""
                            }
                        }
                    }
                ]
            },
            {
                chainName: 'zkSync',
                chainId: 165,
                chainLogo: chains.zkSync,
                contractAddress: "0x3dd5EC9eDA87edbD74a8149ACb76EF01b1F3A243",
                tokensFrom: [
                    {
                        name: "BASED",
                        address: "0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae",
                        symbol: "BASED",
                        tokenLogo: tokens.based,
                        buyHelp: {
                            buyLink: "https://next-gen.basedfinance.io/dex/swap/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae",
                            buyText: "Buy BASED",
                            bridgeText: "BRIDGE BASED TO POLYGON",
                            buyHelperText1: "You don't have enough BASED",
                            buyHelperText2: ""
                        },
                        tokensTo: [
                            {
                                name: "zkBASED",
                                address: "0x8a78ec16D4B0d3230441cbB8b43DD18c0b8Dc01c",
                                decorativeName: "zkBased"
                            }
                        ],
                        feeToken: {
                            name: "OBOL",
                            address: "0x1539C63037D95f84A5981F96e43850d1451b6216",
                            symbol: "OBOL",
                            buyHelp:{
                                buyLink: "https://next-gen.basedfinance.io/dex/swap/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x1539C63037D95f84A5981F96e43850d1451b6216",
                                buyText: "Buy OBOL",
                                bridgeText: "BRIDGE BASED TO POLYGON",
                                buyHelperText1: "You don't have enough OBOL",
                                buyHelperText2: ""
                            }
                        }
                    }
                ]
            },
            {
                chainName: 'BASE',
                chainId: 184,
                chainLogo: chains.base,
                contractAddress: "0x3dd5EC9eDA87edbD74a8149ACb76EF01b1F3A243",
                tokensFrom: [
                    {
                        name: "BASED",
                        address: "0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae",
                        symbol: "BASED",
                        tokenLogo: tokens.based,
                        buyHelp: {
                            buyLink: "https://next-gen.basedfinance.io/dex/swap/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae",
                            buyText: "Buy BASED",
                            bridgeText: "BRIDGE BASED TO POLYGON",
                            buyHelperText1: "You don't have enough BASED",
                            buyHelperText2: ""
                        },
                        tokensTo: [
                            {
                                name: "BASED",
                                address: "0x74235c64f806341C14bF2be0887EEcd55daE955E",
                                decorativeName: "Based"
                            }
                        ],
                        feeToken: {
                            name: "OBOL",
                            address: "0x1539C63037D95f84A5981F96e43850d1451b6216",
                            symbol: "OBOL",
                            buyHelp:{
                                buyLink: "https://next-gen.basedfinance.io/dex/swap/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x1539C63037D95f84A5981F96e43850d1451b6216",
                                buyText: "Buy OBOL",
                                bridgeText: "BRIDGE BASED TO POLYGON",
                                buyHelperText1: "You don't have enough OBOL",
                                buyHelperText2: ""
                            }
                        }
                    }
                ]
            }
        ]
    },
]

const bridgeNftInfo = [
    {
        chainId: 250,
        chainLogo: chains.fantom,
        chainsTo:[
            {
                chainName: 'POLYGON',
                chainId: 109,
                chainLogo: chains.polygon2,
                contractAddress: "0xE0a90dd7813b580b0Dd24E6C9BE84993dE9c535f",
                tokensFrom: [
                    {
                        name: "GodNFT",
                        address: "0xc5777AD2250D7b12a3f2383afB4464b9E49bE297",
                        symbol: "GodNFT",
                        buyHelp: {
                            buyLink: "https://next-gen.basedfinance.io/dex/swap/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae",
                            buyText: "Buy BASED",
                            bridgeText: "BRIDGE BASED TO POLYGON",
                            buyHelperText1: "You don't have enough BASED",
                            buyHelperText2: ""
                        },
                        tokensTo: [
                            {
                                name: "GodNftPoly",
                                address: "0xc978B4d4E9D2e8047E01A577c2b29d5A6Cb2eF5E",
                                decorativeName: "GodNft"
                            }
                        ],
                        feeToken: {
                            name: "OBOL",
                            address: "0x1539C63037D95f84A5981F96e43850d1451b6216",
                            symbol: "OBOL",
                            buyHelp:{
                                buyLink: "https://next-gen.basedfinance.io/dex/swap/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x1539C63037D95f84A5981F96e43850d1451b6216",
                                buyText: "Buy OBOL",
                                bridgeText: "BRIDGE BASED TO POLYGON",
                                buyHelperText1: "You don't have enough OBOL",
                                buyHelperText2: ""
                            }
                        },
                        tokenLogo: tokens.based
                    }
                ]
            },
        ]
    },
    {
        chainId: 137,
        chainLogo: chains.polygon2,
        chainsTo:[
            {
                chainName: 'FTM',
                chainId: 112,
                chainLogo: chains.fantom,
                contractAddress: "0x9BC6DA9Be2dDC0B0b04b6D89Dc57c49FeDCac522",
                tokensFrom: [
                    {
                        name: "GodNFTPoly",
                        address: "0xc978B4d4E9D2e8047E01A577c2b29d5A6Cb2eF5E",
                        symbol: "GodNFTPoly",
                        buyHelp: {
                            buyLink: "https://next-gen.basedfinance.io/dex/swap/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae",
                            buyText: "Buy BASED",
                            bridgeText: "BRIDGE BASED TO POLYGON",
                            buyHelperText1: "You don't have enough BASED",
                            buyHelperText2: ""
                        },
                        tokensTo: [
                            {
                                name: "GodNft",
                                address: "0xc5777AD2250D7b12a3f2383afB4464b9E49bE297",
                                decorativeName: "GodNft"
                            }
                        ],
                        feeToken: {
                            name: "pBased",
                            address: "0xd44779F0E18729cB6AFB7548cF10EF448a602668",
                            symbol: "pBased",
                            buyHelp:{
                                buyLink: "https://next-gen.basedfinance.io/dex/swap/0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83/0x1539C63037D95f84A5981F96e43850d1451b6216",
                                buyText: "Buy OBOL",
                                bridgeText: "BRIDGE BASED TO POLYGON",
                                buyHelperText1: "You don't have enough OBOL",
                                buyHelperText2: ""
                            }
                        },
                        tokenLogo: tokens.based
                    }
                ]
            },
        ]
    }
]

const bridgeInfos = [...bridgeInfo];
const bridgeNftInfos = [...bridgeNftInfo];

export {bridgeInfos, bridgeNftInfos}