import { UpgradeCategory } from "types/upgrade";

const nftUpgrade = [
    {
        chainId: 250,
        contractUpgrade: "0x7D1fed46389E8f64474EaC0B28cC1696528cfE09",
        name: 'Prometheus Origins',
        address: '0xc5777AD2250D7b12a3f2383afB4464b9E49bE297',
        uri: "https://basedlabs.mypinata.cloud/ipfs/QmNn8zCDLF7qzk2pvSCdAQxeVanDTavikavsQvjWoLFDQF/",
        category: UpgradeCategory.ORIGINS,
        upgradeTo: "0x6d74573A73f9B52B42fc07888f243b62d53fd2e0",
        downgradeTo: "",
        notes: [{name: "Staking", val: "FTM."}, {name: "Bridging", val: "NO."}, {name: "Multi-chain staking", val: "NO."}],
        enabled: true
    },
    {
        chainId: 250,
        contractUpgrade: "0x6b6433E3f17644b59431e5E45Fcc098ae0f88B5B",
        contractDowngrade: "",
        name: 'Prometheus Traveler',
        address: '0x6d74573A73f9B52B42fc07888f243b62d53fd2e0',
        uri: "https://basedlabs.mypinata.cloud/ipfs/QmNgApep6coebAFy18pBf6nw5FznREGDppf2HLbgQeoVk9/",
        category: UpgradeCategory.TRAVELER,
        upgradeTo: "0xC6B720270aB699f52b1e5f529aA364f7407FceCd",
        downgradeTo: "",
        notes: [{name: "Staking", val: "NO."}, {name: "Bridging", val: "Any Chain."}, {name: "Multi-chain staking", val: "NO."}],
        enabled: true
    },
    {
        chainId: 250,
        contractDowngrade: "0x6b6433E3f17644b59431e5E45Fcc098ae0f88B5B",
        name: 'Prometheus Emperor',
        address: '0xC6B720270aB699f52b1e5f529aA364f7407FceCd',
        uri: "https://basedlabs.mypinata.cloud/ipfs/QmSKUNmTUbjxJEYh1nc8uHMjujKat9UPszDMTspAkVnisW/",
        category: UpgradeCategory.REALM,
        upgradeTo: "",
        downgradeTo: "0x6d74573A73f9B52B42fc07888f243b62d53fd2e0",
        notes: [{name: "Staking", val: "Chain Specific."}, {name: "Bridging", val: "NO."}, {name: "Multi-chain staking", val: "YES."}],
        enabled: true
    }
]

const supportedUpgrades = [...nftUpgrade];
 
export { supportedUpgrades };