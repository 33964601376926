import { getUpgradeVariants } from "utils/nftUpgrade";
import GodNftAbi from 'config/abi/GodNft.json';
import UpgradeAbi from 'config/abi/Upgrade.json'
import multicall from 'utils/multicall';
import LpPairAbi from 'config/abi/LpPair.json'
import { BigNumber } from "ethers";

export const fetchUpgradeInfo = async (collectionAddr: string, nftId: number, chainId: string, isDowngrade: boolean) => {
    if (!collectionAddr) return { };
    
    const collections = getUpgradeVariants(Number(chainId));
    const collection = collections.filter((row: any) => Number(collectionAddr) === Number(row.address));
    try{
      if( collection.length  > 0 ){

        const upgradeContract = isDowngrade ? collection[0].contractDowngrade : collection[0].contractUpgrade

        if( upgradeContract ){
            const sellTokens = []
            let isUpgradeAvailable = false
            try{
                const [sellToken, isUpgradable] = await multicall(UpgradeAbi, [
                    {
                      address: upgradeContract,
                      name: 'sellTokensArray',
                      params: [0]
                    },
                    {
                        address: upgradeContract,
                        name: "upgradeAvailable"                    
                    }
                  ]);
                  sellTokens.push(sellToken[0])
                  isUpgradeAvailable = isUpgradable
            }
            catch(err){
// 
            }
            try{
                const [sellToken] = await multicall(UpgradeAbi, [
                    {
                      address: upgradeContract,
                      name: 'sellTokensArray',
                      params: [1]
                    },
                  ]);
                  sellTokens.push(sellToken[0])
            }
            catch(err){
// 
            }
            try{
                const [sellToken] = await multicall(UpgradeAbi, [
                    {
                      address: upgradeContract,
                      name: 'sellTokensArray',
                      params: [2]
                    },
                  ]);
                  sellTokens.push(sellToken[0])
            }
            catch(err){
// 
            }

            return {
                sellTokens,
                isUpgradeAvailable,
                upgradeContract,
                tokenAddress: collection[0].address
            };

        };
        return {
            sellTokens: [],
            isUpgradeAvailable: false,
            upgradeContract: "",
            tokenAddress: ""
        };

    }
    }
    catch(err){
      console.log(err)
    }
    return {
        sellTokens: [],
        isUpgradeAvailable: false,
        upgradeContract: "",
        tokenAddress: ""
    };
  };
// Account, Collection, Token, 
  export const fetchUserUpgradeInfo = async (account: string,  upgradeInfo: any) => {
    try{
      const calls1 = [
        {
          address: upgradeInfo.token,
          name: 'allowance',
          params: [account, upgradeInfo.contract],
        },
        {
          address: upgradeInfo.token,
          name: 'balanceOf',
          params: [account],
        },
      ];
  
      const [allowanceFee, balanceFeeRaw] = await multicall(LpPairAbi, calls1);
      return {tokenFeeAllowance: allowanceFee, tokenFeeBalance: balanceFeeRaw}
    }
    catch(err){
      // 
    }
    // NFT ALLOWANCE

    return {tokenFeeAllowance: BigNumber.from(0), tokenFeeBalance:  BigNumber.from(0)}
  }