import { supportedUpgrades } from 'config/constants/nft_upgrade';

export const getUpgradeVariants = (chainId: number) => {
    const coll = supportedUpgrades.filter((row: any) => Number(chainId) === Number(row.chainId));
    return coll;
};

export const getUpgradeVariantsByAddress = (chainId: number, address: string) => {
    const coll = supportedUpgrades.filter((row: any) => Number(chainId) === Number(row.chainId))
                                     .filter((row: any) => Number(address) === Number(row.address))
    if( coll.length > 0 )
        return coll[0];
    return undefined;
};
