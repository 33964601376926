import { staking, tokens as tokenImages } from 'config/constants/assets';

const fantomPoolInfos = [
  {
    name: 'Acropolis',
    address: '0xE5009Dd5912a68B0D7C6F874cD0b4492C9F0e5cD',
    type: 'Acropolis',
    stakingToken: '0x49C290Ff692149A4E16611c694fdED42C954ab7a', // bshare
    stakingTokenName: 'BShare',
    rewardToken: '0x8D7d3409881b51466B483B11Ea1B8A03cdEd89ae', // based
    rewardTokenDecimal: 18,
    rewardTokenImage: tokenImages.rewardBased,
    rewardTokenName: 'Based',
    tokenImage: tokenImages.bshard,
    chainId: 250,
    apr: 12.5652,
    treasuryAddress: '0x25430E2ACb1255453135b2c64436C6b1Ff8153C5'
  },
  {
    name: 'Boardroom',
    address: '0x8ff9eFB99D522fAC6a21363b7Ca54d25477637F6',
    type: 'Boardroom',
    stakingToken: '0x141FaA507855E56396EAdBD25EC82656755CD61e', // smelt
    stakingTokenName: 'Smelt',
    rewardToken: '0x1539C63037D95f84A5981F96e43850d1451b6216', // obol
    rewardTokenDecimal: 18,
    rewardTokenImage: tokenImages.rewardObol,
    rewardTokenName: 'Obol',
    tokenImage: tokenImages.smelt,
    chainId: 250,
    apr: 12.5652,
    treasuryAddress: '0xc6Afc56E74f7d59A273F711B36ecDb4E81454614'
  },
];

const poolInfos = [...fantomPoolInfos];

export { poolInfos };
