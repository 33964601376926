import { FC, useEffect, useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { useWeb3React } from '@web3-react/core';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setIsWalletChecked, setSelectedChainId } from 'state/chain/chainSlice';
import { useEthBalance } from 'hooks/useEthBalance';
import { routes, RouteType } from 'config/constants/header';
import { icons, images } from 'config/constants/assets';
import { getBalanceInEther } from 'utils/formatBalance';
import { getChainTokenSymbol } from 'utils/chain';
import { AccountButton, ConnectWallet } from 'components/common/Button';
import { ChainSelect } from 'components/common/Select';
import { ChainInfo } from 'types/chain';
import { SUPPORTED_CHAINS } from 'config/constants/chains';
import { switchToNetwork } from 'utils/wallet';
import { ExtraMenu } from 'components/ExtraMenu';
import useAuth from 'hooks/useAuth';

const AppHeader = styled(Box)(({ theme }) => ({
  height: '75px',
  position: 'sticky',
  top: '0px',
  zIndex: 150,
  background: theme.palette.common.dark2,
  padding: '0px 24px',
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1px solid rgba(255, 255, 255, 0.7)',

  [theme.breakpoints.up('xs')]: {
    height: '53px',
  },
  [theme.breakpoints.up('sm')]: {
    height: '75px',
  },
}));

const HeaderContent = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '16px',
  width: '100%',
  maxWidth: '851px',
  margin: 'auto',
}));

// logo section
const LogoSection = styled(Box)(() => ({
  cursor: 'pointer',
}));

const LogoImg = styled('img')(() => ({
  minWidth: '22px',
}));

// menu section
const MenuSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '20px',
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',

  [theme.breakpoints.up('xs')]: {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    gap: '30px',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    gap: '30px',
  },
}));

const MenuItem = styled(Typography)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  cursor: 'pointer',
  fontSize: '16px',
  lineHeight: '24px',
}));

// chain section
const ChainSection = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    display: 'block',
  },
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

// action section
const AccountSection = styled(Box)(() => ({
  display: 'flex',
  position: 'relative',
  textAlign: 'center',
  alignItems: 'center',
}));

const AccountBalance = styled(Box)(({ theme }) => ({
  marginRight: '12px',

  [theme.breakpoints.up('xs')]: {
    display: 'none',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'block',
    marginRight: '12px',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'block',
    marginRight: '24px',
  },
}));

const AccountBalanceLabel = styled(Typography)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '300',
  cursor: 'pointer',
  fontSize: '12px',
  lineHeight: '18px',
  color: 'rgba(255, 255, 255, 0.7)',
}));

const AccountBalanceValue = styled(Typography)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '600',
  cursor: 'pointer',
  fontSize: '16px',
  lineHeight: '24px',
  color: '#FFFFFF',
}));

const Header: FC = () => {
  const [tempChainSelectOption, setTempChainSelectOption] = useState<any>(null);
  const [extraMenuOpened, setExtraMenuOpened] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { selectedChainId } = useAppSelector((state) => state.chain);

  const { data: nftFarms } = useAppSelector((state) => state.nftFarms);
  const { account, chainId, connector } = useWeb3React();
  const { autoLogin, logout } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const ethBalanceRaw = useEthBalance();
  const ethBalance = getBalanceInEther(ethBalanceRaw);

  const chainSelectOptions = SUPPORTED_CHAINS.filter((row: ChainInfo) => row.isMainnet);
  const [chainSelectOption, setChainSelectOption] = useState<any>(chainSelectOptions.find((row) => Number(row.chainId) === Number(selectedChainId)));

  const pageName = pathname.replace('/', '').split('/')[0];

  useEffect(() => {
    autoLogin(connector);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSwitchMetamaskNetwork = async (targetChainId: number) => {
    try {
      if (connector.provider! && targetChainId) {
        await switchToNetwork({ library: connector.provider!, chainId: targetChainId });
      } else if (!connector.provider && targetChainId) {
        let web3Provider;
        if (window.ethereum) {
          // eslint-disable-next-line no-new
          web3Provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
        } else if (window.web3) {
          // eslint-disable-next-line no-new
          web3Provider = new ethers.providers.Web3Provider(window.web3, 'any');
        }
        if (web3Provider) {
          await switchToNetwork({ library: web3Provider, chainId: targetChainId });
        }
      }
      window.localStorage.setItem("selectedChainId", chainSelectOption.chainId);
      dispatch(setSelectedChainId(chainSelectOption.chainId));
      dispatch(
        setIsWalletChecked(true))
      // eslint-disable-next-line @typescript-eslint/no-shadow
    } catch (error: any) {
      console.log(`Failed to switch networks: ${error.message}`, { variant: 'error' });

      if (error?.code === 4001) {
        // revert chain change on chain select dropdown only when wallet is connected
        if (account) {
          setChainSelectOption(tempChainSelectOption);
        }
      }
      dispatch(
        setIsWalletChecked(true))
    }
  };
  useEffect(() => {
    if(!chainId)
      return
    
    const found = chainSelectOptions.find((row) => Number(row.chainId) === Number(chainId))
    if( found ){
      window.localStorage.setItem("selectedChainId", chainId.toString());
      setChainSelectOption(chainSelectOptions.find((row) => Number(row.chainId) === Number(chainId)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  useEffect(() => {
    if (!chainSelectOption) return;
    onSwitchMetamaskNetwork(chainSelectOption.chainId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainSelectOption]);

  const onClickMenuItem = (item: RouteType) => {
    navigate(item.url);
  };

  const onChangeChain = (option: any) => {
    if (Number(selectedChainId) === Number(option.chainId)) return;
    if (!account) {
      dispatch(setSelectedChainId(option.chainId));
    }
    const selectedChainOption = chainSelectOptions.find((row) => Number(row.chainId) === Number(option.chainId));

    setTempChainSelectOption(chainSelectOption);
    setChainSelectOption(selectedChainOption);
  };

  const onCloseExtraMenu = () => {
    setExtraMenuOpened(false);
  };

  const onLogout = async () => {
    await logout();
  };

  const getHeaderActiveColor = () => {
    if (Number(selectedChainId) === 250) return '#716C54';
    if (Number(selectedChainId) === 137) return '#716C54';
    if (Number(selectedChainId) === 43114) return '#716C54';
    return '#716C54';
  };

  const getUserNfts = () => {
    let nfts: any[] = [];
    nftFarms.map((row) => {
      const farmNfts = row?.userInfo?.userNfts || [];
      const stakedNfts = row?.userInfo?.stakedNfts || [];
      nfts = [...nfts, ...farmNfts, ...stakedNfts];
      return row;
    });

    return nfts;
  };

  const getUserPfp = () => {
    const userNfts = getUserNfts();
    if (userNfts.length === 0) {
      return images.defautAccountPhoto;
    }    
    const topRankNft = userNfts.sort((a, b) => (a.rank > b.rank ? 1 : -1))[0];
    return topRankNft.image;
  };

  return (
    <AppHeader>
      {extraMenuOpened && <ExtraMenu onClose={onCloseExtraMenu} />}
      <HeaderContent>
        <LogoSection>
          <LogoImg alt="logo" onClick={() => setExtraMenuOpened(true)} src={icons.hamburger} />
        </LogoSection>
        <MenuSection>
          {routes[Number(selectedChainId)] &&
            routes[Number(selectedChainId)].map((row: RouteType) => (
              <MenuItem
                key={row.key}
                onClick={() => onClickMenuItem(row)}
                sx={{
                  color: row.key === pageName ? getHeaderActiveColor() : '#CDC392',
                }}
              >
                {row.text}
              </MenuItem>
            ))}
        </MenuSection>
        <Box alignItems="center" display="flex" gap="24px">
          <ChainSection>
            <ChainSelect onSelect={onChangeChain} options={chainSelectOptions} value={chainSelectOption} />
          </ChainSection>
          <AccountSection>
            {!account && <ConnectWallet />}
            {account && (
              <>
                <AccountBalance>
                  <AccountBalanceLabel>Balance</AccountBalanceLabel>
                  <AccountBalanceValue>
                    {`${ethBalance.toFixed(2)} ${getChainTokenSymbol(chainId)} `}
                  </AccountBalanceValue>
                </AccountBalance>
                <AccountButton image={getUserPfp()} />
              </>
            )}
          </AccountSection>
        </Box>
      </HeaderContent>
    </AppHeader>
  );
};

export default Header;
