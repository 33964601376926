import React from 'react';
import { Box, styled, Typography } from '@mui/material';

import { tokens } from 'config/constants/assets';

const TokenLogo = styled('img')(() => ({
  height: '24px',
  width: '24px',
  borderRadius: '50%',
}));

const TokenName = styled(Typography)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: 'normal',
  color: 'rgba(255, 255, 255, 0.70)',
  cursor: 'pointer',
}));

const TokenBalance = styled(Typography)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '14px',
  lineHeight: 'normal',
  color: '#ffffff',
  cursor: 'pointer',
}));

const TokenSymbol = styled(Typography)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '12px',
  lineHeight: 'normal',
  color: '#ffffff',
  cursor: 'pointer',
}));

interface Props {
  token?: any;
}

const TokenCard: React.FC<Props> = ({ token }) => {
  const tokenLogo = token.tokenImage;
  const tokenName = token.symbol;
  const tokenBalance = token.balance;
  // const tokenSymbol = token.symbol;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 8px',
        background: 'rgba(217, 217, 217, 0.20)',
        borderRadius: '10px',
      }}
    >
      <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
        <TokenLogo alt="token logo" src={tokenLogo} />
        <TokenName>{tokenName}</TokenName>
      </Box>
      <Box sx={{ display: 'flex', gap: '4px', alignItems: 'baseline' }}>
        <TokenBalance>{tokenBalance.toFixed(2)}</TokenBalance>
        {/* <TokenSymbol>{tokenSymbol}</TokenSymbol> */}
      </Box>
    </Box>
  );
};

export default TokenCard;
