import { createSlice } from '@reduxjs/toolkit';

import {  NftMarketInfo, SupportedCollections } from 'types/nft';
import { fetchNftInfo, fetchNftsInfo } from './fetchNftInfo';
import { fetchMarketGlobalInfo, fetchMarketTokenAllowance } from './fetchMarketGlobalInfo';
import { fetchMarketNftsInfo } from './fetchMarketNftsInfo';

interface FarmState {
  nftData: any;
  nftsInfo: any;
  currentNft: any;
  loaded: boolean;
  isStakedNftsLoaded: boolean;
  isUserNftsLoaded: boolean;
  supportedCollections: SupportedCollections[];
  tokenAllowance: number
  marketNftsActivity: any;
}

const initialState: FarmState = {
  nftData: [],
  nftsInfo: [],
  currentNft : {},
  loaded: false,
  isStakedNftsLoaded: false,
  isUserNftsLoaded: false,
  supportedCollections: [],
  tokenAllowance: 0,
  marketNftsActivity: []
};

export const marketSlice = createSlice({
  name: 'marketFarms',
  initialState,
  reducers: {
    reset: (state) => {
      state.nftData = [];
      state.loaded = false;
      state.isStakedNftsLoaded = false;
      state.isUserNftsLoaded = false;
      state.supportedCollections = [];
      state.nftsInfo = [];
      state.marketNftsActivity = [];
    },

    setNftInfo: (state, action) => {
        state.currentNft = action.payload.data;
    },
    setMarketGlobalInfo: (state, action) => {
       const { data } = action.payload;
       state.nftData = data; 
    },
    setMarketNftsInfo: (state, action) => {
        const { data } = action.payload;
        state.nftsInfo = data.data; 
     },
     setTokenAllowanceInfo: (state, action) => {
        state.tokenAllowance = action.payload.data;
    },
    setNftsInfo: (state, action) => {
      state.marketNftsActivity = action.payload.data;
  },
  },
});

export const { reset, setNftInfo, setMarketGlobalInfo, setMarketNftsInfo,setTokenAllowanceInfo, setNftsInfo} =
  marketSlice.actions;

  // fetch global farm user info
export const fetchNftInfoAsync =
(collectionAddr: string, nftId: number, chainId: string) =>
async (dispatch: any): Promise<void> => {
  const data = await fetchNftInfo(collectionAddr, nftId, chainId);

  dispatch(
    setNftInfo({
      data,
    })
  );
};

export const fetchMarketInfoAsync =
(chainId: string) =>
async (dispatch: any): Promise<void> => {
  const data = await fetchMarketGlobalInfo(chainId);

  dispatch(
    setMarketGlobalInfo({
      data,
    })
  );
};

export const fetchMarketNftsInfoAsync =
(nfts: [], collectionAddr: string) =>
async (dispatch: any): Promise<void> => {
  const data = await fetchMarketNftsInfo(nfts, collectionAddr);

  dispatch(
    setMarketNftsInfo({
      data,
    })
  );
};

export const fetchMarketTokenAllowanceInfoAsync =
(chainId: string, tokenAddress: string, account: string) =>
async (dispatch: any): Promise<void> => {
  const data = await fetchMarketTokenAllowance(chainId, tokenAddress, account);

  dispatch(
    setTokenAllowanceInfo({
      data,
    })
  );
};

export const fetchNftsInfoAsync =
(collectionAddr: string, nfts: any, chainId: string) =>
async (dispatch: any): Promise<void> => {
  const data = await fetchNftsInfo(collectionAddr, nfts, chainId);

  dispatch(
    setNftsInfo({
      data,
    })
  );
};

export default marketSlice.reducer;
