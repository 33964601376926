import { createSlice } from '@reduxjs/toolkit';
import { fetchNftInfo } from './fetchNftInfo';
import { fetchUpgradeInfo, fetchUserUpgradeInfo } from './fetchUpgradeInfo';

interface NftUpgradeState {
    currentNft: any;
    upgradeInfo: any;
    userInfo: any;
}

const initialState: NftUpgradeState = {
    currentNft : {},
    upgradeInfo: {},
    userInfo: {}
};

export const upgradeSlice = createSlice({
  name: 'upgrade',
  initialState,
  reducers: {
    reset: (state) => {
        state.currentNft = {}
        state.upgradeInfo = {}
        state.userInfo = {}
    },

    setNftInfo: (state, action) => {
        state.currentNft = action.payload.data;
    },
    setUpgradeInfo: (state, action) => {
      state.upgradeInfo = action.payload.data;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload.data;
    },
  },
});

export const { reset, setNftInfo, setUpgradeInfo, setUserInfo } =
    upgradeSlice.actions;

  // fetch global farm user info
export const fetchUpgradeNftInfoAsync =
(collectionAddr: string, nftId: number, chainId: string, uri: string) =>
async (dispatch: any): Promise<void> => {
  const data = await fetchNftInfo(collectionAddr, nftId, chainId, uri);

  dispatch(
    setNftInfo({
      data,
    })
  );
};

export const fetchUpgradeInfoAsync =
(collectionAddr: string, nftId: number, chainId: string, isDowngrade: boolean) =>
async (dispatch: any): Promise<void> => {
  const data = await fetchUpgradeInfo(collectionAddr, nftId, chainId, isDowngrade);

  dispatch(
    setUpgradeInfo({
      data,
    })
  );
};

export const fetchUserUpgradeInfoAsync =
(account: string,  upgradeInfo: any) =>
async (dispatch: any): Promise<void> => {
  const data = await fetchUserUpgradeInfo(account, upgradeInfo);

  dispatch(
    setUserInfo({
      data,
    })
  );
};

export default upgradeSlice.reducer;
