import { getUpgradeVariants } from "utils/nftUpgrade";
import GodNftAbi from 'config/abi/GodNft.json';
import multicall from 'utils/multicall';

export const fetchNftInfo = async (collectionAddr: string, nftId: number, chainId: string, uri: string) => {
    if (!collectionAddr) return { };
    
    const collections = getUpgradeVariants(Number(chainId));
    const collection = collections.filter((row: any) => Number(collectionAddr) === Number(row.address));

    try{
      if( collection.length  > 0 ){
          const tokenMetadata = await fetch(`${uri}${nftId}.json`).then((res) => res.json());

          return {
            tokenId: nftId,
            address: collectionAddr,
            image: tokenMetadata.image.replace('ipfs://', 'https://basedlabs.mypinata.cloud/ipfs/'),
            rank: tokenMetadata.rank || 0,
            meta: tokenMetadata,
          };
    }
    }
    catch(err){
      console.log(err)
    }

    return {

    };
  };