export enum NftCardCategory {
    INTERNAL,
    EXTERNAL
  }

  export interface NftMarketInfo{
    nftId: number,
    seller: string,
    paymentToken: string,
    value: number,
    expires: number
  }

  export interface NftDataInfo {
    tokenId: number,
    image: string,
    rank: number,
    meta: any,
    address: string,
    owner: '', // TODO OWner of,
    isStaked: boolean,
    marketData?: NftMarketInfo  
    stakeContract: string,
    stakeName: string
  }
  
  export interface NftSellInfo {
    tokenId: number
    value: number
    addressSeller: number;
    addressPaymentToken: number;
    expireTimestamp: number
  }

  export interface SupportedCollections{
    address: string;
    name: string;
    chainId: number;
    tokens: string[];
    img: string;
    collectionName: string;
  }
