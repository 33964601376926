import { getMasterChefAbi, getNftFarms } from 'utils/farms';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';
import GodNftAbi from 'config/abi/GodNft.json';
import multicall from 'utils/multicall';
import { BigNumber } from 'ethers';
import { FarmCategory } from 'types/farm';
import LpPairAbi from 'config/abi/LpPair.json';
import { getBalanceInEther } from 'utils';
import { NftDataInfo } from 'types/nft';

// single
export const fetchUserTokenDataSingle = async (account: string, chainId: string, farm: any) => {
  const { masterChefAddress } = farm;
  if (!masterChefAddress) return { ...farm };

  if( farm.isFake ) {
    return { ...farm };
  }
  const { name, stakingToken, rewardToken, rewardTokenDecimal, receiptToken } = farm;

  // fetch staking token balance and allowance
  const calls1 = [
    {
      address: stakingToken,
      name: 'isApprovedForAll',
      params: [account, masterChefAddress],
    },
    {
      address: stakingToken,
      name: 'balanceOf',
      params: [account],
    },
  ];

  const [stakingTokenApproved, balanceRaw] = await multicall(GodNftAbi, calls1);

  // fetch user nft IDs
  const nftCnt = balanceRaw[0].toNumber();
  const calls2 = Array.from(Array(nftCnt).keys()).map((idx) => ({
    address: stakingToken,
    name: 'tokenOfOwnerByIndex',
    params: [account, idx],
  }));

  const nftIdsRaw = await multicall(GodNftAbi, calls2);
  const nftIds = nftIdsRaw.map((row: any) => row[0].toNumber());

  const calls3 = nftIds.map((nftId: number) => ({
    nftId,
    address: stakingToken,
    name: 'tokenURI',
    params: [nftId],
  }));

  const tokenUris = await multicall(GodNftAbi, calls3);
  const tokenMetadataArray = await Promise.all(
    tokenUris.map(async (tokenUri: string) => {
      const tokenMetadata = await fetch(`${tokenUri}.json`).then((res) => res.json());
      return {
        image: tokenMetadata.image.replace('ipfs://', 'https://basedlabs.mypinata.cloud/ipfs/'),
        rank: tokenMetadata.rank || 99999,
        meta: tokenMetadata,
      };
    })
  );

  const userNfts = nftIds.map((nftId: number, i: number) => ({
    tokenId: nftId,
    image: tokenMetadataArray[i].image,
    rank: tokenMetadataArray[i].rank,
    meta: tokenMetadataArray[i].meta,
    address: stakingToken,
    owner: account, // TODO OWner of,
    isStaked: false,
    marketData: undefined,
    stakeContract: masterChefAddress,
    stakeName: "Smelt Reward Pool"
  }));

  let userReceiptTokenAllowance = BigNumber.from(0);
  if (farm.farmType === 'smeltRewardPool' && farm.category === FarmCategory.ACTIVE) {
    // fetch receipt token balance and allowance
    const calls4 = [
      {
        address: receiptToken,
        name: 'allowance',
        params: [account, masterChefAddress],
      },
    ];

    const [[receiptTokenAllowanceRaw]] = await multicall(LpPairAbi, calls4);
    userReceiptTokenAllowance = receiptTokenAllowanceRaw;
    console.log(`Allowance 1 = ${getBalanceInEther(userReceiptTokenAllowance)}`)

  }

  return {
    name,
    userStakingTokenApproved: stakingTokenApproved[0],
    userStakingTokenBalance: balanceRaw[0].toNumber(),
    userNfts,
    rewardToken,
    rewardTokenDecimal,
    userReceiptTokenAllowance
  };
};

// multiple
export const fetchUserTokenData = async (account: string, chainId: string) => {
  const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
  const farms = getNftFarms(selectedChainid);
  
  try {
    const tokenData = await Promise.all(
      farms.map(async (farm) => {
        const { masterChefAddress } = farm;
        if (!masterChefAddress) return { ...farm };

        if( farm.isFake ) {
          return { ...farm };
        }
        const { name, stakingToken, rewardToken, rewardTokenDecimal, receiptToken } = farm;

        // fetch staking token balance and allowance
        const calls1 = [
          {
            address: stakingToken,
            name: 'isApprovedForAll',
            params: [account, masterChefAddress],
          },
          {
            address: stakingToken,
            name: 'balanceOf',
            params: [account],
          },
        ];

        const [stakingTokenApproved, balanceRaw] = await multicall(GodNftAbi, calls1);
        // fetch user nft IDs
        const nftCnt = balanceRaw[0].toNumber();
        const calls2 = Array.from(Array(nftCnt).keys()).map((idx) => ({
          address: stakingToken,
          name: 'tokenOfOwnerByIndex',
          params: [account, idx],
        }));

        const nftIdsRaw = await multicall(GodNftAbi, calls2);
        const nftIds = nftIdsRaw.map((row: any) => row[0].toNumber());
        // nftIds = [];

        // for( let i = 1; i < 700; i+=1 ){
        //   nftIds.push(i);
        // }
        // console.log(nftIds)

        const calls3 = nftIds.map((nftId: number) => ({
          nftId,
          address: stakingToken,
          name: 'tokenURI',
          params: [nftId],
        }));

        const tokenUris = await multicall(GodNftAbi, calls3);
        const tokenMetadataArray = await Promise.all(
          tokenUris.map(async (tokenUri: string) => {
            const tokenMetadata = await fetch(`${tokenUri}.json`).then((res) => res.json());

            return {
              image: tokenMetadata.image.replace('ipfs://', 'https://basedlabs.mypinata.cloud/ipfs/'),
              rank: tokenMetadata.rank || 0,
              meta: tokenMetadata,
            };
          })
        );

        const userNfts : NftDataInfo = nftIds.map((nftId: number, i: number) => ({
          tokenId: nftId,
          image: tokenMetadataArray[i].image,
          rank: tokenMetadataArray[i].rank,
          meta: tokenMetadataArray[i].meta,
          address: stakingToken,
          owner: account, // TODO OWner of,
          isStaked: false,
          marketData: undefined,
          stakeContract: masterChefAddress,
          stakeName: "Smelt Reward Pool"
        }));

        let userReceiptTokenAllowance = BigNumber.from(0);
        if(receiptToken.length > 0){
          if (farm.farmType === 'smeltRewardPool' && farm.category === FarmCategory.ACTIVE) {
            // fetch receipt token balance and allowance
            const calls4 = [
              {
                address: receiptToken,
                name: 'allowance',
                params: [account, masterChefAddress],
              },
            ];
    
            const [[receiptTokenAllowanceRaw]] = await multicall(LpPairAbi, calls4);
            userReceiptTokenAllowance = receiptTokenAllowanceRaw;
            console.log(`Allowance = ${getBalanceInEther(userReceiptTokenAllowance)}`)
          }
  
        }

        return {
          name,
          userStakingTokenApproved: stakingTokenApproved[0],
          userStakingTokenBalance: balanceRaw[0].toNumber(),
          userNfts,
          rewardToken,
          rewardTokenDecimal,
          userReceiptTokenAllowance,
        };
      })
    );

    return tokenData;
  } catch (error: any) {
    console.log(error)
    return [];
  }
};

// single
export const fetchUserFarmDataSingle = async (account: string, chainId: string, farm: any) => {
  const { masterChefAddress } = farm;
  if (!masterChefAddress) return { ...farm };

  if( farm.isFake || farm.category === FarmCategory.INVISIBLE ) {
    return { ...farm };
  }
  
  const { name, poolId, farmType, stakingToken, rewardToken, rewardTokenDecimal, category } = farm;

  // fetch staked amount and reward
  const calls = [
    {
      address: masterChefAddress,
      name: 'userInfo',
      params: [poolId, account],
    },
    {
      address: masterChefAddress,
      name: 'pendingSMELTNft',
      params: [poolId, account],
    },
    {
      address: masterChefAddress,
      name: 'getStakedNfts',
      params: [account],
    },
  ];

  const [userInfo, pendingRewardRaw, stakedNftsRaw] = await multicall(getMasterChefAbi(farmType, category, farm.masterChefAddress), calls);
  let stakedNfts = stakedNftsRaw[0].map((row: any) => row.toNumber());

  console.log(stakedNfts)
  const stakedNftsOwnerRecheck = []
  if( poolId === 0 || poolId === 5 || poolId === 2 || poolId === 3 ){
    try{
      const calls2 = stakedNfts.map((nftId: number) => ({
        nftId,
        address: stakingToken,
        name: 'ownerOf',
        params: [nftId],
      }));
      const tokensOwner = await multicall(GodNftAbi, calls2);

      for( let i = 0; i < tokensOwner.length; i+=1 ){
        if( tokensOwner[i][0] === farm.masterChefAddress ){
          stakedNftsOwnerRecheck.push(stakedNfts[i]);
        }
      }
    }
    catch(err){
      // 
      
      for( let k = 0; k < stakedNfts.length; k+=1 ){
        try{
          const ownerCall = [
            {
              address: stakingToken,
              name: 'ownerOf',
              params: [stakedNfts[k]],
            }
          ];
          const [owner] = await multicall(GodNftAbi, ownerCall);
          if( owner[0] === farm.masterChefAddress) {
            stakedNftsOwnerRecheck.push(stakedNfts[k]);
          }
        }
        catch(error){
          console.log("Can't check owner")
        }
      }
    }
  }
  if(stakedNftsOwnerRecheck.length === 0 ){
    return{ ...farm };
  }
  stakedNfts = stakedNftsOwnerRecheck.map((row: number) => row);

  const calls1 = stakedNfts.map((nftId: number) => ({
    nftId,
    address: stakingToken,
    name: 'tokenURI',
    params: [nftId],
  }));

  const tokenUris = await multicall(GodNftAbi, calls1);
  const tokenImages = await Promise.all(
    tokenUris.map(async (tokenUri: string) => {
      const tokenMetadata = await fetch(`${tokenUri}.json`).then((res) => res.json());
      return tokenMetadata.image.replace('ipfs://', 'https://basedlabs.mypinata.cloud/ipfs/');
    })
  );

  const tokenMetadataArray = await Promise.all(
    tokenUris.map(async (tokenUri: string) => {
      const tokenMetadata = await fetch(`${tokenUri}.json`).then((res) => res.json());

      return {
        image: tokenMetadata.image.replace('ipfs://', 'https://basedlabs.mypinata.cloud/ipfs/'),
        rank: tokenMetadata.rank || 0,
        meta: tokenMetadata,
      };
    })
  );

  const userStakedNfts : NftDataInfo = stakedNfts.map((nftId: number, i: number) => ({
    tokenId: nftId,
    image: tokenImages[i],
    rank: tokenMetadataArray[i].rank,
    meta: tokenMetadataArray[i].meta,
    address: stakingToken,
    owner: masterChefAddress, // TODO OWner of,
    isStaked: true,
    marketData: undefined,
    stakeContract: masterChefAddress,
    stakeName: "Smelt Reward Pool"
  }));

  return {
    name,
    stakedBalance: userInfo.amount,
    stakedNfts: userStakedNfts,
    rewardTokenBalance: pendingRewardRaw[0],
    rewardToken,
    rewardTokenDecimal,
  };
};

// multiple
export const fetchUserFarmData = async (account: string, chainId: string) => {
  const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
  const farms = getNftFarms(selectedChainid);

  const farmData = await Promise.all(
    farms.map(async (farm) => {
      const { masterChefAddress } = farm;
      if (!masterChefAddress) return { ...farm };

      if( farm.isFake || farm.category === FarmCategory.INVISIBLE ) {
        return{ ...farm };
      }
      const { name, poolId, farmType, stakingToken, rewardToken, rewardTokenDecimal, category } = farm;

      try{
        // fetch staked amount and reward
        const calls = [
          {
            address: masterChefAddress,
            name: 'userInfo',
            params: [poolId, account],
          },
          {
            address: masterChefAddress,
            name: 'pendingSMELTNft',
            params: [poolId, account],
          },
          {
            address: masterChefAddress,
            name: 'getStakedNfts',
            params: [account],
          },
        ];
  
        const [userInfo, pendingRewardRaw, stakedNftsRaw] = await multicall(getMasterChefAbi(farmType, category, farm.masterChefAddress), calls);
        let stakedNfts = stakedNftsRaw[0].map((row: any) => row.toNumber());
        const stakedNftsOwnerRecheck = []
        if( poolId === 0 || poolId === 5 || poolId === 2 || poolId === 3 ){
          try{
            const calls2 = stakedNfts.map((nftId: number) => ({
              nftId,
              address: stakingToken,
              name: 'ownerOf',
              params: [nftId],
            }));
            const tokensOwner = await multicall(GodNftAbi, calls2);

            for( let i = 0; i < tokensOwner.length; i+=1 ){
              if( tokensOwner[i][0] === farm.masterChefAddress ){
                stakedNftsOwnerRecheck.push(stakedNfts[i]);
              }
            }
          }
          catch(err){
            // 
            for( let k = 0; k < stakedNfts.length; k+=1 ){
              try{
                const ownerCall = [
                  {
                    address: stakingToken,
                    name: 'ownerOf',
                    params: [stakedNfts[k]],
                  }
                ];
            
                const [owner] = await multicall(GodNftAbi, ownerCall);
                if( owner[0] === farm.masterChefAddress) {
                  stakedNftsOwnerRecheck.push(stakedNfts[k]);
                }
              }
              catch(error){
                console.log("Can't check owner")
              }
            }
          }
        }
        if(stakedNftsOwnerRecheck.length === 0 ){
          return{ ...farm };
        }
        stakedNfts = stakedNftsOwnerRecheck.map((row: number) => row);

        const calls1 = stakedNfts.map((nftId: number) => ({
          nftId,
          address: stakingToken,
          name: 'tokenURI',
          params: [nftId],
        }));
  
        const tokenUris = await multicall(GodNftAbi, calls1);
        const tokenImages = await Promise.all(
          tokenUris.map(async (tokenUri: string) => {
            const tokenMetadata = await fetch(`${tokenUri}.json`).then((res) => res.json());
            return tokenMetadata.image.replace('ipfs://', 'https://basedlabs.mypinata.cloud/ipfs/');
          })
        );
   
        const tokenMetadataArray = await Promise.all(
          tokenUris.map(async (tokenUri: string) => {
            const tokenMetadata = await fetch(`${tokenUri}.json`).then((res) => res.json());
      
            return {
              image: tokenMetadata.image.replace('ipfs://', 'https://basedlabs.mypinata.cloud/ipfs/'),
              rank: tokenMetadata.rank || 99999,
              meta: tokenMetadata,
            };
          })
        );
        
        const userStakedNfts = stakedNfts.map((nftId: number, i: number) => ({
          tokenId: nftId,
          image: tokenImages[i],
          rank: tokenMetadataArray[i].rank,
          meta: tokenMetadataArray[i].meta,
          address: stakingToken,
          owner: masterChefAddress, // TODO OWner of,
          isStaked: true,
          stakeContract: masterChefAddress,
          stakeName: "Smelt Reward Pool"
        }));
  
        return {
          name,
          stakedBalance: userInfo.amount,
          stakedNfts: userStakedNfts,
          rewardTokenBalance: pendingRewardRaw[0],
          rewardToken,
          rewardTokenDecimal,
        };
      }
      catch(err){
        console.log(farm)
        console.log(err)
      }
      return {
        name,
        stakedBalance: 0,
        stakedNfts: [],
        rewardTokenBalance: 0,
        rewardToken,
        rewardTokenDecimal,
      }
    })
  );

  return farmData;
};

export const fetchFarmUserData = async (account: string, chainId: string) => {
  try{
    const tokenData = await fetchUserTokenData(account, chainId);
    const farmData = await fetchUserFarmData(account, chainId);
    const data = tokenData.map((row: any, index: number) => ({
      ...row,
      ...farmData[index],
    }));
    return data;
  }
  catch(err){
    console.log(err);
  }
  return [];
};
