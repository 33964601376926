import { FC, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Grid, IconButton, Link, styled, Typography } from '@mui/material';
import { icons, tokens } from 'config/constants/assets';
import { TokenSelectInput } from 'components/common/TokenSelectCard/TokenSelectInput';
import DataSelect from 'components/common/Select/DataSelect';
import { ConnectWallet, ContainedButton } from 'components/common/Button';
import { useWeb3React } from '@web3-react/core';
import CountDownTimer from 'components/common/Countdown/CountDownTimer';
import { useMarketpalce } from 'hooks/useMarketplace';
import { NftDataInfo, SupportedCollections } from 'types/nft';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { getMarketCollectionInfo } from 'utils/market';
import { useNotification } from 'hooks/useNotification';
import { fetchMarketInfoAsync, fetchMarketTokenAllowanceInfoAsync } from 'state/actions';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '12px 5px 12px',
  borderRadius: '10px',
  background: 'rgb(6,1,1,0.3)',
  position: 'relative'
}));

// nft list title
const SellTitle = styled(Box)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  color: '#CDC392',
  fontSize: '18px',
  textAlign: 'left',
}));

const BasicText = styled(Box)(() => ({
    fontFamily: 'Prompt',
    fontStyle: 'normal',
    fontWeight: '300',
    color: 'white',
    fontSize: '15px',
    textAlign: 'left',
}));

const Header = styled(Box)(({ theme }) => ({
    display: 'flex', 
    // background: 'red',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.up('xs')]: {
        minHeight: '70px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '50px',
      },
}));

const ContentBox = styled(Box)(({ theme }) => ({
    display: 'flex', 
    alignItems: 'center',
    // flexDirection: 'column',
    background: 'rgb(6,1,1,0.3)',
    borderRadius: '10px',
    [theme.breakpoints.up('xs')]: {
        minHeight: '70px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '50px',
      },
}));

const ContentLeft = styled(Box)(({ theme }) => ({
    display: 'flex', 
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
        minHeight: '70px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '50px',
      },
}));

const ContentRight = styled(Box)(({ theme }) => ({
    display: 'flex', 
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up('xs')]: {
        minHeight: '70px',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '50px',
      },
}));

// toggle section
const ToggleSection = styled(Box)(() => ({
    position: 'absolute',
    right: '-7px',
    top: 'calc(50%px - 10px)',
    background: '#2A3139',
    boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: '28px',
    height: '28px',
  }));

const StyledTokenInput = styled('div')(({ theme }) => ({
    maxWidth: '100%',
    width: '100%',
    border: '1px solid grey',
    borderRadius: '10px',
    backgroundColor: 'transparent'
}));

const TokenImgSection = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  }));

  const ActionButton = styled(ContainedButton)(() => ({
    // maxWidth: '102px',
    height: '40px',
  }));
  
  const TokenImg = styled('img')(({ theme }) => ({
      height: '25px',
      width: '25px',
  }));

  const ToggleIcon = styled('img')(() => ({}));

interface Props {
    data: NftDataInfo
    marketData: any
    onHideCard: () => void
}

const BuyBox: FC<Props> = ({ data , marketData, onHideCard}) => {

  const [loaded, setLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const ref = useRef<HTMLImageElement>(null)
  const [isExpanded, setExpanded] = useState<boolean>(false);
  const [amount, setAmount] = useState('');
  const [periodSelect, setPeriodSelect] = useState<any>('1');
  const [isPending, setPending] = useState<boolean>(false);
  const { account } = useWeb3React();
  const { selectedChainId } = useAppSelector((state) => state.chain);
  const { tokenAllowance } = useAppSelector((state) => state.market);

  const collection : SupportedCollections | undefined = getMarketCollectionInfo(Number(selectedChainId), data?.address);
  const { onBuy, onApprovePaymentToken, onDelist } = useMarketpalce(Number(selectedChainId), collection?.address ? collection.address : "",  marketData ? marketData?.paymentToken : '' );
  const [isLoadingTx, setLoading] = useState<boolean>(false);
  const { onShowNotification } = useNotification();
  const { pendingTxHash } = useAppSelector((state) => state.modal);
  const pendingTxHashRef: { current: string | undefined } = useRef();
  pendingTxHashRef.current = pendingTxHash;
  const dispatch = useAppDispatch();

  // TODO PERCENT
  const isApproved = tokenAllowance < (marketData ? marketData?.value  : 0 ) ? false : tokenAllowance
  const imageLoaded = () => {
    setIsLoading(false);
  }
  const onToggle = () => {
    setExpanded(!isExpanded);
  };
  const truncate = (str: string, n: number) => {
    return str?.length > n ? `${str.substr(0, n ) } ... ${str.substr(str.length - n, n) }`: str;
    };
    const changeValue = (val: string) => {
        setAmount((val));
    }

    const onChangePeriod = (option: any) => {
        // if (Number(selectedChainId) === Number(option.chainId)) return;
        setPeriodSelect(option);
    };

    useEffect(() => {
      if( !marketData || !account )
        return;
      dispatch(fetchMarketTokenAllowanceInfoAsync(selectedChainId, marketData.paymentToken, account))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, account]);

    const onConfirm = async () => {
      setLoading(true);
      if (!isApproved) {
        // 1. Approve staking token logic
        try {
          await onApprovePaymentToken();
          onShowNotification({
            title: 'Transaction Success',
            description: 'Approve Token',
            hasView: true,
            txHash: pendingTxHashRef.current,
          });
        } catch (err) {
          onShowNotification({ title: 'Transaction Failed', description: 'Approve Token' });
          console.log('Approve NFT Token Error: ', err);
        }
      } else {
        try {
          if( collection && marketData ){
            if( collection.tokens.length > 0 ){
              await onBuy(marketData.nftId, marketData.expires, marketData.value);
              dispatch(fetchMarketInfoAsync(selectedChainId));
              onHideCard();
              onShowNotification({
                title: 'Transaction Success',
                description: 'Buy Token',
                hasView: true,
                txHash: pendingTxHashRef.current,
              });
            }
          }
        } catch (err) {
          onShowNotification({ title: 'Transaction Failed', description: 'Approve Token' });
          console.log('Approve NFT Token Error: ', err);
        }
      }
      setLoading(false);
    };

    const onDelistClicked = async () => {
      setLoading(true);
    
        try {
          if( collection && marketData ){
            if( collection.tokens.length > 0 ){
              await onDelist(marketData.nftId);
              dispatch(fetchMarketInfoAsync(selectedChainId));
              onHideCard();
              onShowNotification({
                title: 'Transaction Success',
                description: 'Delist Token',
                hasView: true,
                txHash: pendingTxHashRef.current,
              });
            }
          }
        } catch (err) {
          onShowNotification({ title: 'Transaction Failed', description: 'Delist Token' });
          console.log('Delist NFT Token Error: ', err);
        }
      setLoading(false);
    };

    const getButtonText = () => {
      if(!account){
        return 'Connect';
      }
      if (!isApproved) {
        return 'Approve';
      }
      return 'Buy';
    };

  return (
    <>
        <Container mt={1}>
            <Box display='flex' justifyContent='center' alignItems='center' textAlign='center' sx={{border:'0px solid blue'}}>
                <CountDownTimer  targetDate={(marketData ? marketData.expires : 0) * 1000}/>
            </Box>
        </Container>
        <Container mt={1}>
            <BasicText ml={1}>
                Price: {marketData?.value} Obol
            </BasicText>
        </Container>
        <Box mt={1} display='flex'>
            {account && marketData?.seller !== account ? (
                <ActionButton size="small" color="primary" variant="contained" onClick={onConfirm} 
                    style={{marginRight: '10px', width: '100%'}}>
                {isLoadingTx ? <CircularProgress size={20} sx={{ color: 'black' }} /> : getButtonText()}
                </ActionButton>
              ) : (
                <Box display='flex' justifyContent='center' sx={{width: '100%', display:marketData?.seller !== account ? '' : 'none'}}>
                    <ConnectWallet  width={100} />
                </Box>
              )}
              {account && marketData?.seller === account &&
                <ActionButton size="small" color="primary" variant="contained" onClick={onDelistClicked} 
                    style={{marginRight: '0px', width: '100%'}}>
                {isLoadingTx ? <CircularProgress size={20} sx={{ color: 'black' }} /> : 'Delist'}
                </ActionButton>
              }
        </Box>
    </>
    // sx={{borderBottom: '1px solid grey', paddingBottom: '10px'}}
  );
};

export default BuyBox;
