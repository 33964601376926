import {DEFAULT_ACTIVE_CHAIN_ID} from "config/constants";
import {getBridgeInfo, getBridgeNftInfo} from "utils/bridge";

export const fetchBridgeInfo = async (chainId: string): Promise<any> => {
    const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
    const bridgeInfo = getBridgeInfo(selectedChainid);
    try {
        if( bridgeInfo.length > 0 ){
            const chainInfo = bridgeInfo[0];
            return {data: chainInfo};
        }
    } catch (error: any) {
        return {
            data: [],
        };
    }
    return {
        data: [],
    };
};

export const fetchBridgeNftInfo = async (chainId: string): Promise<any> => {
    const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
    const bridgeInfo = getBridgeNftInfo(selectedChainid);

    try {
        if( bridgeInfo.length > 0 ){
            const chainInfo = bridgeInfo[0];
            return {data: chainInfo};
        }
    } catch (error: any) {
        return {
            data: [],
        };
    }
    return {
        data: [],
    };
};