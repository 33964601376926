import { FC } from 'react';
import { Box, styled, Typography } from '@mui/material';

import { useAppSelector } from 'state/hooks';
import { chains, images } from 'config/constants/assets';
import useGlobalInfo from 'hooks/useGlobalInfo';

// tvl box
const TvlBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '218px',
  minWidth: '218px',
  borderRadius: '8px',
  background: theme.palette.background.box,
  padding: '20px 16px',

  [theme.breakpoints.up('xs')]: {
    width: '100%',
    padding: '16px 16px 12px',
  },
  [theme.breakpoints.up('md')]: {
    width: '218px',
    padding: '20px 16px',
  },
}));
const ChainLogo = styled('img')(({ theme }) => ({
  position: 'absolute',
  width: '48px',
  borderRadius: '50%',

  [theme.breakpoints.up('xs')]: {
    width: '40px',
  },
  [theme.breakpoints.up('md')]: {
    width: '48px',
  },
}));

const TvlImg = styled('img')(({ theme }) => ({
  position: 'absolute',
  marginBottom: '-8px',
  marginTop: '20px',
  [theme.breakpoints.up('xs')]: {
    width: 'calc(100% - 32px)',
    height: '94px',
    marginTop: '4px',
  },
  [theme.breakpoints.up('md')]: {
    marginTop: '20px',
  },
}));

const TvlText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  color: theme.palette.text.primary,
  fontSize: '19px',
  lineHeight: '20px',

  [theme.breakpoints.up('xs')]: {
    fontSize: '19px',
    lineHeight: '20px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
    lineHeight: '20px',
  },
}));

const TvlValue = styled(Box)(({ theme }) => ({
  marginTop: '9px',
  marginBottom: '4px',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '600',
  color: theme.palette.text.primary,
  fontSize: '40px',
  lineHeight: '48px',
  textAlign: 'center',

  span: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '400',
    color: theme.palette.text.primary,
    fontSize: '19px',
    lineHeight: '23px',
  },

  [theme.breakpoints.up('xs')]: {
    fontSize: '32px',
    lineHeight: '40px',
    marginBottom: '0px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '32px',
    lineHeight: '48px',
    marginBottom: '4px',
  },
}));

const TvlCard: FC = () => {
  const { selectedChainId } = useAppSelector((state) => state.chain);

  const { globalTvl } = useGlobalInfo();

  // get chain logo
  const getTvlChainLogo = () => {
    if (Number(selectedChainId) === 250) return chains.fantom1;
    if (Number(selectedChainId) === 137) return chains.polygon2;
    if (Number(selectedChainId) === 43114) return chains.avalanche;
    if (Number(selectedChainId) === 8453) return chains.base;
    if (Number(selectedChainId) === 324 ) return chains.zkSync;
    return chains.fantom1;
  };

  return (
    <TvlBox>
      <ChainLogo alt="chain" src={getTvlChainLogo()} />
      <TvlImg alt="tvl" src={images.overviewTvl} />
      {/* <Box sx={{ paddingTop: '80%' }}> */}
      <Box sx={{ paddingTop: '54px' }}>
        <TvlText textAlign="center">Total value locked</TvlText>
        <TvlText textAlign="center">Based Finance </TvlText>
      </Box>
      <TvlValue>
        {/* <span>$</span> */}
        {/* {`${Number(globalTvl.toFixed(0)).toLocaleString()} `} */}
        ---
      </TvlValue>
    </TvlBox>
  );
};

export default TvlCard;
