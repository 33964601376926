/* eslint-disable no-underscore-dangle */
import multicall, { multicallNative} from 'utils/multicall';
import OBOLTokenAbi from 'config/abi/OBOL.json';
import { getBalanceInEther } from 'utils/formatBalance';
import { getTokens } from 'utils/tokens';
import { getTokensInfo } from 'utils/backend';
import { getBoilerRoomAddress, getTeamTreasuryAddress } from 'utils/addressHelpers';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';
import { getTokenPriceFromCGC } from 'utils/coingecko';
import { getTokenPriceFromDexTools } from 'utils/dextools';

const TAX_RATIO_DENOMINATOR = 10000;

export const fetchGlobalTokenData = async (chainId: string): Promise<any> => {

  try {
    const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
    const tokens = getTokens(selectedChainid);
    const treasuryAddr = getTeamTreasuryAddress(selectedChainid);
    const boilerRoomAddr = getBoilerRoomAddress(selectedChainid);
    let circulatingSupply = 0;
    
    const offchainData = await getTokensInfo({});
    const onchainData = await Promise.all(
      tokens.map(async (token: any) => {
        const tokenAddr = token.address;
        let buyTax;
        let sellTax;
        let burntAmnt = 0;

        const [totalSupplyRaw] = await multicall(OBOLTokenAbi, [
          {
            address: tokenAddr,
            name: 'totalSupply',
            params: [],
          },
        ]);

        let totalSupply = getBalanceInEther(totalSupplyRaw[0]);

        if (treasuryAddr) {
          const [burntAmntRaw1] = await multicall(OBOLTokenAbi, [
            {
              address: tokenAddr,
              name: 'balanceOf',
              params: [treasuryAddr],
            },
          ]);

          burntAmnt += getBalanceInEther(burntAmntRaw1);
        }

        if (boilerRoomAddr) {
          const [burntAmntRaw2] = await multicall(OBOLTokenAbi, [
            {
              address: tokenAddr,
              name: 'balanceOf',
              params: [boilerRoomAddr],
            },
          ]);

          burntAmnt += getBalanceInEther(burntAmntRaw2);
        }

        if (token.hasTax) {
          if( token.sellTax || token.buyTax ){
            buyTax = token.buyTax;
            sellTax = token.sellTax;
          }
          else{
            const [buyTaxRaw, sellTaxRaw] = await multicall(OBOLTokenAbi, [
              {
                address: tokenAddr,
                name: 'buyFee',
                params: [],
              },
              {
                address: tokenAddr,
                name: 'sellFee',
                params: [],
              },
            ]);
            buyTax = buyTaxRaw[0].toNumber() / TAX_RATIO_DENOMINATOR;
            sellTax = sellTaxRaw[0].toNumber() / TAX_RATIO_DENOMINATOR;
          }
        }

        const tokenOffChainInfo = offchainData.find((row: any) =>
          token.symbol === 'BBOND'
            ? row?.symbol === 'BASED'
            : row?.tokenAddress.toLowerCase() === token?.address.toLowerCase()
        );

        //  TODO Fetch addresses from correct places.

        if( token.symbol === 'OBOL' || token.symbol === 'SMELT'){
          const [boilerSupplyRaw] = await multicall(OBOLTokenAbi, [
            {
              address: tokenAddr,
              name: 'balanceOf',
              params: ["0x6bB173673cc128Aa8709B2Aa8108f6f9521AE0A0"],
            },
          ]);
          const boilerSupply = getBalanceInEther(boilerSupplyRaw);
          const [fundSupplyRaw] = await multicall(OBOLTokenAbi, [
            {
              address: tokenAddr,
              name: 'balanceOf',
              params: ["0x0A10daD90b9C6FB8B87BFf3857A4B012890C53A5"],
            },
          ]);
          const fundSupply = getBalanceInEther(fundSupplyRaw);

          if( token.symbol === 'SMELT' ){
            const [rewardPoolSupplyRaw] = await multicall(OBOLTokenAbi, [
              {
                address: tokenAddr,
                name: 'balanceOf',
                params: ["0x7A1f47c8a26fD895228947ffc0482F3dD9c2cA29"],
              },
            ]);
            const rewardPoolSupply = getBalanceInEther(rewardPoolSupplyRaw);
            circulatingSupply = totalSupply - boilerSupply - rewardPoolSupply;
          }
          else {
            circulatingSupply = totalSupply - boilerSupply - fundSupply;
          }
        }
        else{
          circulatingSupply = totalSupply;
        }

        let tokenPriceUsd = -1;

        // if( token.cgcId  ){
        //   const tokenPricesRaw = await getTokenPriceFromCGC(token.cgcId);
        //   let tokenPrices2: any = {};
        //   Object.entries(tokenPricesRaw)
        //     .map((row) => ({ [row[0]]: row[1].usd }))
        //     .map((row) => {
        //       tokenPrices2 = { ...tokenPrices2, ...row };
        //       return row;
        //     });
        //   tokenPriceUsd = tokenPrices2[token.cgcId];
        // }
        
        if( token.dextoolsPair ) {
          try{
            const res = await getTokenPriceFromDexTools(Number(chainId), token.dextoolsPair);
            tokenPriceUsd = res;
          }
          catch( err ){
            // 
          }
        }
        if( token.nativeTokenAddress ){
          try{
            const [totalSupplyNativeRaw] = await multicallNative(OBOLTokenAbi, [
              {
                address: token.nativeTokenAddress,
                name: 'totalSupply',
                params: [],
              },
            ]);
            totalSupply = getBalanceInEther(totalSupplyNativeRaw[0]);
          }
          catch(err){
            // 
            console.log(err)
          }
        }

        return {
          ...token,
          buyTax,
          sellTax,
          priceInUsd: tokenOffChainInfo ? tokenOffChainInfo.priceUSD || tokenPriceUsd : tokenPriceUsd,
          priceInFtm: tokenOffChainInfo ? tokenOffChainInfo?.priceInWFTM || -1 : -1,
          circulationSupply: circulatingSupply,
          totalSupply,
          burntAmnt,
        };
      })
    );
    return {
      data: onchainData,
    };
  } catch (error: any) {
    console.log(error)
    return {
      data: [],
    };
  }
};
