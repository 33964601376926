import React from 'react';

const DateTimeDisplay = ({value, type, isDanger}) => {
    return (
      <div className={isDanger ? 'countdown danger' : 'countdown'} style={{display: 'inline-block'}}>
        <p style={{display: 'inline-block'}}>{value}</p>
        <span>{type}</span>
      </div>
    );
  };
  
  export default DateTimeDisplay;