import { useEffect, useState } from 'react';

import { getTokens } from 'utils/tokens';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';
import { useAppSelector } from 'state/hooks';
import useTokenPrice from 'hooks/useTokenPrice';
import { FarmCategory } from 'types/farm';

const useGlobalInfo = (): { globalTvl: number } => {
  const [globalTvl, setGlobalTvl] = useState<number>(0);

  const { data: farmList } = useAppSelector((state) => state.farms);
  const { data: nftFarmList } = useAppSelector((state) => state.nftFarms);
  const { data: stakingPoolList } = useAppSelector((state) => state.stakingPools);
  const { data: tokenList } = useAppSelector((state) => state.tokens);
  const { data: twistedNodesList } = useAppSelector((state) => state.twistedNodes);
  const { tokenPrices } = useTokenPrice();
  const tokens = getTokens(DEFAULT_ACTIVE_CHAIN_ID);

  const getTokenPrice = (tokenAddr: string) => {
    const tokenInfo = tokenList.find((row) => row.address.toLowerCase() === tokenAddr.toLowerCase());
    if (tokenInfo) return tokenInfo.priceInUsd || 0;
    return 0;
  };

  // get farms tvl
  const getFarmsTvl = (): number => {
    let farmsTvl = 0;
    farmList.map((farm: any) => {
      if (farm.category === FarmCategory.ACTIVE) {
        if (farm.stakingToken0 === '0x0000000000000000000000000000000000000000') {
          farmsTvl += getTokenPrice(farm.stakingToken) * farm.tvl;
        } else {
          farmsTvl += 2 * getTokenPrice(farm.stakingToken1) * farm.token1Amount;
        }
      }
      return farm;
    });

    return farmsTvl || 0;
  };

  // get nft farms tvl
  const getNftFarmsTvl = (): number => {
    let nftFarmsTvl = 0;
    nftFarmList.map((farm: any) => {
      const nftPrice = 500;
      nftFarmsTvl += nftPrice * farm.tvl;

      return farm;
    });

    return nftFarmsTvl || 0;
  };

  // get pools tvl
  const getStakingPoolsTvl = (): number => {
    let stakingPoolsTvl = 0;
    stakingPoolList.map((stakingPool: any) => {
      stakingPoolsTvl += getTokenPrice(stakingPool.stakingToken) * stakingPool.tvl;

      return stakingPool;
    });

    return stakingPoolsTvl || 0;
  };

  // get twisted node tvl
  const getTwistedNodesTvl = (): number => {
    let nodesTvl = 0;
    twistedNodesList.map((node: any) => {
      nodesTvl += getTokenPrice(node.token) * node.tvl;

      return node;
    });

    return nodesTvl || 0;
  };

  // get overall tvl
  const fetchOverallTvl = () => {
    const farmsTvl = getFarmsTvl();
    const nftFarmsTvl = getNftFarmsTvl();
    const stakingPoolsTvl = getStakingPoolsTvl();
    const twistedNodesTvl = getTwistedNodesTvl();
    setGlobalTvl(farmsTvl + nftFarmsTvl + stakingPoolsTvl + twistedNodesTvl);

    // return farmsTvl + nftFarmsTvl + stakingPoolsTvl + twistedNodesTvl;
  };

  useEffect(() => {
    // Only for Fantom
    if( farmList.length && nftFarmList.length && twistedNodesList.length && tokenList.length )
      fetchOverallTvl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    farmList.length,
    nftFarmList.length,
    stakingPoolList.length,
    tokenList.length,
    twistedNodesList.length,
    tokenPrices,
  ]);

  useEffect(() => {
    fetchOverallTvl();

    setTimeout(() => {
      fetchOverallTvl();
    }, 100000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log('===== TVL info =====');
  // console.log('Global TVL: ', globalTvl);
  // console.log('Farms TVL: ', getFarmsTvl());
  // console.log('NFT Farms TVL: ', getNftFarmsTvl());
  // console.log('Staking Pools TVL: ', getStakingPoolsTvl());
  // console.log('Twisted Nodes TVL: ', getTwistedNodesTvl());
  // console.log('==========');
  // console.log('');

  return { globalTvl };
};

export default useGlobalInfo;
