import { createSlice } from '@reduxjs/toolkit';
import {BridgeInfo} from "types/bridge";
import {fetchBridgeInfo, fetchBridgeNftInfo} from "./bridgeInfo";
import { fetchUserBridgeData, fetchUserNftBridgeData } from './bridgeUserInfo';

interface ChainState {
    bridgeInfo: BridgeInfo;
    userData?: any;
    bridgeNftInfo: BridgeInfo;
    userNftData?: any;
}

const initialState: ChainState = {
    bridgeInfo: {chainLogo: "", chainId: 0, chainsTo: []},
    userData: undefined,
    bridgeNftInfo: {chainLogo: "", chainId: 0, chainsTo: []},
};

export const bridgeSlice = createSlice({
    name: 'bridge',
    initialState,
    reducers: {
        reset: (state) => {
            state.bridgeInfo = {chainLogo: "", chainId: 0, chainsTo: []};
            state.bridgeInfo = {chainLogo: "", chainId: 0, chainsTo: []};
        },
        setBridgeInfo: (state, action) => {
            state.bridgeInfo = action.payload.data;
        },
        setUserInfo: (state, action) => {
            state.userData = action.payload.data;
        },
        setBridgeNftInfo: (state, action) => {
            state.bridgeNftInfo = action.payload.data;
        },
        setUserNftInfo: (state, action) => {
            state.userNftData = action.payload.data;
        },
    },
});

export const { reset, setBridgeInfo, setUserInfo, setBridgeNftInfo, setUserNftInfo } = bridgeSlice.actions;

export const fetchBridgeInfoAsync =
    (chainId: string) =>
        async (dispatch: any): Promise<void> => {
            const { data } = await fetchBridgeInfo(chainId);
            dispatch(
                setBridgeInfo({
                    data,
                })
            );
        };

export const fetchBridgeNftInfoAsync =
    (chainId: string) =>
        async (dispatch: any): Promise<void> => {
            const { data } = await fetchBridgeNftInfo(chainId);
            dispatch(
                setBridgeNftInfo({
                    data,
                })
            );
        };

// fetch farm user token info
export const fetchBridgeUserDataAsync =
  (account: string, chainId: string, bridgeInfo: any, tokenFrom: any) =>
  async (dispatch: any): Promise<void> => {
    const data = await fetchUserBridgeData(account, chainId, bridgeInfo, tokenFrom);
    dispatch(
      setUserInfo({
        data,
      })
    );
  };

export const fetchBridgeNftUserDataAsync =
  (account: string, chainId: string, bridgeInfo: any, tokenFrom: any) =>
  async (dispatch: any): Promise<void> => {
    const data = await fetchUserNftBridgeData(account, chainId, bridgeInfo, tokenFrom);
    dispatch(
    setUserNftInfo({
        data,
      })
    );
  };

export default bridgeSlice.reducer;
