
import { getMasterChefAbi, getNftFarms } from 'utils/farms';
import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';
import GodNftAbi from 'config/abi/GodNft.json';
import MarketAbi from 'config/abi/BasedMarketplace.json'
import multicall from 'utils/multicall';
import { getMarketABI, getMarketAddress, getMarketSupportedCollectionsInfo } from 'utils/market';
import { AbiItem } from 'web3-utils';
import { farmInfos, masterChefInfos, nftFarmInfos } from 'config/constants/farms';

// single
export const fetchNftInfo = async (collectionAddr: string, nftId: number, chainId: string) => {
    if (!collectionAddr) return { };
    
    const collections = getMarketSupportedCollectionsInfo(Number(chainId));
    const collection = collections.filter((row: any) => Number(collectionAddr) === Number(row.address));

    try{

      if( collection.length  > 0 ){

        const calls1 = [
            {
              address: collectionAddr,
              name: 'ownerOf',
              params: [nftId],
            },
          ];
  
          const [owner] = await multicall(GodNftAbi, calls1);
          // console.log("START 2")
          // console.log(owner[0])
          const calls2 = [
            {
              address: collectionAddr,
              name: 'tokenURI',
              params: [nftId],
            },
          ];
  
          const tokenUris = await multicall(GodNftAbi, calls2);

          const marketAddress = getMarketAddress(Number(chainId));

          if( marketAddress ){
            const calls3 = [
                {
                  address: marketAddress,
                  name: 'numTokenListings',
                  params: [collectionAddr],
                },
              ];
            const listingSize = await multicall(MarketAbi, calls3);
            // console.log(`Listing size ${listingSize}`)
          }

          const tokenURI = await Promise.all(
            tokenUris.map(async (tokenUri: string) => {
              const tokenMetadata = await fetch(`${tokenUri}.json`).then((res) => res.json());
  
              return {
                image: tokenMetadata.image.replace('ipfs://', 'https://basedlabs.mypinata.cloud/ipfs/'),
                rank: tokenMetadata.rank || 0,
                meta: tokenMetadata,
              };
            })
          );

          let isStaked = false;
          for( let i = 0; i < nftFarmInfos.length; i+= 1){
            if( owner.length > 0 ){
              if( owner[0] === nftFarmInfos[i].masterChefAddress ){
                isStaked = true;
              }
            }

          }
          return {
            tokenId: nftId,
            address: collectionAddr,
            image: tokenURI[0].image,
            rank: tokenURI[0].rank,
            meta: tokenURI[0].meta,
            owner: owner[0],
            isStaked
          };
    }
    }
    catch(err){
      console.log(err)
    }

    return {

    };
  };

  export const fetchNftsInfo = async (collectionAddr: string, nfts: any, chainId: string) => {
      const collections = getMarketSupportedCollectionsInfo(Number(chainId));
      const collection = collections.filter((row: any) => Number(collectionAddr) === Number(row.address));
      
      try{
        if( collection.length  > 0 ){

          const nftsIds = []
          for(let i = 0; i < nfts.length; i+=1 ){
            nftsIds.push(nfts[i].nftId);
          }

          const calls3 = nftsIds.map((nftId: number) => ({
            nftId,
            address: collectionAddr,
            name: 'tokenURI',
            params: [nftId],
          }));
        
          const tokenUris = await multicall(GodNftAbi, calls3);

          const tokenURI = await Promise.all(
              tokenUris.map(async (tokenUri: string) => {
                const tokenMetadata = await fetch(`${tokenUri}.json`).then((res) => res.json());
    
                return {
                  image: tokenMetadata.image.replace('ipfs://', 'https://basedlabs.mypinata.cloud/ipfs/'),
                  rank: tokenMetadata.rank || 0,
                  meta: tokenMetadata,
                };
              })
            );
            
          const userNfts = nftsIds.map((nftId: number, i: number) => ({
            tokenId: nftId,
            image: tokenURI[i].image,
            rank: tokenURI[i].rank,
            meta: tokenURI[i].meta,
            address: collectionAddr,
            marketData: nfts[i],
          }));
          
            return {
              userNfts
            };
          
       }
      }
      catch(err){
        console.log(err)
      }
  
      return {
  
      };
  };