import { createSlice } from '@reduxjs/toolkit';

import { NodeInfo } from 'types/node';

import { fetchGlobalNodeData } from './fetchGlobalTwistedNodes';

interface NodesState {
  data: NodeInfo[];
}

const initialState: NodesState = {
  data: [],
};

export const nodesSlice = createSlice({
  name: 'twistedNodes',
  initialState,
  reducers: {
    reset: (state) => {
      state.data = [];
    },

    setNodesGlobalData: (state, action) => {
      state.data = [...action.payload.data];
    },
  },
});

export const { reset, setNodesGlobalData } = nodesSlice.actions;

// fetch global node info
export const fetchNodeGlobalDataAsync =
  (chainId: string) =>
  async (dispatch: any): Promise<void> => {
    const { data } = await fetchGlobalNodeData(chainId);

    dispatch(
      setNodesGlobalData({
        data,
      })
    );
  };

export default nodesSlice.reducer;
