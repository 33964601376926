import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useDispatch } from 'react-redux';
import { BigNumber, ethers } from 'ethers';

import { useLpContract, useStakingPoolContract, useTreasury } from 'hooks/useContract';
import {
  fetchStakingGlobalDataAsync,
  fetchStakingUserDepositDataAsync,
  fetchStakingUserTokenDataAsync,
} from 'state/actions';
import { useAppSelector } from 'state/hooks';
import { StakingInfo } from 'types/staking';
import { setPendingTxHash } from 'state/modal/modalSlice';
import { useNotification } from './useNotification';

export const useStakingPool = (poolInfo: StakingInfo) => {
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const { selectedChainId } = useAppSelector((state) => state.chain);
  const stakingPoolContract = useStakingPoolContract(poolInfo.address, poolInfo.type);
  const treasuryContract = useTreasury(poolInfo.treasuryAddress)
  const stakingTokenContract = useLpContract(poolInfo.stakingToken);
  const { onShowNotification } = useNotification();

  const handleApproveStakingToken = useCallback(async (): Promise<string | undefined> => {
    if (!account || !stakingPoolContract || !stakingTokenContract) return '';

    const tx = await stakingTokenContract.approve(stakingPoolContract.address, ethers.constants.MaxUint256);

    onShowNotification({
      title: 'Transaction Pending',
      description: 'Approve Staking Token',
      hasView: true,
      txHash: tx.hash,
    });

    dispatch(setPendingTxHash(tx.hash));
    const receipt = await tx.wait();

    if (receipt.status !== 1) {
      throw new Error();
    }

    dispatch(fetchStakingUserTokenDataAsync(account, poolInfo));

    return tx.txHash;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, dispatch, stakingTokenContract, stakingPoolContract]);

  const handleDeposit = useCallback(
    async (amount: BigNumber): Promise<string | undefined> => {
      if (!account || !stakingPoolContract) return '';

      const tx = await stakingPoolContract.stake(amount);

      onShowNotification({
        title: 'Transaction Pending',
        description: 'Deposit Staking Token',
        hasView: true,
        txHash: tx.hash,
      });
  
      dispatch(setPendingTxHash(tx.hash));

      const receipt = await tx.wait();

      if (receipt.status !== 1) {
        throw new Error();
      }

      dispatch(fetchStakingUserTokenDataAsync(account, poolInfo));
      dispatch(fetchStakingGlobalDataAsync(selectedChainId));
      dispatch(fetchStakingUserDepositDataAsync(account, poolInfo));

      return tx.txHash;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, dispatch, stakingPoolContract]
  );

  const handleWithdraw = useCallback(
    async (amount: BigNumber): Promise<string | undefined> => {
      if (!account || !stakingPoolContract) return '';

      const tx = await stakingPoolContract.withdraw(amount);
      onShowNotification({
        title: 'Transaction Pending',
        description: 'Withdraw Staking tokens',
        hasView: true,
        txHash: tx.hash,
      });
  
      dispatch(setPendingTxHash(tx.hash));

      const receipt = await tx.wait();

      if (receipt.status !== 1) {
        throw new Error();
      }

      dispatch(fetchStakingUserTokenDataAsync(account, poolInfo));
      dispatch(fetchStakingGlobalDataAsync(selectedChainId));
      dispatch(fetchStakingUserDepositDataAsync(account, poolInfo));

      return tx.txHash;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, dispatch, stakingPoolContract]
  );

  const handleClaim = useCallback(
    async (): Promise<string | undefined> => {
      if (!account || !stakingPoolContract) return '';

      const tx = await stakingPoolContract.claimReward();

      onShowNotification({
        title: 'Transaction Pending',
        description: 'Claim Reward',
        hasView: true,
        txHash: tx.hash,
      });

      dispatch(setPendingTxHash(tx.hash));

      const receipt = await tx.wait();

      if (receipt.status !== 1) {
        throw new Error();
      }

      dispatch(fetchStakingUserTokenDataAsync(account, poolInfo));
      dispatch(fetchStakingGlobalDataAsync(selectedChainId));
      dispatch(fetchStakingUserDepositDataAsync(account, poolInfo));

      return tx.txHash;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, dispatch, stakingPoolContract]
  );

  const handleKick = useCallback(
    async (): Promise<string | undefined> => {
      if (!account || !stakingPoolContract) return '';

      if(!treasuryContract)
        return '';

      const tx = await treasuryContract.allocateSeigniorage();

      onShowNotification({
        title: 'Transaction Pending',
        description: 'Approve Staking Token',
        hasView: true,
        txHash: tx.hash,
      });
  
      dispatch(setPendingTxHash(tx.hash));

      const receipt = await tx.wait();
      if (receipt.status !== 1) {
        throw new Error();
      }
      return tx.txHash;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, dispatch, treasuryContract]
  );

  return {
    onApproveStakingToken: handleApproveStakingToken,
    onDeposit: handleDeposit,
    onWithdraw: handleWithdraw,
    onClaim: handleClaim,
    onKickPrinter: handleKick
  };
};
