import { useCallback, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useDispatch } from 'react-redux';
import { BigNumber, ethers } from 'ethers';
import { AbiItem } from 'web3-utils';

import useContract, { useLpContract, useMasterChefContract, useNftContract } from 'hooks/useContract';
import {
  fetchMarketInfoAsync,
  fetchMarketTokenAllowanceInfoAsync,
  fetchNftFarmGlobalDataAsync,
  fetchNftFarmUserDepositDataAsync,
  fetchNftFarmUserTokenDataAsync,
} from 'state/actions';
import { useAppSelector } from 'state/hooks';
import { FarmInfo } from 'types/farm';
import { setPendingTxHash } from 'state/modal/modalSlice';
import { useNotification } from 'hooks/useNotification';
import { getMarketABI, getMarketAddress } from 'utils/market';
import { getBalanceInWei } from 'utils';

export const useMarketpalce = (chainId: number, collectionAddr: string, tokenAddress: string) => {
  const dispatch = useDispatch();
  const { account } = useWeb3React();
  const marketContract = useContract(getMarketABI(chainId) as unknown as AbiItem, getMarketAddress(chainId));
  const nftContract = useNftContract(collectionAddr);
  const { selectedChainId } = useAppSelector((state) => state.chain);
  const paymentTokenContract = useLpContract(tokenAddress );

  const { onShowNotification } = useNotification();

  const handleApproveStakingToken = useCallback(async (nftId: number): Promise<string | undefined> => {
    if (!account || !marketContract || !nftContract || !nftId ) return '';

    // console.log(`WTF ${contract.address} ${stakingTokenContract.address}`)
    const tx = await nftContract.approve(marketContract.address, nftId);

    onShowNotification({
      title: 'Transaction Pending',
      description: 'Approve Token',
      hasView: true,
      txHash: tx.hash,
    });

    dispatch(setPendingTxHash(tx.hash));
    const receipt = await tx.wait();

    if (receipt.status !== 1) {
      throw new Error();
    }
    // dispatch(fetchBridgeNftUserDataAsync(account, chainId.toFixed(), info, tokenFrom));
    return tx.txHash;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, dispatch, marketContract, nftContract]);

  const HandleGetApproved = (nftId: number, update: number) => {
    const [tokenPrices, setTokenPrice] = useState(false);
  
    const fetchTokenPrice = useCallback(async () => {
    if (!account || !marketContract || !nftContract || !nftId) return false;
    let allowance = false;

    try{
      const res = await nftContract.getApproved(nftId);

      if( marketContract.address === res )
          allowance = true;

      setTokenPrice(allowance);
    }
    catch(err){
      console.log(err)
    }

      return allowance;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, marketContract, nftContract, nftId, update]);
  
    useEffect(() => {
      fetchTokenPrice();
  
      setTimeout(() => {
        fetchTokenPrice();
      }, 100000);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, nftId, update]);
  
    return tokenPrices ;
  };

  const handleSell = useCallback(
    async (value: number, tokenAddres: string, tokenId: number, expires: number): Promise<string | undefined> => {
      if (!account || !tokenAddres || !tokenId || !expires || !marketContract || !nftContract) return '';
     
      const options = {value: ethers.utils.parseUnits("10", 18)}
      const tx =  await marketContract.listToken(nftContract?.address, tokenAddres, tokenId, getBalanceInWei(value.toString()), expires, options);

      onShowNotification({
        title: 'Transaction Pending',
        description: 'List Token',
        hasView: true,
        txHash: tx.hash,
      });
  
      dispatch(setPendingTxHash(tx.hash));

      const receipt = await tx.wait();

      if (receipt.status !== 1) {
        throw new Error();
      }
      fetchMarketInfoAsync(selectedChainId);
      return tx.txHash;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, dispatch, marketContract, nftContract]
  );

  const handleBuy = useCallback(
    async (tokenId: number, expires: number, value: number): Promise<string | undefined> => {
      if (!account  || !tokenId || !expires || !marketContract || !nftContract) return '';
     
      const options = {value: getBalanceInWei(value.toString())}
      const tx =  await marketContract.buyToken(nftContract?.address, tokenId);

      onShowNotification({
        title: 'Transaction Pending',
        description: 'Buy NFT',
        hasView: true,
        txHash: tx.hash,
      });
  
      dispatch(setPendingTxHash(tx.hash));

      const receipt = await tx.wait();

      if (receipt.status !== 1) {
        throw new Error();
      }
      fetchMarketInfoAsync(selectedChainId);

      return tx.txHash;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, dispatch, marketContract, nftContract]
  );

  const handleApprovePaymentToken = useCallback(async (): Promise<string | undefined> => {
    if (!account || !marketContract || !paymentTokenContract ) return '';

    const tx = await paymentTokenContract.approve(marketContract.address, ethers.constants.MaxUint256);

    onShowNotification({
      title: 'Transaction Pending',
      description: 'Approve Token',
      hasView: true,
      txHash: tx.hash,
    });

    dispatch(setPendingTxHash(tx.hash));
    const receipt = await tx.wait();

    if (receipt.status !== 1) {
      throw new Error();
    }

    dispatch(fetchMarketTokenAllowanceInfoAsync(chainId.toFixed(), paymentTokenContract.address, account));

    return tx.txHash;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, dispatch, marketContract, paymentTokenContract, account]);

  const handleDelist = useCallback(
    async (tokenId: number): Promise<string | undefined> => {
      if (!account  || !tokenId  || !marketContract || !nftContract) return '';
     
      const tx =  await marketContract.delistToken(nftContract?.address, tokenId);

      onShowNotification({
        title: 'Transaction Pending',
        description: 'Delist NFT',
        hasView: true,
        txHash: tx.hash,
      });
  
      dispatch(setPendingTxHash(tx.hash));

      const receipt = await tx.wait();

      if (receipt.status !== 1) {
        throw new Error();
      }
      fetchMarketInfoAsync(selectedChainId);

      return tx.txHash;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, dispatch, marketContract, nftContract]
  );

  return {
    onApproveStakingToken: handleApproveStakingToken,
    onGetApproved: HandleGetApproved,
    onSell: handleSell,
    onBuy: handleBuy,
    onApprovePaymentToken: handleApprovePaymentToken,
    onDelist: handleDelist
  };
};
