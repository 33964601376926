import { DEFAULT_ACTIVE_CHAIN_ID } from 'config/constants/chains';
import LpPairAbi from 'config/abi/LpPair.json';
import multicall from 'utils/multicall';
import { getStakingPoolAbi, getStakingPools } from 'utils/stakings';

// single
export const fetchUserTokenDataSingle = async (account: string, pool: any) => {
  const { name, stakingToken, rewardToken, rewardTokenDecimal, address } = pool;

  // fetch staking token balance and allowance
  const calls1 = [
    {
      address: stakingToken,
      name: 'allowance',
      params: [account, address],
    },
    {
      address: stakingToken,
      name: 'balanceOf',
      params: [account],
    },
  ];

  const [stakingTokenAllowanceRaw, balanceRaw] = await multicall(LpPairAbi, calls1);

  return {
    name,
    userStakingTokenAllowance: stakingTokenAllowanceRaw[0],
    userStakingTokenBalance: balanceRaw[0],
    rewardToken,
    rewardTokenDecimal,
  };
};

// multiple
export const fetchUserTokenData = async (account: string, chainId: string) => {
  const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
  const pools = getStakingPools(selectedChainid);

  const tokenData = await Promise.all(
    pools.map(async (pool) => {
      const { name, stakingToken, address, rewardToken, rewardTokenDecimal } = pool;

      // fetch staking token balance and allowance
      const calls1 = [
        {
          address: stakingToken,
          name: 'allowance',
          params: [account, address],
        },
        {
          address: stakingToken,
          name: 'balanceOf',
          params: [account],
        },
      ];

      const [allowanceRaw, balanceRaw] = await multicall(LpPairAbi, calls1);

      return {
        name,
        userStakingTokenAllowance: allowanceRaw[0],
        userStakingTokenBalance: balanceRaw[0],
        rewardToken,
        rewardTokenDecimal,
      };
    })
  );

  return tokenData;
};

// single
export const fetchUserStakingDataSingle = async (account: string, pool: any) => {
  const { name, address, rewardToken, rewardTokenDecimal, type } = pool;

  // fetch staked amount and reward
  const calls = [
    // demos
    {
      address,
      name: 'demos',
      params: [account],
    },
    {
      address,
      name: 'balanceOf',
      params: [account],
    },
    {
      address,
      name: 'earned',
      params: [account],
    },
    {
      address,
      name: 'canWithdraw',
      params: [account],
    },
    {
      address,
      name: 'canClaimReward',
      params: [account],
    },
  ];

  const [demos, stakedAmountRaw, pendingRewardRaw, canWithdraw, canClaimReward] = await multicall(
    getStakingPoolAbi(type),
    calls
  );
  return {
    name,
    stakedBalance: stakedAmountRaw[0],
    rewardTokenBalance: pendingRewardRaw[0],
    rewardToken,
    rewardTokenDecimal,
    canWithdraw: canWithdraw[0],
    canClaimReward: canClaimReward[0],
    epochTimerStart: demos.epochTimerStart
  };
};

// multiple
export const fetchUserStakingData = async (account: string, chainId: string) => {
  const selectedChainid = Number(chainId || DEFAULT_ACTIVE_CHAIN_ID);
  const pools = getStakingPools(selectedChainid);
  const poolData = await Promise.all(
    pools.map(async (pool) => {
      const { name, address, rewardToken, rewardTokenDecimal, type } = pool;

      // fetch staked amount and reward
      const calls = [
            // demos
    {
      address,
      name: 'demos',
      params: [account],
    },
        {
          address,
          name: 'balanceOf',
          params: [account],
        },
        {
          address,
          name: 'earned',
          params: [account],
        },
        {
          address,
          name: 'canWithdraw',
          params: [account],
        },
        {
          address,
          name: 'canClaimReward',
          params: [account],
        },
      ];

      const [demos, stakedAmountRaw, pendingRewardRaw, canWithdraw, canClaimReward] = await multicall(
        getStakingPoolAbi(type),
        calls
      );

      return {
        name,
        stakedBalance: stakedAmountRaw[0],
        rewardTokenBalance: pendingRewardRaw[0],
        rewardToken,
        rewardTokenDecimal,
        canWithdraw: canWithdraw[0],
        canClaimReward: canClaimReward[0],
        epochTimerStart: demos.epochTimerStart
      };
    })
  );

  return poolData;
};

export const fetchStakingUserData = async (account: string, chainId: string) => {
  try{
    const tokenData = await fetchUserTokenData(account, chainId);
    const stakingData = await fetchUserStakingData(account, chainId);
    const data = tokenData.map((row: any, index: number) => ({
      ...row,
      ...stakingData[index],
    }));
  
    return data;
  }
  catch(err){
    console.log(err)
  }
  return [];
};
