import { createSlice } from '@reduxjs/toolkit';

import { LiquidityInfo } from 'types/liquidity';
import { fetchGlobalLiquidityData } from './fetchGlobalLps';

interface LiquidityState {
  data: LiquidityInfo[];
}

const initialState: LiquidityState = {
  data: [],
};

export const liquiditySlice = createSlice({
  name: 'liquidity',
  initialState,
  reducers: {
    reset: (state) => {
      state.data = [];
    },

    setLiquidityGlobalData: (state, action) => {
      state.data = action.payload.data.map((row: any, i: number) => ({
        ...row
    }));
    },
  },
});

export const { reset, setLiquidityGlobalData} =
liquiditySlice.actions;

// fetch global farm info
export const fetchLiquidityGlobalDataAsync =
  (chainId: string) =>
  async (dispatch: any): Promise<void> => {
    const { data } = await fetchGlobalLiquidityData(chainId);

    dispatch(
        setLiquidityGlobalData({
        data,
      })
    );
  };

export default liquiditySlice.reducer;
