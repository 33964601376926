import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';

import modal from './modal/modalSlice';
import tokens from './tokens/tokensSlice';
import farms from './farms/farmSlice';
import stakingPools from './stakings/stakingSlice';
import nftFarms from './nftFarms/nftFarmSlice';
import liquidity from './lps/lpsSlice';
import chain from './chain/chainSlice';
import twistedNodes from './twistedNodes/twistedNodesSlice';
import theatre from './theatre/theatreSlice';
import bridge from './bridge/bridgeSlice';
import market from './market/nftMarketSlice';
import upgrade from './upgrade/nftUpgradeSlice';

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

export const store = configureStore({
  reducer: {
    modal,
    tokens,
    farms,
    stakingPools,
    nftFarms,
    chain,
    twistedNodes,
    liquidity,
    theatre,
    bridge,
    market,
    upgrade
  },
  middleware: customizedMiddleware,
  devTools: process.env.NODE_ENV !== 'production',
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
