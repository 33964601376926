export const multicallAddr = {
  // fantom
  250: '0xcA11bde05977b3631167028862bE2a173976CA11',
  // avalanche
  43114: '0xcA11bde05977b3631167028862bE2a173976CA11',
  // polygon
  137: '0xcA11bde05977b3631167028862bE2a173976CA11',
  8453: '0xcA11bde05977b3631167028862bE2a173976CA11',
  324: '0xcA11bde05977b3631167028862bE2a173976CA11',
};

export const wftmAddr = {
  // fantom
  250: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
  // avalanche
  43114: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
  // polygon
  137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
};

export const obolAddr = {
  // fantom
  250: '0x1539C63037D95f84A5981F96e43850d1451b6216',
};

export const obolFtmPairAddr = {
  // fantom
  250: '0xE3E26Bd9cD2F32a8F60BfFf5DF88bB3b3C5Eb9f9',
  // avalanche
  43114: '0xE3E26Bd9cD2F32a8F60BfFf5DF88bB3b3C5Eb9f9',
  // polygon
  137: '0xE3E26Bd9cD2F32a8F60BfFf5DF88bB3b3C5Eb9f9',
};
