import React from 'react';
import { Box, Dialog, Grid, styled, Typography } from '@mui/material';
import { Login } from 'types/wallet';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setIsWalletConnectModalOpen } from 'state/modal/modalSlice';

import WalletCard from './WalletCard';
import config from './config';

const ModalDialog = styled(Dialog)(({ theme }) => ({
  '.MuiDialog-container > .MuiPaper-root': {
    borderRadius: '12px',
    maxWidth: '350px',
    width: '100%',
    background: theme.palette.mode === 'dark' ? '#2A3139' : '#2A3139',
    boxShadow:
      '0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
    padding: '30px 30px',
    border: '1px solid rgba(217, 217, 217, 0.2)',
  },
}));

const ModalTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '19px',
  lineHeight: '25px',
  color: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
}));

interface Props {
  login: Login;
}

const ConnectModal: React.FC<Props> = ({ login }) => {
  const { isWalletConnectModalOpen } = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();

  const onClose = () => {
    dispatch(setIsWalletConnectModalOpen(false));
  };

  const onDismiss = () => {
    onClose();
  };

  return (
    <ModalDialog maxWidth="xs" onClose={onClose} open={isWalletConnectModalOpen}>
      <Box>
        <ModalTitle>Connect Wallet</ModalTitle>
      </Box>
      <Box sx={{ marginTop: '24px' }}>
        <Grid container spacing="12px">
          {config.map((entry) => (
            <Grid item key={entry.title} xs={6}>
              <WalletCard login={login} onDismiss={onDismiss} walletConfig={entry} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </ModalDialog>
  );
};

export default ConnectModal;
